import React, { useEffect, useState } from "react";
import {
  getAllAddionalList,
  getProjectList,
} from "../../Redux/actions/Project";

import { useDispatch, useSelector } from "react-redux";
import axios from "../../Utility/jwt/config";

import { history } from "../../Redux/store/index";
import { universalLoading } from "../../Redux/actions/Common";

import {
  SET_PROJECTDETAILS_LOADING,
  UPDATE_TITLE,
} from "../../Utility/ActionConstant";
import { getAddAlarmListDetails } from "../../Redux/actions/AddAlarmList";

import AdditionalAlarmDeleteModel from "./AdditionalAlarmDeleteModel";

function SideBar() {
  const { ProjectList, ALLAdditionalList } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  ); //get project list and additional alarm list from store

  const [pathId, setPathId] = useState(""); // path id state to check herachy of parent and child project
  const [subchildId, setSubchildId] = useState(""); //subchildId state to get child project id
  const [grandChildId, SetGrandChildId] = useState(""); //grandchildId state to get grand child project id
  const [grandChildData, SetGrandChildData] = useState([]); //get sub project inside grand child
  const [searchTerm, setSearchTerm] = useState(""); // side bar search for projectlist
  const [childProject, setChildProject] = useState([]); //get sub project inside parent project
  const [subChildProject, setSubChildProject] = useState([]); //get sub project inside child project
  const [projectid, setProjectid] = useState(""); // get project id of clicked project to render
  const [idChecked, setIdChecked] = useState(""); // display or hide parent project dropdown list
  const [idChildChecked, setIdChildChecked] = useState(""); //display or hide child project dropdown list
  const [loading, setLoading] = useState(true); //add loading until data rendered from api for parent project
  const [childLoading, setChildLoading] = useState(true); //add loading until data rendered from api for child project
  const [grandchild, setGrandchild] = useState(""); //add loading until data rendered from api for grand child project
  const [alarmSearch, setAlarmSearch] = useState(""); //search additional alarmlist
  //additional alarm list delete
  const [open, setOpen] = useState(""); //display hide delete model
  const [aprojectId, setAprojectId] = useState(null); //get additional alarm list project id for delete
  const [addDelete, setAddDelete] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectList(searchTerm));
    if (alarmSearch.length > 2 || alarmSearch.length === 0) {
      dispatch(getAllAddionalList(alarmSearch));
    }
  }, [dispatch, searchTerm, alarmSearch]);
  const rowPerPageLocal = Number(
    window.localStorage.getItem("rowPerPage")
  );
  //load parent project details
  function handleClick(e) {
    dispatch(universalLoading(true));
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: true });
    setLoading(true);
    dispatch({ type: UPDATE_TITLE, payload: { title: "" } });
    // console.log("childvalue", pathId);

    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`projects/project-list/${pathId}`)
      .then((data) => {
        if (data.status === 200) {
          setChildProject(data.data.data);

          // console.log("childlistdata", data);
          setLoading(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });

    history.push(`/home/projectlist/projectdetail/${projectid}`);
    // dispatch(getProjectDetails(projectid));
  }

  //load child project details
  function handleSubChild(e) {
    dispatch(universalLoading(true));
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: true });
    setChildLoading(true);
    // console.log("childvalue1", projectid);

    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`projects/project-list/${subchildId}`)
      .then((data) => {
        if (data.status === 200) {
          setSubChildProject(data.data.data);
          setChildLoading(false);
          // console.log("childlistdata", data);
        } else {
          // console.log("errorchild");
          setChildLoading(false);
        }
      })
      .catch((err) => {
        setSubChildProject([]);

        console.log("error", err);
        setChildLoading(false);
      });
    history.push(`/home/projectlist/projectdetail/${projectid}`);
  }
  //load grand child project details
  function handlegrandChild(e) {
    // console.log("childvalue1", projectid);
    dispatch(universalLoading(true));
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`projects/project-list/${grandChildId}`)
      .then((data) => {
        if (data.status === 200) {
          // console.log("grandlistdata", data);
          SetGrandChildData(data.data.data);
        } else {
          console.log("errorchild");
          SetGrandChildData([]);
        }
      })
      .catch((err) => {
        SetGrandChildData([]);
      });
    //dispatch({ type: GET_PROJECTDETAILS, payload: null });
    history.push(`/home/projectlist/projectdetail/${projectid}`);
  }
  //load grand grand child project details
  function handlesubgrandChild(e) {
    // console.log("childvalue1", projectid);
    dispatch(universalLoading(true));
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`projects/project-list/${grandChildId}`)
      .then((data) => {
        if (data.status === 200) {
          // console.log("grandlistdata", data);
          SetGrandChildData(data.data.data);
        } else {
          console.log("errorchild");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
    history.push(`/home/projectlist/projectdetail/${projectid}`);
  }

  return (
    <nav class="sidebar-section d-md-block  collapse">
    <h2 className="sidebar-logo" onClick={(e) => history.push('/')}>PLC Alarm</h2>

      <div className="sidebar-search-box">
        <input
          className="form-control"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span className="sidebar-search-box-icon">
          <img src={process.env.PUBLIC_URL + "/img/search.svg"} />
        </span>
      </div>
      <h4 className="sidebar-menu-title">Projects</h4>
      <ul className="sidebar-menu">
        {/* side bar dynamic */}
        {(ProjectList ?? []).map((items) => (
          <li
            key={items._id}
            className={
              idChecked === items._id ? "nav-item  " : "nav-item collapsed"
            }
            data-bs-toggle="collapse"
            data-bs-target={"#mainmen" + items.path_number}
            data-parent="#mygroup"
            aria-expanded="false"
            value={items._id}
          >
            <a
              href="javascript:void(0)"
              value={items.path_number}
              onMouseDown={(e) => {
                setPathId(items.path_number);
                setProjectid(items._id);
                idChecked === items._id
                  ? setIdChecked("")
                  : setIdChecked(items._id);
              }}
              onFocus={handleClick}
            >
              <img
                className="menu-icon"
                src={process.env.PUBLIC_URL + "/img/folder.svg"}
              />{" "}
              <p>{items.project_name} </p>
              <img
                className="sidebar-menu-arrow"
                src={process.env.PUBLIC_URL + "/img/down-arrow.svg"}
              />
            </a>
            <div
              className={
                idChecked === items._id
                  ? "collapse sidebar-submenu show "
                  : "collapse sidebar-submenu"
              }
              id={"mainmenu" + items.path_number}
            >
              <ul className="btn-toggle-nav">
                {/* Sub menu start */}
                {loading
                  ? ""
                  : childProject.map((item) => (
                      <li
                        className={
                          idChildChecked === item._id
                            ? "rounded"
                            : " collapsed rounded"
                        }
                        data-bs-toggle="collapse"
                        data-bs-target={"#submenu" + item.path_number}
                        aria-expanded="false"
                        onClick={(e) => setIdChildChecked(item._id)}
                      >
                        <a
                          href="javascript:void(0)"
                          value={1}
                          onMouseDown={(e) => {
                            setSubchildId(item.path_number);
                            setProjectid(item._id);
                          }}
                          onClick={handleSubChild}
                        >
                          <img
                            className="menu-icon"
                            src={
                              process.env.PUBLIC_URL + "/img/open_folder.svg"
                            }
                          />
                          <p>{item.project_name} </p>
                          <i className="bi bi-chevron-down text-center sidebar-menu-arrow"></i>
                        </a>
                        <div
                          className={
                            idChildChecked === item._id
                              ? "collapse sidebar-submenu show"
                              : "collapse sidebar-submenu "
                          }
                          id={"submenu" + item.path_number}
                        >
                          {/* Sub menu data */}

                          <ul className="btn-toggle-nav">
                            {childLoading
                              ? // <li
                                //   className={
                                //     idChildChecked === item._id
                                //       ? "rounded"
                                //       : " collapsed rounded"
                                //   }
                                //   data-bs-toggle="collapse"
                                //   data-bs-target={"#submenu" + item.path_number}
                                //   aria-expanded="false"
                                // >
                                //   <a
                                //     href="javascript:void(0)"
                                //     className="link-dark rounded"
                                //   >
                                //     <img
                                //       className="menu-icon"
                                //       src={
                                //         process.env.PUBLIC_URL +
                                //         "/img/open_folder.svg"
                                //       }
                                //     />{" "}
                                //     Loading
                                //   </a>
                                // </li>
                                null
                              : subChildProject.map((item) => (
                                  <li
                                    className={
                                      grandchild === item._id
                                        ? "rounded"
                                        : " collapsed rounded"
                                    }
                                    data-bs-toggle="collapse"
                                    data-bs-target={
                                      "#submenu" + item.path_number
                                    }
                                    aria-expanded="false"
                                    onClick={(e) => setGrandchild(item._id)}
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      className="link-dark rounded"
                                      onMouseDown={(e) => {
                                        SetGrandChildId(item.path_number);
                                        setProjectid(item._id);
                                      }}
                                      onClick={handlegrandChild}
                                    >
                                      <img
                                        className="menu-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/open_folder.svg"
                                        }
                                      />{" "}
                                      {item.project_name}
                                      <i className="bi bi-chevron-down text-center sidebar-menu-arrow"></i>
                                    </a>

                                    {/* grand child */}

                                    <div
                                      className={
                                        grandchild === item._id
                                          ? "collapse sidebar-submenu show"
                                          : "collapse sidebar-submenu "
                                      }
                                      id={"submenu" + item.path_number}
                                    >
                                      {/* Sub menu data */}

                                      <ul
                                        className="btn-toggle-nav"
                                        id="grandchild"
                                      >
                                        {grandChildData.map((item) => (
                                          <li
                                            className={
                                              idChildChecked === item._id
                                                ? "rounded"
                                                : " collapsed rounded"
                                            }
                                            data-bs-toggle="collapse"
                                            data-bs-target={
                                              "#submenu" + item.path_number
                                            }
                                            aria-expanded="false"
                                          >
                                            <a
                                              href="javascript:void(0)"
                                              className="link-dark rounded"
                                              onMouseDown={(e) => {
                                                SetGrandChildId(
                                                  item.path_number
                                                );
                                                setProjectid(item._id);
                                              }}
                                              onClick={handlesubgrandChild}
                                            >
                                              <img
                                                className="menu-icon"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/open_folder.svg"
                                                }
                                              />{" "}
                                              {item.project_name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>

                                    {/*grand child */}
                                  </li>
                                ))}
                          </ul>
                        </div>
                      </li>
                    ))}
              </ul>
            </div>
          </li>
        ))}
        {/* Main menu start removed */}
      </ul>

      <div className="sidebar-alarm-box">
        <h4 className="sidebar-menu-title mt-0">Additional Alarms</h4>
        <div className="sidebar-search-box">
          <input
            className="form-control"
            type="text"
            placeholder="Search..."
            value={alarmSearch}
            onChange={(e) => setAlarmSearch(e.target.value)}
          />
          <span className="sidebar-search-box-icon">
            <img src={process.env.PUBLIC_URL + "/img/search.svg"} />
          </span>
        </div>
        <ul>
          {ALLAdditionalList?.map((addList) => (
            <li key={addList._id}>
              <a href="javascript:void(0)">
                <img
                  className="menu-icon"
                  src={process.env.PUBLIC_URL + "/img/folder.svg"}
                />{" "}
                <span
                  onClick={() => {
                    window.localStorage.removeItem("addpageIndex");
                    window.localStorage.removeItem("addIndexId");
                    window.localStorage.removeItem("addstartIndex");
                    window.localStorage.removeItem("addendIndex");
                    window.localStorage.setItem("addpageNumber", Math.floor(0));
                    window.sessionStorage.setItem("additionalsearch", "");
                    dispatch(getAddAlarmListDetails(addList._id,0,rowPerPageLocal));
                  }}
                >
                  {addList.a_alarm_name}
                </span>
                {addList.delete ? (
                  <i
                    className="bi bi-trash-fill"
                    style={{ float: "right", width: "30px" }}
                    onClick={() => console.log("additional alarm list ")}
                    onClick={() => {
                      setOpen("block");
                      setAprojectId(addList._id);
                      setAddDelete(addList.delete);
                    }}
                  ></i>
                ) : (
                  <i
                    className="bi bi-trash"
                    data-tip="hello world"
                    style={{ float: "right", width: "30px" }}
                    onClick={() => console.log("additional alarm list ")}
                    onClick={() => {
                      setOpen("block");
                      setAprojectId(addList._id);
                      setAddDelete(addList.delete);
                    }}
                  ></i>
                )}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <AdditionalAlarmDeleteModel
        setOpen={setOpen}
        open={open}
        projectid={aprojectId}
        addDelete={addDelete}
      />
    </nav>
  );
}

export default SideBar;
