import React from 'react'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { useDispatch } from 'react-redux';
import { SET_PROJECTDETAILS_LOADING } from '../../../Utility/ActionConstant';
import { universalLoading } from '../../../Redux/actions/Common';
export default function DocumentTemplet() {
  const dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();
  const OpenGoogleDocument = () => {
    //open GoogleDoc file of specifice project_id (code by komal)
    window.open(
      `https://docs.google.com/document/d/${location.pathname.split('/')[5]}`
    );
    dispatch(universalLoading(true));
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: true });
    //open projectdetails module (code by komal)
    history.push(`/home/projectlist/projectdetail/${location.pathname.split('/')[6]}`);
  }
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{ display: "flex", position: "absolute", alignItems: "center" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> Project Document</h5>
          </div>
          <div className="modal-body">
            <p>Please allow pop-ups in your browser to open project template.</p>
            <p>New template for this project created successfully , you will be redirected to template by clicking on below button.</p>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              style={{ marginRight: '200px' }}
              onClick={OpenGoogleDocument}
            >
              <b>REDIRECT</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
