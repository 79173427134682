import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import BreadCums from "../../../Components/Layout/BreadCums";
import Footer from "../../../Components/Layout/Footer";
import Header from "../../../Components/Layout/Header";
import SideBar from "../../../Components/Layout/SideBar";
import { useSelector } from "react-redux";
import { LastName } from "../../../Utility/Constant";
import { AuhMethods } from "../../../Utility/jwt/Authmethod";
import { useDispatch } from "react-redux";
import { UPDATE_TITLE } from "../../../Utility/ActionConstant";

const titleData = { title: "My Profile" };
function Profile() {
  const [editButton, setEditButton] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");

  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  

  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    window.sessionStorage.setItem("pageTitle", "My Profile");
    setFirstName(authUser.first_name);
    setLastName(authUser.last_name);
    setEmail(authUser.email);
  }, [editButton]);
  const letters = /^(?=(?:[^A-Za-z]*[A-Za-z]){2})(?![^\d~`?!^*¨ˆ;@=$%{}\[\]|\\\/<>#“.,]*[\d~`?!^*¨ˆ;@=$%{}\[\]|\\\/<>#“.,])\S+(?: \S+){0,2}$/;
  const onSubmit = () => {
    if (firstName === "") {
      setFirstNameError("First name is required.");
    } else if (!firstName.match(letters)) {
      setFirstNameError("First name should contain only letters.");
    }
    if (lastName === "") {
      setLastNameError("Last name is required.");
    } else if (!lastName.match(letters)) {
      setLastNameError("Last name should contain only letters.");
    }

    if (
      firstName !== "" &&
      lastName !== "" &&
      firstName.match(letters) &&
      lastName.match(letters)
    ) {
      setFirstNameError("");
      setLastNameError("");
      dispatch(
        AuhMethods["jwtAuth"].onUpdateProfile({
          firstName,
          lastName,
        })
      );

      setTimeout(() => {
        setEditButton(false);
      }, 1000);
    }
  };
  return (
    <div className="main-inner-card d-flex justify-content-center align-items-center">
      <div className="profile-box">
        <div className="profile-img">
          {/* <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=150" /> */}
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/OOjs_UI_icon_userAvatar-constructive.svg/480px-OOjs_UI_icon_userAvatar-constructive.svg.png" />
          
        </div>
        {editButton ? (
          ""
        ) : (
          <div className="profile-details">
            <h2>
              {authUser?.first_name} {authUser?.last_name}
            </h2>
            <p>{email}</p>
          </div>
        )}

        {editButton ? (
          <>
            <div className="form-row">
              <div className="row ">
                <div className="col">
                  <label for="Your-Name" className="col-sm-12 col-form-label ">
                    {" "}
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter first name"
                    aria-label="Your-Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onBlur={() =>
                      firstName !== "" && firstName.match(letters)
                        ? setFirstNameError("")
                        : null
                    }
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {firstNameError}
                  </div>
                </div>

                <div className="col">
                  <label
                    for="Company-Name"
                    className="col-sm-12 col-form-label "
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter last name"
                    aria-label="Company-Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    onBlur={() =>
                      lastName !== "" && lastName.match(letters)
                        ? setLastNameError("")
                        : null
                    }
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {lastNameError}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="row">
                <div className="col">
                  <label
                    for="Company-Name"
                    className="col-sm-12 col-form-label "
                  >
                    {" "}
                    Email Id{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter  Email Address"
                    aria-label="Company-Name"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                  <div id="name" className="invalid-feedback">
                    Enter valid email address here
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          ""
        )}
        <div className="profile-btn">
          {editButton ? (
            <>
              <button
                className="cancel-btn"
                onClick={(e) => setEditButton(false)}
              >
                {" "}
                Cancel{" "}
              </button>{" "}
              <button className="primary-btn" onClick={onSubmit}>
                {" "}
                Save{" "}
              </button>
            </>
          ) : (
            <button
              className="primary-btn"
              onClick={(e) => setEditButton(true)}
            >
              {" "}
              Edit Profile
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
