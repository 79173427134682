import { GET_ADDALARMLISTDETAILS, SET_ADDITIONALPAGELIST } from "../../Utility/ActionConstant";

const INIT_STATE = {
 AddAlarmListDetails:null,
 allPage:[]

};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADDALARMLISTDETAILS: {
      return {
        ...state,
        AddAlarmListDetails: action.payload,
      };
    }
    case SET_ADDITIONALPAGELIST:{
      return{
        ...state,
        allPage:action.payload
      }
    }
    default:
      return state;
  }
};
