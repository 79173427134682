import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Project from './Project';
import Breadcums from './Breadcums';
import ContentManager from './ContentManager';
import AddAlarmList from './AddAlarmList';
import Export from './Export';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    projectlistReducer:Project,
    BreadcumsReducer:Breadcums,
  ContentManager:ContentManager,
  AddAlarmList:AddAlarmList,
  Export:Export
  });
