import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { browserHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
function BreadCums() {
  const { title } = useSelector(({ BreadcumsReducer }) => BreadcumsReducer); //get title state from store 

  return (
    <div
      className="breadcrumb-box"
      style={{ display: title?.title === "" ? "none" : "" }}
    >
      <h4>{title ? title.title : ""}</h4>
      <nav className="breadcrumb">
        {title?.title === "" ? (
          ""
        ) : (
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/home">
                <i className="bi bi-house-door-fill"></i>
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {title ? title.title : ""}
            </li>
          </ol>
        )}
      </nav>
    </div>
  );
}

export default BreadCums;
