import {
  clearSnackbar,
  fetchError,
  fetchSuccess,
  universalLoading,
} from "./Common";
import axios from "../../Utility/jwt/config";
import {
  GET_ABOUT,
  SET_ABOUT,
  GET_PROJECTDETAILS,
  GET_ALLPROJECTLIST,
  GET_ALLLANGUAGES,
  GET_ALLADDITONALLIST,
  SET_ALARMLANGUAGE,
  SET_PROJECTDETAILS_LOADING,
  ALARM_LOADER,
  SET_PAGELIST,
  SET_ADDPAGELIST,
  SET_FILEUPLOAD,
  GET_USABLEFILELIST,
  GET_ACTIVATE_USED_ALARM_FILE,
  GET_ALARM_SELECT_DESELECT,
  GET_PROJECT_SETTING,
  DISPLAYED_LANGUAGES,
  SHOW_USED_ALARM,
  EXPORTLOADING,
  PROJECT_BITLIST
  
} from "../../Utility/ActionConstant";

import { history } from "../../Redux/store/index";
import { somethingWentWrongError } from "../../Utility/Constant";
import { langStatus } from "./Export";

//get Project list
export const getProjectList = (searchTerm) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get("projects/project-list/0")
      .then((data) => {
        if (data.status === 200) {
          const searchData = data.data.data
            // eslint-disable-next-line array-callback-return
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.project_name
                  .toLowerCase()
                  .includes(searchTerm.toLocaleLowerCase())
              ) {
                return val;
              }
            });

          dispatch({ type: GET_ABOUT, payload: searchData });

          dispatch(getAllProjectList());
          dispatch(getAllLanguageList());
          dispatch(getAllAddionalList());
        } else {
          dispatch(fetchError(somethingWentWrongError));
        }
      })
      .catch((err) => {
        dispatch(getAllLanguageList());
        if (err?.response?.data?.message === "Unauthorized, please login.") {
        } else {
          dispatch(
            fetchError(
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "No details availible."
            )
          );
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        }
        dispatch({ type: GET_ABOUT, payload: [] });
        history.push("/home/projectlist/projectdetailnotavailabel");
      });
  };
};

//get Project details
export const getProjectDetails = (
  id,
  pageNumber,
  rowPerpage,
  searchTerm,
  addPageNumber,
  addSearch,
  
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(
        `projects/project-detail/${id}/${pageNumber}/${rowPerpage}/${searchTerm}/${addPageNumber}/${rowPerpage}/${addSearch}`
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_PROJECTDETAILS, payload: data.data.data });
          const allpageArray = Math.ceil(data.data.data[0]?.total / rowPerpage)
          const pagenumberFilterArray = []
          
          for (let index = 0; index < Number(allpageArray); index++) {
            
            pagenumberFilterArray.push({ pageName: `${index * rowPerpage}-${data.data.data[0]?.total > rowPerpage && index !== Number(allpageArray) - 1 ? ((index + 1) * rowPerpage) - 1 : data.data.data[0]?.total ? data.data.data[0]?.total - 1 : 0}`, pageNumber: index })
          }
          // {data?.data?.data[0]?.total>rowPerpage? (index *rowPerpage) +data?.data?.data[0]?.total-1: data?.data?.data[0]?.total? data?.data?.data[0]?.total-1:0}
          dispatch({ type: SET_PAGELIST, payload: pagenumberFilterArray })
          const allAddpageArray = Math.ceil(data.data.data[0]?.addi_total / rowPerpage)
          const addpagenumberFilterArray = []

          for (let index = 0; index < Number(allAddpageArray); index++) {

            addpagenumberFilterArray.push({ pageName: `${index * rowPerpage}-${data.data.data[0]?.addi_total > rowPerpage && index !== Number(allAddpageArray) - 1 ? ((index + 1) * rowPerpage) - 1 : data.data.data[0]?.addi_total ? data.data.data[0]?.addi_total - 1 : 0}`, pageNumber: index })
          }
         
          dispatch({ type: SET_ADDPAGELIST, payload: addpagenumberFilterArray })
       
          const NewLangArr  = [];
          data.data.data[0].languages.forEach(ele => {
           if(!data.data.data[0].hide_language_array.includes(ele._id)) NewLangArr.push(ele)
          });
         
          
          dispatch({type:DISPLAYED_LANGUAGES, payload: NewLangArr})
          
          dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: false });
          dispatch(universalLoading(false));
          dispatch({ type: ALARM_LOADER, payload: false });
          dispatch(langStatus(id))
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.message ===
          "User have not Access for this Project."
        ) {
          dispatch(
            fetchError(
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "No details availible."
            )
          );

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          history.push("/home/projectlist/projecterror");
        } else {
          dispatch(
            fetchError(
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "No details availible."
            )
          );
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);

          history.push("/home/projectlist/projecterror");
        }
      });
  };
};

//get Project list for add project menu
export const getAllProjectList = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`projects/all-project-list`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_ALLPROJECTLIST, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => { });
  };
};

//get language list for add project
export const getAllLanguageList = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`alarm/all-language-list`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_ALLLANGUAGES, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => { });
  };
};

// get additional alarm list for add project
export const getAllAddionalList = (searchTerm) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`alarm/all-additional-alarm-list`)
      .then((data) => {
        if (data.status === 200) {
          const searchData = data.data.data
            // eslint-disable-next-line array-callback-return
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.a_alarm_name
                  .toLowerCase()
                  .includes(searchTerm.toLocaleLowerCase())
              ) {
                return val;
              }
            });
          dispatch({ type: GET_ALLADDITONALLIST, payload: searchData });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//add new project
export const getAddProject = (
  projectName,
  parentProjectName,
  description,
  AddAlarmlanguageList,
  rsml,
  additionalList,
  additionalListStatus
) => {
  return (dispatch) => {
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(`projects/add-project`, {
        project_name: projectName,
        path_number: parentProjectName,
        description: description,
        languages: AddAlarmlanguageList,
        do_it: rsml,
        additional: additionalList,
        additional_create: additionalListStatus,
      })
      .then((data) => {
        if (data.status === 200) {
          // redirect to doc template using doc id from responce
          dispatch(fetchSuccess("Project added successfully."));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          dispatch(universalLoading(true));
          //open documentTemplate with Template_document id and project_id(code by komal)
          history.push(`/home/projectlist/projectdetail/${data.data.data.project_id}`);
          // history.push(`/home/Routes/Pages/documentTemplet/${data.data.data.template_document}/${data.data.data.project_id}`);
          dispatch(getProjectList(""));
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};

//update project details
export const getUpdateProject = (
  projectName,
  description,
  AddAlarmlanguageList,
  rsml,
  projectId,
  additionalList
) => {
  return (dispatch) => {
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(`/projects/edit-project/${projectId}`, {
        project_name: projectName,
        description: description,
        do_it: rsml,
        languages: AddAlarmlanguageList,
        additional_alarm: additionalList,
        additional_create: additionalList ? 1 : null,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Project updated successfully."));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          dispatch(getProjectDetails(projectId));
          dispatch(getProjectList(""));
          dispatch(getAllAddionalList(""));
          history.goBack();
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};

// update alarm in project
export const getEditAlarm = (
  alarmName,
  description,
  languageIds,
  languageDetails,
  projectId,
  alarmId,
  alarmServerSearch
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(`alarm/edit-alarm-in-project/${projectId}/${alarmId}`, {
        alarm_name: alarmName,
        description: description,
        language_ids: languageIds,
        language_details: languageDetails,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Alarm updated successfully."));
          const pagenumberLocal = Number(
            window.localStorage.getItem("pageNumber")
          );
          const rowPerPageLocal = Number(
            window.localStorage.getItem("rowPerPage")
          );
          if (alarmServerSearch) {
            dispatch(getProjectDetails(projectId, 0, rowPerPageLocal, alarmServerSearch));
          } else {
            dispatch(getProjectDetails(projectId, pagenumberLocal, rowPerPageLocal));
          }
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch({ type: ALARM_LOADER, payload: false });
        if (err?.response?.status === 500) {
        } else {
          dispatch(fetchError(err?.response?.data?.message));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        }
      });
  };
};

//add new alarm in project
export const getAddAlarm = (
  alarmName,
  description,
  languageIds,
  languageDetails,
  projectId,
  alarmIndex,
  giveAccess
) => {
  const languageIDRev = languageIds.reverse();
  const languageDetailRev = languageDetails.reverse();
  const newLangid = [];
  const newDetailid = [];
  let found = null;
  languageIDRev.forEach((languageId, index) => {
    found = newLangid.some((id) => id === languageId);
    if (!found) {
      newLangid.push(languageId);
      newDetailid.push(languageDetailRev[index]);
    }
  });

 
  return (dispatch) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(
        `alarm/add-alarm-in-project/${projectId}/${alarmIndex}/${giveAccess}`,
        {
          alarm_name: alarmName,
          description: description,
          language_ids: newLangid,
          language_details: newDetailid,
        }
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(
              "New alarm added successfully at the end of this list."
            )
          );
          const pagenumberLocal = Number(
            window.localStorage.getItem("pageNumber")
          );
          const rowPerPageLocal = Number(
            window.localStorage.getItem("rowPerPage")
          );
          dispatch(getProjectDetails(projectId, pagenumberLocal, rowPerPageLocal));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch({ type: ALARM_LOADER, payload: false });
        if (err?.response?.status === 500) {
        } else {
          //
          dispatch(fetchError(err?.response?.data?.message));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        }
      });
  };
};
//create google doc in alarm
export const createGoogleDoc = (
  projectId,
  alarmId,
  languageId,
  alarmServerSearch
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(
        `doc/create-alarm-docs/${projectId}/${alarmId}/${languageId}`
      )
      .then((res) => {
        dispatch(fetchSuccess("New document created successfully."));
        const CreatDocumentID = res.data.data.doc_id;
        if (CreatDocumentID) {
          window.open(
            `https://docs.google.com/document/d/${CreatDocumentID}`
          );
        }
        const pagenumberLocal = Number(
          window.localStorage.getItem("pageNumber")
        );
        const rowPerPageLocal = Number(
          window.localStorage.getItem("rowPerPage")
        );
        if (alarmServerSearch) {
          dispatch(
            getProjectDetails(projectId, 0, rowPerPageLocal, alarmServerSearch)
          );
        } else {
          dispatch(getProjectDetails(projectId, pagenumberLocal, rowPerPageLocal));
        }
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      })
      .catch((err) => {
        dispatch({ type: ALARM_LOADER, payload: false });
        dispatch(fetchError(err?.response?.data?.message));
        dispatch({ type: ALARM_LOADER, payload: false });
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};



//delete alarm in project
export const getDeleteAlarm = (projectId, alarmId, alarmServerSearch) => {
  return (dispatch) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .delete(`alarm/delete-alarm-in-project/${projectId}/${alarmId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Alarm deleted successfully."));
          const pagenumberLocal = Number(
            window.localStorage.getItem("pageNumber")
          );
          const rowPerPageLocal = Number(
            window.localStorage.getItem("rowPerPage")
          );
          if (alarmServerSearch) {
            dispatch(getProjectDetails(projectId, 0, rowPerPageLocal, alarmServerSearch));
          } else {
            dispatch(getProjectDetails(projectId, pagenumberLocal, rowPerPageLocal));
          }

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
        } else {
          //
          dispatch(fetchError(err?.response?.data?.message));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        }
      });
  };
};

//delete project
export const getDeleteProject = (projectId) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .delete(`/projects/delete-project/${projectId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Project deleted successfully."));

          dispatch(getAllAddionalList(""));
          history.push("/home");

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 1000);
          dispatch(getProjectList(""));
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
        } else {
          //
          dispatch(fetchError(err?.response?.data?.message));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        }
      });
  };
};

//upadate access of alarm in project(inherit alarm in child project)
export const getAccessAlarm = (projectId, alarmId, giveAccess) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(
        `/tick-untick-alarm-in-project/${projectId}/${alarmId}/${giveAccess}`
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Alarm access updated."));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
        } else {
          //
          dispatch(fetchError(err?.response?.data?.message));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        }
      });
  };
};

//update user access in project
export const getUserEdit = (projectId, email, edit) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(`projects/edit-user-access/${projectId}/${email}/${edit}`)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.message === "You can not remove edit access of parent project owner.") {
            dispatch(fetchError(data.data.message));
          } else {
            dispatch(fetchSuccess("User access updated successfully."));

          }

          dispatch(getProjectDetails(projectId));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(
          fetchError(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "User dont have write access."
          )
        );

        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};

//delete user access in project
export const getUserDelete = (projectId, email) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .delete(`/projects/remove-user-access/${projectId}/${email}`)
      .then((data) => {

        let userDelete = data.data.status
        if (userDelete) {
          if (data.data.message === 'You can not remove owner of parent project.') {
            dispatch(
              fetchError(data.data.message)

            );
          }
          else {
            dispatch(
              fetchSuccess(data.data.message)

            );
          }

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);

          dispatch(getProjectDetails(projectId));
        } else {
          dispatch(fetchSuccess("User deleted successfully."));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);

        }
      })
      .catch((err) => {
        dispatch(
          fetchError(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "User dont have write access."
          )
        );

        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        console.log("error", err);
      });
  };
};

// add new user in project
export const getUserAdd = (projectId, email, edit) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(`/projects/add-user-access/${projectId}`, {
        email: email,
        edit: edit,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("User Added succesfully."));

          dispatch(getProjectDetails(projectId));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);

          history.goBack();
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(
          fetchError(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "User dont have write access."
          )
        );

        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        console.log("error", err);
      });
  };
};

//alarm language in crazy tag component
export const setAlarmLanguage = (a) => {
  return (dispatch) => {
    const options = a.map(function (row) {
      return row._id;
    });
    dispatch({ type: SET_ALARMLANGUAGE, payload: options });
  };
};

//extra function for future use
export const setCurrentAbout = (item) => {
  return (dispatch) => {
    dispatch({ type: SET_ABOUT, payload: item });
  };
};

//Usable alrams file upload
export const usableFileUpload = (
  projectid, formData,callbackfun
) => {
  return (dispatch) => {
    // dispatch({ type: SET_FILEUPLOAD, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(`/alarm/usable-alarm-file-upload/${projectid}`,
        formData
      )
      .then((data) => {

        if (data.status === 200) {
          if(callbackfun) callbackfun();
          dispatch(fetchSuccess("File Uploaded successfully."));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          // dispatch(universalLoading(true));
          ;
        } 
        else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
          // dispatch(universalLoading(true));

        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        dispatch(universalLoading(true));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};


//Used Alarms File List
export const usableAlarmFileList = (
  projectid,
  page,
  limit
) => {

  return (dispatch) => {
    dispatch({ type: ALARM_LOADER, payload: true });

    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`/alarm/used-alarm-file-list/${projectid}/${page}/${limit}`)
      .then((data) => {
     
        if (data.status === 200) {
         if(limit) window.localStorage.setItem('u_l',limit)
          if(page) window.localStorage.setItem('u_p',page)
          dispatch({ type: GET_USABLEFILELIST, payload: data.data.data });
          dispatch({ type: ALARM_LOADER, payload: false });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
            
          );
          dispatch({ type: ALARM_LOADER, payload: false });
        }
      })
      .catch((err) => {  dispatch({ type: ALARM_LOADER, payload: false });});
  };
};

//Active AlarmsfileList
export const getActivateUsedAlarmFile = (
  project_id,file_id,callbackfun
) => {
  return (dispatch) => {
    
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`alarm/activate-used-alarm-file/${project_id}/${file_id}`)
      .then((data) => {
        
        if (data.status === 200) { 
          if(callbackfun) callbackfun();
          dispatch(fetchSuccess("File activated successfully."));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          dispatch({ type: GET_ACTIVATE_USED_ALARM_FILE, payload: data.data.data });
        } else {
         
          dispatch(
            
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};

//alarm Select-deselect 
export const getalarmSelectDeselect = (
  project_id,alarm_id,status,callbackfun
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`alarm/alarm-select-deselect/${project_id}/${alarm_id}/${!status}`)
      .then((data) => {
     
        if (data.status === 200) {
          if (callbackfun) callbackfun()
          // dispatch({ type: GET_ALARM_SELECT_DESELECT, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => { });
  };
};

//UPDATE PROJECT SETTING
export const getProjectSetting = (
projectid,

  data,callbackfun,isExport
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(`/projects/update-project-setting/${projectid}`, data)
      .then((data) => {
        if (data.status === 200) {
          if (callbackfun) callbackfun()
          if(!isExport) dispatch(fetchSuccess("Alarm setting updated successfully."));
          else{
            dispatch(fetchSuccess("Export setting updated successfully."));
          }
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          dispatch({ type: ALARM_LOADER, payload: false });
          // dispatch({ type: EXPORTLOADING, payload: false });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        // setTimeout(function () {
        //   dispatch(clearSnackbar());
        // }, 3000);
      });
  };
};

    
//get project_setting
