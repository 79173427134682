import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProjectSetting, getProjectDetails } from "../../Redux/actions/Project";
import {
  clearSnackbar,
  fetchError,
  fetchSuccess,
  universalLoading,
} from "../../Redux/actions/Common";
import { ALARM_LOADER } from '../../Utility/ActionConstant';
export default function AlarmSetting({ openSetting, setOpenSetting, projectid }) {
  // setOpenSetting={setOpenSetting}
  // openSetting={openSetting}
  const { ProjectDetails, projectSetting
  } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
 
  const [display, setDisplay] = useState("block"); //hide or show model state
  const [selectLang, setSelectLang] = useState([])
  const [pushLan,setPushLan] = useState(0);
  const [isUsedAlarmShow, setisUsedAlarmShow] = useState(false)
  const dispatch = useDispatch();


  useEffect(() => {
    setSelectLang(ProjectDetails[0].hide_language_array)
    setisUsedAlarmShow(ProjectDetails[0].show_used_alarm_column)
  }, [openSetting])
  const selectChange = (_id) => {
    // console.log(_id)
    setSelectLang([...selectLang, _id])
  }

  const saveAlarms = () => {
    const data ={
      show_used_alarm_column: isUsedAlarmShow,
      hide_language_ids: selectLang,
      export_used_alarm: null,
      merge_with_page_break: null,
      fill_with_number: null,
      merge_used_alarm: null
    }
    dispatch({ type: ALARM_LOADER, payload: true })
    dispatch(getProjectSetting(projectid,data, projectDetailsCallBack))
    setOpenSetting('');
  }
  const projectDetailsCallBack = () => {
    dispatch(getProjectDetails(ProjectDetails[0]._id, Number(window.localStorage.getItem('pageNumber')),Number(
      window.localStorage.getItem("rowPerPage")
    )));

  }
  const removeLang = (_id) => {
    setSelectLang(prevState => {
      if (prevState.includes(_id)) {
        return prevState.filter(item => item !== _id)
      }
    }
    )
  }


  return (
    <div
    key={pushLan}
      className="modal fade show"

      id="exampleModal"
      tabIndex="-1"

      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{ display: openSetting, top: "33%" ,position:'-webkit-sticky'}}
    >
      <div className="modal-dialog" >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Alarm Settings</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() =>{ setOpenSetting(""); setPushLan(pushLan+1)}}
            ></button>
          </div>
          <div className="modal-body" 
          style={{overflow:'auto', maxHeight:'290px'}}>
            <tr><td
              style={{
                     
                      fontWeight: "600",
                      color: "#2f4c41b3",
                      fontWidth: "14px",
                      fontSize: "14px",
                    }}
            >
            Hide/Show Language column
            </td>
            <td
              style={{
                      paddingLeft:'36px',
                      fontWeight: "600",
                      color: "#2f4c41b3",
                      fontWidth: "14px",
                      fontSize: "14px",
                    }}
            >
              Hide/Show Used Alarm Column</td>
            </tr>
            <tr>
              {ProjectDetails
                ? ProjectDetails[0].languages.map((item, index) => (
                  <tr>
                    <td key={index}>
                      <label className="cust_checkbox">
                        <input
                          type="checkbox"
                          defaultChecked={!ProjectDetails[0].hide_language_array.includes(item._id)}
                          onChange={() => !selectLang.includes(item._id) ? selectChange(item._id) : removeLang(item._id)}
                        />
                        <span className="checkmark"></span>
                      <span>  {item.name}</span>
                      </label>
                    </td>
                  </tr>
                ))
                : ""}
              <td>
                <input
                  style={{ marginLeft: '40px' }}
                  type='checkbox'
                  defaultChecked={ProjectDetails[0].show_used_alarm_column}
                  onChange={() => setisUsedAlarmShow(!isUsedAlarmShow)}>
                </input>
                <span className="checkmark"></span>
                <label style={{ marginLeft: '10px' }}> Used Alarm </label>

              </td>
            </tr>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={() =>{ setOpenSetting(""); setPushLan(pushLan+1)} }
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={saveAlarms}
            >
              <b>Save</b>
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

