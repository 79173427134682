import React from "react";
import { Route, Switch } from "react-router-dom";
import Profile from "../Profile/Profile";
import AboutUs from "../ContentPage/AboutUs";
import ContactUs from "../ContentPage/ContactUs";
import TermsandConditions from "../ContentPage/TermsandConditions";
import FAQPage from "../ContentPage/FAQPage";
import PrivacyPolicy from "../ContentPage/PrivacyPolicy";
import CookiePolicy from '../ContentPage/CookiePolicy';
import ChangePassword from "../ContentPage/ChangePassword";
import EditProfile from "../Profile/EditProfile";
import AddProject from "../Projects/AddProject";
import AddUser from "../UsersList/AddUser";
import ProjectDetails from "../Projects/ProjectDetails";
import MainHomepage from "./MainHomepage";
import EditProject from "../Projects/EditProject";
import ProjectNotAvailibel from "../Projects/ProjectNotAvailibel";
import ProjectDetailsNotAvailabel from "../Projects/ProjectDetailsNotavailabel";
import { useSelector } from "react-redux";
import AdditionalAlarms from "../Additional Alarms/AdditionalAlarms";
import AdditionalAlarmNameEdit from "../Additional Alarms/AdditionalAlarmNameEdit";
import DocumentTemplet from '../Projects/documentTemplet'
import Globalmergemodel from "../../../Components/Globalmergemodel";
function MainContent() {
  const { title } = useSelector(({ BreadcumsReducer }) => BreadcumsReducer);
  return (
    <main
      className={
        title?.title === ""
          ? "main-section main-section-no-data"
          : "main-section"
      }
      style={{overflow:'hidden'}}
    >
      <Switch>
        <Route exact path="/home/" component={MainHomepage} />
        <Route path="/home/profile" component={Profile} />
        <Route path="/home/aboutus" component={AboutUs} />
        <Route path="/home/contactus" component={ContactUs} />
        <Route path="/home/termsandconditions" component={TermsandConditions} />
        <Route path="/home/faq" component={FAQPage} />
        <Route path="/home/privacypolicy" component={PrivacyPolicy} />
        <Route path="/home/CookiePolicy" component={CookiePolicy} />
        <Route path="/home/changepassword" component={ChangePassword} />
        <Route path="/home/editprofile" component={EditProfile} />
        <Route path="/home/projectlist/addproject" component={AddProject} />
        <Route path="/home/projectlist/adduser" component={AddUser} />
        <Route path="/home/additonalalarms/:id" component={AdditionalAlarms} />
        <Route
          path="/home/additonalalarmsname"
          component={AdditionalAlarmNameEdit}
        />
        <Route
          path="/home/projectlist/projectdetail/:id"
          component={ProjectDetails}
        />
        <Route path="/home/projectlist/editproject" component={EditProject} />
        <Route
          path="/home/projectlist/projecterror"
          component={ProjectNotAvailibel}
        />
        <Route
          path="/home/projectlist/projectdetailnotavailabel"
          component={ProjectDetailsNotAvailabel}
        />
        <Route path="/home/Routes/Pages/documentTemplet" component={DocumentTemplet}/>
        {/* <Route path="/home/Routes/Pages/Globalmergemodel" component={Globalmergemodel}/> */}
      </Switch>
    </main>
  );
}

export default MainContent;
