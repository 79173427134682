import React, { useEffect, useState } from "react";
import GlobalMerge from "../../../Components/Layout/GlobalMerge";
import LanguageMerge from "../../../Components/Layout/LanguageMerge";
import { ExportToExcel } from "../../../Utility/ExcleExport";
import { useDispatch, useSelector } from "react-redux";
import { ExportAPI } from "../../../Redux/actions/Export";
import { useParams } from "react-router-dom";
import { MERGESTATUS } from "../../../Utility/ActionConstant";
import { getProjectSetting } from "../../../Redux/actions/Project";
import AlarmLoaderComponent from "../../../Components/Layout/AlarmLoaderComponent";
import { exportProjectSetting } from "../../../Redux/actions/Export";
import { ALARM_LOADER, EXPORTLOADING } from "../../../Utility/ActionConstant";
import { BitlistExcleExport } from "../../../Utility/BitlistExcleExport";
function ProjectExport() {
  const { id } = useParams();
  
  const { exportDetails, mergeStatus, ExportLoading, settings ,projectBitlist} = useSelector(
    ({ Export }) => Export
  );
console.log("settings", settings)
  const [isExportUsedAlarm, setIsExportUsedAlarm] = useState(false);
  const [isFillWithNumber, setIsFillWithNumber] = useState(false);
  console.log('id',id,exportDetails?._id)

  // console.log("includAdditionalAlarm",includAdditionalAlarm)
const [isExportWithUsedAlarm, setIsExportWithUsedAlarm] = useState(false);
// const [isExportallAlarm, setIsExportallAlarm] = useState(false);

const [fileData, setFileData] = useState([]);

const [includAdditionalAlarm, setIncludAdditionalAlarm] = useState(true)
const [additonalList, setadditonalList] = useState([])

console.log('additonalList',additonalList)
  const dispatch = useDispatch();
 
  if (mergeStatus) {
    dispatch(ExportAPI(id));
    dispatch({ type: MERGESTATUS, payload: false });
  }

  const exportUsedAlarm = () => {
    // setIsExportUsedAlarm(!isExportUsedAlarm);
    console.log('isExportUsedAlarm',isExportUsedAlarm);
    const data=
    {
      show_used_alarm_column: null,
      hide_language_ids:null,
      export_used_alarm: !settings.exportUsedAlarm,
      merge_with_page_break: null,
      fill_with_number: !settings.exportUsedAlarm? false:null,
      merge_used_alarm: null
    }
    dispatch({ type: EXPORTLOADING, payload: true });
    dispatch({ type: ALARM_LOADER, payload: true });
    dispatch(getProjectSetting(id,data,callbackFun,true));
  
  }    

  const usedAlarmWithNumber = () => {
    // setIsFillWithNumber(!isFillWithNumber);
    const data=
    {
      show_used_alarm_column: null,
      hide_language_ids:null,
      export_used_alarm: !settings.isFillWithNumber?false:null,
      merge_with_page_break: null,
      fill_with_number: !settings.fillEmptyAndUnusedAlarmWithNumber,
      merge_used_alarm: null
    }
    dispatch({ type: EXPORTLOADING, payload: true });
    dispatch({ type: ALARM_LOADER, payload: true });
    dispatch(getProjectSetting(id,data,callbackFun,true));
  }

  const exportWithUsedAlarm = () => {
    setIsExportWithUsedAlarm(!isExportWithUsedAlarm);
    // dispatch({ type: EXPORTLOADING, payload: true });
    // dispatch({ type: ALARM_LOADER, payload: true });
   
    // const data = {
    //   isUsedAlarmsExport:!isExportWithUsedAlarm,
    //   isAllAlarmsExport:!isExportWithUsedAlarm?false:true
    // };
    // setFileData(data)
    // setTimeout(() => {
    //   dispatch({ type: EXPORTLOADING, payload: false });
    //   dispatch({ type: ALARM_LOADER, payload: false });
    // }, 3000); 
  }

  // const exportWithAllAlarms = () => {
  //   setIsExportallAlarm(true);
  //   dispatch({ type: EXPORTLOADING, payload: true });
  //   dispatch({ type: ALARM_LOADER, payload: true });
  //   const data = {
  //     isUsedAlarmsExport:!isExportallAlarm?false:true,
  //     isAllAlarmsExport:!isExportallAlarm
  //   };
  //   setFileData(data)
  //   setTimeout(() => {
  //     dispatch({ type: EXPORTLOADING, payload: false });
  //     dispatch({ type: ALARM_LOADER, payload: false });
  //   }, 3000);
  // }

  const additionalAlarm = () => {
    setIncludAdditionalAlarm(!includAdditionalAlarm)
    // const data = {
    //   include_additional_alarm:!includAdditionalAlarm
    // }
    // setadditonalList(data)
  }
  const callbackFun = () => {
    dispatch(exportProjectSetting(id));
  };
  if (!ExportLoading) {
    return (
      <>
        {/* {excle export for project start ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>} */}
        <div className="main-inner-card alarm-tab-section">
          <table className="table ">
            <thead>
              <tr>
                <th scope="col" style={{ width: "320px" }}>
                  TIA Portal
                </th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PLC Alarm Text List</td>
                <td>
                  <label className="cust_checkbox" style={{ marginLeft: "175px" }}>
                    <input type="checkbox" defaultChecked={settings.exportUsedAlarm} onChange={exportUsedAlarm}/>
                    <label></label>
                    <span className="checkmark"></span>
                  </label>

                  <label style={{marginLeft: "8px",fontSize: "14px",fontWeight: "600",color: " #2f4c41b3", }} >
                    Only export used alarm
                  </label>
                  <br />
                  <br />

                  <label className="cust_checkbox"style={{ marginLeft: "175px" }}>
                    <input type="checkbox" defaultChecked={settings.fillEmptyAndUnusedAlarmWithNumber}
                      onChange={usedAlarmWithNumber}
                    />
                    <label></label>
                    <span className="checkmark"></span>
                  </label>
                  <label style={{marginLeft: "5px",fontSize: "14px",fontWeight: "600", color: " #2f4c41b3",}} >
                    Fill empty and unused alarm with number{" "}
                  </label>
                  <br />
                  <br />

                  <label className="cust_checkbox"style={{ marginLeft: "175px" }}>
                    <input type="checkbox"
                    defaultChecked={!includAdditionalAlarm}
                    onChange={additionalAlarm}
                    />
                    <label></label>
                    <span className="checkmark"></span>
                  </label>
                  <label style={{ marginLeft: "5px", fontSize: "14px",fontWeight: "600",color: " #2f4c41b3",}}>
                  Export file without additional alarms list{" "}
                  </label>
                </td>

                <td className="text-end">
                  {/* <a href={excleApiLink}> <button className="export-btn" > Export</button></a> */}

                  <ExportToExcel
                    pid={id}
                    fileName={"filename"}
                    additonalLists = {includAdditionalAlarm}
                  />
                </td>
                
                </tr>
               
                 
                 <tr style={{border:' 1px solid rgba(0,0,0,.15)'}}/>
                  {/* <hr className="dropdown-divider w-100 float-start my-3" /></tr> */}
            
              <tr style={{backgroundColor:'white'}}>
                <td>PLC - Alarm Lib - Bit List</td>
                <td>
                  <br/>
                  <label className="cust_checkbox" style={{ marginLeft: "175px" }}>
                    <input type="checkbox" 
                    
                    defaultChecked = {isExportWithUsedAlarm}
                    onChange={exportWithUsedAlarm}
                    />
                    <label></label>
                    <span className="checkmark"></span>
                  </label>

                  <label style={{marginLeft: "8px",fontSize: "14px",fontWeight: "600",color: " #2f4c41b3", }} >
                  Only export used alarm
                  </label>
                  <br />
                  <br />
                  </td>
                  <td className="text-end">
                  

                  <BitlistExcleExport
                   pid={id}
                   isExportWithUsedAlarm = {isExportWithUsedAlarm}
                  />
                
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <hr className="dropdown-divider w-100 float-start my-3" />
      <div className="main-inner-card alarm-tab-section">
        <table className="table ">
         
            <tbody>
            <tr>
              <td className="col" style={{ width: "320px" }}>
              PLC - Alarm Lib - Bit List
              </td>
             
                
                <td>
                 
                  <label className="cust_checkbox" style={{ marginLeft: "175px" }}>
                    <input type="checkbox" 
                    
                    defaultChecked = {isExportWithUsedAlarm}
                    onChange={exportWithUsedAlarm}
                    />
                    <label></label>
                    <span className="checkmark"></span>
                  </label>

                  <label style={{ marginLeft: "5px",fontSize: "14px",fontWeight: "600",color: " #2f4c41b3", }} >
                  Only export used alarm
                  </label>
               
                  </td>
                  <td className="text-end">
                  <BitlistExcleExport
                   pid={exportDetails ? exportDetails._id : null}
                   isExportWithUsedAlarm = {isExportWithUsedAlarm}
                  />
                
                </td>
              </tr>
              </tbody></table></div> */}
        {/*  {excle export for project end ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}*/}

        <LanguageMerge />
        {/*  {Language google doc merge & export for project start ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}*/}

        {/*  {Language google doc merge & export for project end ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}*/}

        {/*  {Global google doc merge & export for project start ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}*/}
        <GlobalMerge />
        {/* <Globalmergemodel/> */}
        {/*  {Global google doc merge & export for project start ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}*/}
      </>
    );
  } else {
    return <AlarmLoaderComponent />;
  }
}

export default ProjectExport;
