import {
  GET_ABOUTUS,
  GET_PRIVACYPOLICY,
  GET_TERMSANDCONDITON,
  GET_FAQPAGE,
} from "../../Utility/ActionConstant";
const INIT_STATE = {
  AboutUs: [],
  PrivacyPolicy: [],
  TermsAndCondition: [],
  FAQ: [],
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ABOUTUS: {
      return {
        ...state,
        AboutUs: action.payload,
      };
    }
    case GET_PRIVACYPOLICY: {
      return {
        ...state,
        PrivacyPolicy: action.payload,
      };
    }
    case GET_TERMSANDCONDITON: {
      return {
        ...state,
        TermsAndCondition: action.payload,
      };
    }
    case GET_FAQPAGE: {
      return {
        ...state,
        FAQ: action.payload,
      };
    }

    default:
      return state;
  }
};
