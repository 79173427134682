import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usableFileUpload, usableAlarmFileList } from '../../Redux/actions/Project'
import { ALARM_LOADER } from "../../Utility/ActionConstant";
export default function UploadFile({ openUploadModule, setOpenUploadModule, projectid }) {

  const { ProjectDetails,
  } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const ref = useRef();
  const [display, setDisplay] = useState("block"); //hide or show model state
  const dispatch = useDispatch();
  const [files, setFiles] = useState('');
  const [ShowErr, setShowErr] = useState('');
  const [fileTypeErr, setFileTypeErr] = useState('')
  const UploadData = () => {
   
    if (!files) {
      setShowErr("Please choose text file.");
      return false;
    } else if (files && files.type !== "text/plain") {
      setShowErr("Please choose text file.");
      return false
    } else {
      const formData = new FormData();
      formData.append('usable_alarm', files);
      dispatch({ type: ALARM_LOADER, payload: true })
      dispatch(usableFileUpload(projectid, formData, projectDetailsCallBack));
      ref.current.value = "";
      setShowErr('');
      setFiles(' ');
      setFileTypeErr('');
      setOpenUploadModule("");

      return true;
    }

  }

  const projectDetailsCallBack = () => {
    
    if (Number(window.localStorage.getItem('u_p'))===0){
      dispatch(usableAlarmFileList(ProjectDetails[0]._id));
    }
    else{
    window.localStorage.setItem('u_p',0)
    }
    
  }
  const handelCancel = () => {
    ref.current.value = "";
    setShowErr('');
    setFileTypeErr('');
    setFiles('')
    setOpenUploadModule(' ');
  }
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{ display: openUploadModule, top: "40%" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Upload File</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setOpenUploadModule(""); ref.current.value = "";
                setShowErr(''); setFileTypeErr(''); setFiles('')
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div style={{maxHeight:'15px'}}>
            <p
             style={{
              textOverflow: 'ellipsis',
              maxWidth: '350px',
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            ><input
              type='file'
              onChange={(e) => {setFiles(e.target.files[0]);setShowErr('')}}
              ref={ref}
              accept='.txt'
              
              style={{
                textOverflow: 'ellipsis',
                maxWidth: '240px',
                display: 'block',
                cursor:'pointer',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              // style={{ wordWrap: 'break-word' }}
            /></p>
            </div>
            <br />
            {ShowErr ? <span style={{ color: 'red', fontSize: '14px' }}>{ShowErr}</span> : null}
            {/* {fileTypeErr ? <span style={{ color: 'red', fontSize: '14px' }}>{fileTypeErr}</span> : null} */}

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={handelCancel}
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={UploadData}

            >
              <b>Upload</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

