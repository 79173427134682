import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAdd } from "../../../Redux/actions/Project";
import { useHistory } from "react-router-dom";
import { UPDATE_TITLE } from "../../../Utility/ActionConstant";
import { clearSnackbar, fetchError } from "../../../Redux/actions/Common";
const titleData = {
  title: "Add New User",
};
function AddUser() {
  let history = useHistory();
  const { ProjectDetails } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [buttonDisable,setButtonDisable]=useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
  }, [dispatch, ProjectDetails]);
  const handleSave = () => {
if(ProjectDetails[0]?.created_by?.email===email){
  dispatch(
    fetchError('User already got Access to this project')
  );
  setTimeout(function () {
    dispatch(clearSnackbar());
  }, 3000);
}
  else{ setButtonDisable(true)
    dispatch(getUserAdd(ProjectDetails[0]._id, email, edit));
    setTimeout(() => {
      setButtonDisable(false)
    }, 3000);
  }
  };
  const goToPreviousPath = () => {
    history.goBack();
  };
  return (
    <div className="main-inner-card d-flex justify-content-center align-items-center">
      <div>
        <div className="add-user-box">
          <p className="add-user-box-text">
            Please enter the email address of a user whom you wish to invite in
            this project.{" "}
          </p>
          <p className="add-user-box-text">
            That user will get an email invite to join this project.
          </p>
        </div>
        <div className="profile-box">
          <div className="form-row">
            <div className="row">
              <div className="col">
                <label for="Company-Name" className="col-sm-12 col-form-label ">
                  {" "}
                  Email Address{" "}
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email Address"
                  aria-label="Company-Name"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div id="name" className="invalid-feedback">
                  Enter Email Address
                </div>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="row">
              <div className="col">
                <label for="Company-Name" className="col-sm-12 col-form-label ">
                  {" "}
                  User Access{" "}
                </label>
                <label className="cust_checkbox">
                  View
                  <input type="checkbox" checked />
                  <span className="checkmark"></span>
                </label>
                <label className="cust_checkbox">
                  Edit
                  <input
                    type="checkbox"
                    checked={edit}
                    onChange={(e) => setEdit(edit ? false : true)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="profile-btn">
            <button className="cancel-btn" onClick={goToPreviousPath}>
              {" "}
              Cancel{" "}
            </button>
            <button className="primary-btn" onClick={handleSave} disabled={buttonDisable}>
              {" "}
              Save{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
