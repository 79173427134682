import React from 'react'
import BreadCums from '../../../Components/Layout/BreadCums'
import Footer from '../../../Components/Layout/Footer'
import Header from '../../../Components/Layout/Header'
import SideBar from '../../../Components/Layout/SideBar'
import CookieConsent, { Cookies } from "react-cookie-consent";

import MainContent from './MainContent'

function HomePage() {
    return (
        <div>
            {/* main layout page */}
           
            <SideBar />
                <div className="right-section">
                    <Header />
                    <BreadCums/>
                    <MainContent />
                    <Footer />
                   
                </div>
                {/* <CookieConsent  style={{zIndex:99999}} location="bottom" cookieName="myAwesomeCookieName3" expires={999} overlay buttonStyle={{background:'white'}}>
  This website uses cookies to enhance the user experience. <a href="#/CookiePolicy" target="_blank">Cookie Policy</a> <a href="#/termsandcondition" target="_blank">Terms & Conditions</a> <a href="#/PrivacyPolicy" target="_blank">Privacy Policy</a>
</CookieConsent> */}
        </div>
    )
    
}

export default HomePage
