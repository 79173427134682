import * as FileSaver from "file-saver";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as XLSX from "xlsx";
import { clearSnackbar, fetchError } from "../Redux/actions/Common";
import { useState, useEffect } from "react";
import { history } from "../Redux/store";
import axios from "./jwt/config";
// import { ALARM_LOADER, EXPORTLOADING } from "../../../Utility/ActionConstant";
import { ALARM_LOADER,EXPORTLOADING } from "./ActionConstant";
import { getProjectSetting } from "../Redux/actions/Project";
export const ExportToExcel = ({ pid,exportUsedAlarm,alarmFillWithNumber,additonalLists }) => {
console.log("additonalList",additonalLists)
const data1 = {
  include_additional_alarm:additonalLists
}

const [data, setdata] = useState();
console.log("additionaldata",data)
useEffect(() => { 
  let obj1 = Object.entries(data1);
  let obj2 = obj1.map(([key, val]) => {
      let newData = `${key}=${val}`;
      setdata(newData);
  });
}, [data1]);

  const dispatch = useDispatch();
  const [exportButton, setExportButton] = useState("Export");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const handleExport = () => {

    setExportButton("Downloading...");
    // dispatch({ type: EXPORTLOADING, payload: true });
    // dispatch({ type: ALARM_LOADER, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`/alarm/alarm-export-in-project/${pid}?${data}`)
      .then((data) => {
        console.log('exportData',data.data.data)
        setExportButton("Export");
        // setdata('');
        const excleApi = data.data.data.url;

        window.open(data.data.data.url, "_self");
        // dispatch({ type: EXPORTLOADING, payload: false });
        // dispatch({ type: ALARM_LOADER, payload: false });
      })
      .catch((err) => {
        setExportButton("Export");
        dispatch(fetchError("There is something issue in responding server."));
        setTimeout(function () {
          dispatch(clearSnackbar());      
        }, 3000);
     
          // dispatch({ type: EXPORTLOADING, payload: false });
          // dispatch({ type: ALARM_LOADER, payload: false });
     
      });
    
  };

  return (
    <>
         <button className="export-btn" onClick={handleExport}>
      {exportButton} <i className="bi bi-file- -excel-fill"></i>
    </button>
       
    </>
  );
};
