import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AlarmLoaderComponent from "../../../Components/Layout/AlarmLoaderComponent";
import DeleteModel from "../../../Components/Layout/DeleteModel";
import {
  getAddAdditionalAlarm,
  getAddAlarmListDetails,
  getDeleteAddAlarm,
  getEditAdditionalAlarm,
} from "../../../Redux/actions/AddAlarmList";
import { universalLoading } from "../../../Redux/actions/Common";

import { history } from "../../../Redux/store";
import {
  ALARM_LOADER,
  SET_PROJECTDETAILS_LOADING,
  UPDATE_TITLE,
} from "../../../Utility/ActionConstant";

function AdditionalAlarms() {
  const { id } = useParams(); //get additional alarm list id from url
  const rowPerPageLocal = Number(
    window.localStorage.getItem("rowPerPage")
  );
  const { AddAlarmListDetails, allPage } = useSelector(
    ({ AddAlarmList }) => AddAlarmList
  ); //get additional alarm details from store
  const { ALARMLOADER } = useSelector(({ common }) => common); // get loading stat from store
  const [editRight, setEditRight] = useState(
    AddAlarmListDetails ? AddAlarmListDetails?.alarmDetails[0]?.edit : false
  );
  const [alarmName, setAlarmName] = useState("");
  const [description, setDescription] = useState("");
  const [alarmId, setAlarmId] = useState(null);
  const [addAlarmName, setAddAlarmName] = useState("");
  const [alarmNameError, setAlarmNAmeError] = useState("");
  const [addDescription, setAddDescription] = useState("");
  const [alarmDescritptionError, setAlarmDescritptionError] = useState("");
  const [addLanguageId, setAddLangugaeId] = useState("");
  const [addLanguageIds, setAddLanguageIds] = useState([]);
  const [addLanguageDetail, setAddLanguageDetail] = useState("");
  const [addLanguageDetails, setAddLanguageDetails] = useState([]);
  //server side search for additional alarm
  const [addAlarmSearch, setAddAlarmSearch] = useState("");
  const [addAlarmArry, setAddAlarmArry] = useState(
    AddAlarmListDetails ? AddAlarmListDetails?.alarmDetails[0]?.alarms : []
  );
  //local pagination for addtional alarm
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [paginationIndexID, setPaginationIndexID] = useState(0);
  const [paginationUserIndex, setPaginationUserIndex] = useState(0);
  const [paginationUserIndexID, setPaginationUserIndexID] = useState(0);
  const [open, setOpen] = useState(false);
  // added index functinality for additional list
  const [additonalIndex, setAdditonalIndex] = useState(null);
  const [addIndexError, setAddIndexError] = useState(null);
  // server side pagination
  const [pageNumber, setPageNumber] = useState(null);
  const [search, setSearch] = useState("");
  const [rowPerpage, setRowPerpage] = useState(rowPerPageLocal ? rowPerPageLocal : 20);
  const [windowWidth,setWindowWidth]=useState(window.screen.width)
  // const [allPage,PetAllPage]=useState([])
  const dispatch = useDispatch();
  let found = null;

  const titleData = {
    title: "Additional Alarm List",
  };

  //excute api call for additional alarm list if details are null in store
  if (AddAlarmListDetails === null) {
    const rowPerPageLocal = Number(
      window.localStorage.getItem("rowPerPage")
    );
    console.log('add', rowPerPageLocal)
    window.localStorage.setItem("addpageNumber", Math.floor(0));
    dispatch(getAddAlarmListDetails(id, 0, rowPerPageLocal));
  }
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    const additionalLocalsearch =
      window.sessionStorage.getItem("additionalsearch");
    setSearch(additionalLocalsearch ?? "");
    const pageLocal = Number(window.localStorage.getItem("addpageNumber"));
    setPageNumber(pageLocal);
    const additionalAlarmSearchData = AddAlarmListDetails
      ? AddAlarmListDetails?.alarmDetails[0]?.alarms?.filter((val) => {
        if (addAlarmSearch === "") {
          return val;
        } else if (
          val.alarm_name
            .toLowerCase()
            .includes(addAlarmSearch.toLocaleLowerCase())
        ) {
          return val;
        }
      })
      : "";
    const pageIndex = Number(window.localStorage.getItem("addpageIndex"));
    const pageIndexId = Number(window.localStorage.getItem("addIndexId"));
    const startIndex = Number(window.localStorage.getItem("addstartIndex"));
    const endIndex = Number(window.localStorage.getItem("addendIndex"));

    setPaginationIndex(pageIndex ? pageIndex : 0);
    setPaginationIndexID(pageIndexId ? pageIndexId : 0);
    if (addAlarmSearch !== "") {
      setAddAlarmArry(additionalAlarmSearchData.slice(0, rowPerPageLocal));
    } else {
      setAddAlarmArry(
        additionalAlarmSearchData.slice(startIndex, endIndex ? endIndex : rowPerPageLocal)
      );
    }
  }, [addAlarmSearch, AddAlarmListDetails]);

  useEffect(() => {
    if (AddAlarmListDetails?.alarmDetails[0]?.edit) {
      if (AddAlarmListDetails) {
        AddAlarmListDetails?.alarmDetails[0].languages.forEach(
          (item, index) =>
            (document.getElementById(`addlanguage${index}`).value = "")
        );
      }
      setAddAlarmName("");
      setAddDescription("");
      setAdditonalIndex(null);
      document.getElementById(`addalarmName`).value = "";
      document.getElementById(`adddescription`).value = "";
      document.getElementById(`additonalIndex`).value = "";
    }
  }, [AddAlarmListDetails]);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize',handleResize)
    }
  })
  
  const handleResize=()=>{
    setWindowWidth(window.screen.width)
  }
  //phase_4 Enter line function
  const enterFocusOut = (e) => {
    if (e.charCode === 13) {
      document.getElementById('alarmName').focus({ preventScroll: true });
    }
  }


  // edit additional alarm
  const handleKeyPress = (event) => {
    if (event.target.id === "alarmName") {
      setAlarmName(event.target.innerText);
      dispatch(
        getEditAdditionalAlarm(
          event.target.innerText === "" ? "\n" : event.target.innerText,
          "",
          [],
          [],
          AddAlarmListDetails?.alarmDetails[0]._id,
          alarmId,
          search
        )
      );
    } else if (event.target.id === `description`) {
      setDescription(event.target.innerText);

      dispatch(
        getEditAdditionalAlarm(
          "",
          event.target.innerText === "" ? "\n" : event.target.innerText,
          [],
          [],
          AddAlarmListDetails?.alarmDetails[0]._id,
          alarmId,
          search
        )
      );
    }

    if (event.target.id === "language") {
      dispatch(
        getEditAdditionalAlarm(
          "",
          "",
          [event.target.accessKey],
          [event.target.innerText],
          AddAlarmListDetails?.alarmDetails[0]._id,
          event.target.slot,
          search
        )
      );
    }
  };
  //excute edit additional alarm on enter
  const handleUpdate = (event) => {
    if (event.key === "Enter") {
      dispatch(
        getEditAdditionalAlarm(
          "",
          "",
          [event.target.accessKey],
          [event.target.innerText],
          AddAlarmListDetails?.alarmDetails[0]._id,
          event.target.slot,
          search
        )
      );
    }
  };

  {
    /* add additionalalarm */
  }
  const handleAddAlarm = (event) => {
    additonalIndex !== null &&
      additonalIndex !== "" &&
      additonalIndex >= 0 &&
      additonalIndex -
      AddAlarmListDetails?.alarmDetails[0]?.highest_alarm_number <=
      10000 &&
      additonalIndex <= 65536
      ? setAddIndexError("")
      : setAddIndexError("error");
    if (
      event.key === "Enter" &&
      event.target.id === "addalarmName" &&
      addAlarmName !== ""
    ) {
      document.querySelector(`#adddescription`).focus();
    } else if (
      event.key === "Enter" &&
      event.target.id === "adddescription" &&
      addDescription !== "" &&
      addAlarmName !== "" &&
      additonalIndex !== null &&
      additonalIndex !== "" &&
      additonalIndex >= 0 &&
      additonalIndex -
      AddAlarmListDetails?.alarmDetails[0]?.highest_alarm_number <=
      10000 &&
      additonalIndex <= 65536
    ) {
      dispatch(
        getAddAdditionalAlarm(
          additonalIndex,
          addAlarmName,
          addDescription,
          [...addLanguageIds, addLanguageId],
          [...addLanguageDetails, addLanguageDetail],
          AddAlarmListDetails?.alarmDetails[0]._id
        )
      );
      if (AddAlarmListDetails) {
        AddAlarmListDetails?.alarmDetails[0].languages.forEach(
          (item, index) =>
            (document.getElementById(`addlanguage${index}`).value = "")
        );
      }
      setAddAlarmName("");
      setAddDescription("");
      setAddLanguageDetails([]);
      setAdditonalIndex(null);
      setAddLanguageIds([]);
      setAddLangugaeId("");
      setAddLanguageDetail("");
      document.getElementById(`addalarmName`).value = "";
      document.getElementById(`additonalIndex`).value = "";
      document.getElementById(`adddescription`).value = "";
    }

    if (
      event.key === "Enter" &&
      event.target.accessKey === "addlanguage" &&
      addDescription !== "" &&
      addAlarmName !== "" &&
      additonalIndex !== null &&
      additonalIndex !== "" &&
      additonalIndex >= 0 &&
      additonalIndex -
      AddAlarmListDetails?.alarmDetails[0]?.highest_alarm_number <=
      10000 &&
      additonalIndex <= 65536
    ) {
      setAddLanguageDetails([...addLanguageDetails, addLanguageDetail]);

      setAddLanguageIds([...addLanguageIds, addLanguageId]);

      dispatch(
        getAddAdditionalAlarm(
          additonalIndex,
          addAlarmName,
          addDescription,
          [...addLanguageIds, addLanguageId],
          [...addLanguageDetails, addLanguageDetail],
          AddAlarmListDetails?.alarmDetails[0]._id
        )
      );
      if (AddAlarmListDetails) {
        AddAlarmListDetails?.alarmDetails[0].languages.forEach(
          (item, index) =>
            (document.getElementById(`addlanguage${index}`).value = "")
        );
      }
      setAddAlarmName("");
      setAddDescription("");
      document.getElementById(`addalarmName`).value = "";
      document.getElementById(`adddescription`).value = "";
      document.getElementById(`additonalIndex`).value = "";
      setAddLanguageDetails([]);
      setAdditonalIndex(null);
      setAddLanguageIds([]);
      setAddLangugaeId("");
      setAddLanguageDetail("");
    }
  };
  {
    /* pagination */
  }
  // phase 4
  const handleRowPerpage = (e) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    setRowPerpage(Number(e.target.value));
    window.localStorage.setItem("addpageNumber", 0);
    window.localStorage.setItem('rowPerPage',e.target.value)
    // setPagenumberAlarm(0);
    setPageNumber(0)
    if (search.length > 2) {
      dispatch(getAddAlarmListDetails(id, 0, e.target.value, search));
    }
    else {

      dispatch(getAddAlarmListDetails(id, 0, e.target.value, undefined));
    }
  }
  const handlePagination = (id, pageNumber) => {
    if (pageNumber >= 0) {
      dispatch({ type: ALARM_LOADER, payload: true });
      dispatch(getAddAlarmListDetails(id, pageNumber, rowPerpage, undefined));
      setPageNumber(pageNumber);
      window.localStorage.setItem("addpageNumber", Math.floor(pageNumber));
    }
  };
  //additional alarm search
  const handleSearch = () => {
    if (search.length > 2) {
      dispatch({ type: ALARM_LOADER, payload: true });
      dispatch(getAddAlarmListDetails(id, 0, rowPerpage, search));
      setPageNumber(0);
      window.sessionStorage.setItem("additionalsearch", search);
    } else if (!search.length) {
      dispatch({ type: ALARM_LOADER, payload: true });
      dispatch(getAddAlarmListDetails(id, 0, rowPerpage, undefined));
      window.sessionStorage.setItem("additionalsearch", search);
      window.localStorage.setItem("addpageNumber", Math.floor(0));
      setPageNumber(0);
    }
  };
  //excute additional alarm seacrh on enter
  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div className="tab-menu-section">
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-contact"
            type="button"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="true"
          >
            Additional Alarms
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Projects
          </a>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
        >
          <div className="alarm-tab-section-title">
            <h4 id='additionalAlarmHeaderName'>
              {/* <h4> */}
              {AddAlarmListDetails
                ? AddAlarmListDetails?.alarmDetails[0]?.a_alarm_name
                : "Additional Alarm List"}
            </h4>
            <div className="alarm-tab-section-box">
              {AddAlarmListDetails?.alarmDetails[0]?.edit ? (
                <button
                  className="primary-btn"
                  onClick={() => history.push("/home/additonalalarmsname")}
                >
                  Edit Name
                </button>
              ) : null}

              <div className="alarm-tab-section-search">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                  onKeyUp={handleSearchEnter}
                />
                <button onClick={handleSearch}>
                  <img src={process.env.PUBLIC_URL + "/img/search-white.svg"} />
                </button>
              </div>
            </div>
            {/* delete icon */}
          </div>

          <div className="main-inner-card alarm-tab-section sticky-last-child" style={{maxHeight:windowWidth<2600? '60vh':'80vh'}}>
            <DeleteModel setOpen={setOpen} open={open} projectid={123} />
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Alarm Name</th>
                  <th scope="col">Description</th>
                  {AddAlarmListDetails?.alarmDetails
                    ? AddAlarmListDetails?.alarmDetails[0]?.languages?.map(
                      (item) => <th scope="col">{item.name}</th>
                    )
                    : ""}
                  <th className="add-alarm-del"></th>
                </tr>
              </thead>
              {!ALARMLOADER ? (
                <tbody>
                  {addAlarmArry
                    ? (addAlarmArry ?? []).map((item, index) => (
                      <tr>
                        <td>
                          <p>{item.alarm_number}</p>
                          {/* <p>{`${paginationIndex * 20 + index}`}</p>{" "} */}
                        </td>
                        <td>
                          <p
                            contenteditable={
                              AddAlarmListDetails?.alarmDetails[0]?.edit
                                ? "true"
                                : "false"
                            }
                            accessKey={index}

                            id="alarmName"
                            onClick={(e) => {
                              setAlarmName(item.alarm_name);
                              setAlarmId(item._id);
                            }}
                            onFocus={(e) => {
                              setAlarmName(item.alarm_name);
                              setAlarmId(item._id);
                            }}
                            onBlur={handleKeyPress}
                            onKeyPress={enterFocusOut}
                          >
                            {item.alarm_name}
                          </p>
                        </td>
                        <td style={{ maxWidth: "350px" }}>
                          <p
                            contenteditable={
                              AddAlarmListDetails?.alarmDetails[0]?.edit
                                ? "true"
                                : "false"
                            }
                            id="description"
                            accessKey={index}
                            onFocus={(e) => {
                              setDescription(item.description);
                              setAlarmId(item._id);
                            }}
                            onBlur={handleKeyPress}
                            onKeyPress={enterFocusOut}
                          >
                            {item.description}
                          </p>
                        </td>
                        {/* filter array and match alarm details with alarm language */}
                        {AddAlarmListDetails.alarmDetails[0].languages.map(
                          (items, index) => (
                            (found = item.languages.find((element, index) =>
                              element.id === items._id ? element : false
                            )),
                            found ? (
                              <td key={found?.id}>
                                <p
                                  contenteditable={
                                    AddAlarmListDetails?.alarmDetails[0]?.edit
                                      ? "true"
                                      : "false"
                                  }
                                  id="language"
                                  slot={found.alarm_id}
                                  accessKey={found?.id}
                                  onBlur={handleKeyPress}
                                  onKeyPress={enterFocusOut}
                                  onKeyUp={handleUpdate}
                                >
                                  {found?.details}
                                </p>
                              </td>
                            ) : (
                              ""
                            )
                          )
                        )}

                        {(AddAlarmListDetails?.alarmDetails[0]?.edit &&
                          item.showIcon) ||
                          (AddAlarmListDetails?.alarmDetails[0]?.edit &&
                            item.alarm_name) ||
                          (AddAlarmListDetails?.alarmDetails[0]?.edit &&
                            item.description) ? (
                          <td className="add-alarm-del">
                            {" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/icn_trash.svg"
                              }
                              onClick={(e) =>
                                dispatch(
                                  getDeleteAddAlarm(
                                    AddAlarmListDetails?.alarmDetails[0]._id,
                                    item._id
                                  )
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td className="add-alarm-del"></td>
                        )}
                      </tr>
                    ))
                    : ""}

                  {AddAlarmListDetails?.alarmDetails[0]?.edit ? (
                    <tr>
                      <td>
                        <input
                          type="number"
                          id="additonalIndex"
                          className={`form-control ${addIndexError}`}
                          placeholder="Index"
                          value={additonalIndex}
                          onChange={(e) => {
                            setAdditonalIndex(e.target.value);
                          }}
                          onBlur={() =>
                            additonalIndex !== null &&
                              additonalIndex !== "" &&
                              additonalIndex >= 0 &&
                              additonalIndex -
                              AddAlarmListDetails?.alarmDetails[0]
                                ?.highest_alarm_number <=
                              10000 &&
                              additonalIndex <= 65536
                              ? setAddIndexError("")
                              : setAddIndexError("error")
                          }
                          onKeyUp={handleAddAlarm}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id="addalarmName"
                          className={`form-control ${alarmNameError}`}
                          placeholder="Enter Alarm Name"
                          // value={addAlarmName}
                          onChange={(e) => {
                            setAddAlarmName(e.target.value);
                            setAlarmNAmeError(
                              addAlarmName === "" ? "error" : ""
                            );
                          }}
                          onKeyUp={handleAddAlarm}
                          onBlur={(e) =>
                            setAlarmNAmeError(
                              addAlarmName === "" ? "error" : ""
                            )
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          id="adddescription"
                          className={`form-control ${alarmDescritptionError}`}
                          placeholder="Enter Description"
                          // value={addDescription}
                          onChange={(e) => {
                            setAddDescription(e.target.value);
                            // setAlarmDescritptionError(
                            //   addDescription === "" ? "error" : ""
                            // );
                          }}
                          onKeyUp={handleAddAlarm}
                          onBlur={(e) =>
                            setAlarmDescritptionError(
                              addDescription === "" ? "error" : ""
                            )
                          }
                        />
                      </td>
                      {AddAlarmListDetails
                        ? AddAlarmListDetails.alarmDetails[0].languages.map(
                          (item, index) => (
                            <td>
                              <input
                                id={`addlanguage${index}`}
                                accessKey="addlanguage"
                                type="text"
                                className="form-control"
                                placeholder={"Enter " + item.name}
                                onChange={(e, i) => {
                                  setAddLanguageDetail(e.target.value);
                                  setAddLangugaeId(item._id);
                                }}
                                onKeyUp={handleAddAlarm}
                                onBlur={(e) => {
                                  setAddLanguageDetails([
                                    ...addLanguageDetails,
                                    e.target.value,
                                  ]);
                                  setAddLanguageIds([
                                    ...addLanguageIds,
                                    item._id,
                                  ]);
                                }}
                              />
                            </td>
                          )
                        )
                        : ""}

                      <td className="add-alarm-del"></td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              ) : (
                <tbody
                  style={{
                    cursor: "not-allowed",
                  }}
                >
                  {addAlarmArry
                    ? (addAlarmArry ?? []).map((item, index) => (
                      <tr>
                        <td>
                          <p>{item.alarm_number}</p>

                        </td>
                        <td>
                          <p

                            accessKey={index}
                            id="alarmName"
                            onClick={(e) => {
                              setAlarmName(item.alarm_name);
                              setAlarmId(item._id);
                            }}
                            onFocus={(e) => {
                              setAlarmName(item.alarm_name);
                              setAlarmId(item._id);
                            }}
                            onBlur={handleKeyPress}
                          >
                            {item.alarm_name}
                          </p>
                        </td>
                        <td style={{ maxWidth: "350px" }}>
                          <p
                            contenteditable={
                              AddAlarmListDetails?.alarmDetails[0]?.edit
                                ? "true"
                                : "false"
                            }
                            id="description"
                            accessKey={index}
                            onFocus={(e) => {
                              setDescription(item.description);
                              setAlarmId(item._id);
                            }}
                            onBlur={handleKeyPress}
                          >
                            {item.description}
                          </p>
                        </td>

                        {AddAlarmListDetails.alarmDetails[0].languages.map(
                          (items, index) => (
                            (found = item.languages.find((element, index) =>
                              element.id === items._id ? element : false
                            )),
                            found ? (
                              <td key={found?.id}>
                                <p
                                  contenteditable={
                                    AddAlarmListDetails?.alarmDetails[0]?.edit
                                      ? "true"
                                      : "false"
                                  }
                                  id="language"
                                  slot={found.alarm_id}
                                  accessKey={found?.id}
                                  onBlur={handleKeyPress}
                                  onKeyUp={handleUpdate}
                                >
                                  {found?.details}
                                </p>
                              </td>
                            ) : (
                              ""
                            )
                          )
                        )}

                        {AddAlarmListDetails?.alarmDetails[0]?.edit &&
                          item.alarm_name !== "" ? (
                          <td className="add-alarm-del">
                            {" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/icn_trash.svg"
                              }
                              onClick={(e) =>
                                dispatch(
                                  getDeleteAddAlarm(
                                    AddAlarmListDetails?.alarmDetails[0]._id,
                                    item._id
                                  )
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td className="add-alarm-del"></td>
                        )}
                      </tr>
                    ))
                    : ""}

                  {AddAlarmListDetails?.alarmDetails[0]?.edit ? (
                    <tr>
                      <td>
                        <input
                          type="number"
                          id="additonalIndex"
                          className={`form-control ${addIndexError}`}
                          placeholder="Index"
                          value={additonalIndex}
                          onChange={(e) => {
                            setAdditonalIndex(e.target.value);
                          }}
                          onBlur={() =>
                            additonalIndex !== null &&
                              additonalIndex !== "" &&
                              additonalIndex >= 0 &&
                              additonalIndex -
                              AddAlarmListDetails?.alarmDetails[0]
                                ?.highest_alarm_number <=
                              10000 &&
                              additonalIndex <= 65536
                              ? setAddIndexError("")
                              : setAddIndexError("error")
                          }
                          onKeyUp={handleAddAlarm}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id="addalarmName"
                          className={`form-control ${alarmNameError}`}
                          placeholder="Enter Alarm Name"
                          // value={addAlarmName}
                          onChange={(e) => {
                            setAddAlarmName(e.target.value);
                            setAlarmNAmeError(
                              addAlarmName === "" ? "error" : ""
                            );
                          }}
                          onKeyUp={handleAddAlarm}
                          onBlur={(e) =>
                            setAlarmNAmeError(
                              addAlarmName === "" ? "error" : ""
                            )
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          id="adddescription"
                          className={`form-control ${alarmDescritptionError}`}
                          placeholder="Enter Description"
                          // value={addDescription}
                          onChange={(e) => {
                            setAddDescription(e.target.value);
                            // setAlarmDescritptionError(
                            //   addDescription === "" ? "error" : ""
                            // );
                          }}
                          onKeyUp={handleAddAlarm}
                          onBlur={(e) =>
                            setAlarmDescritptionError(
                              addDescription === "" ? "error" : ""
                            )
                          }
                        />
                      </td>
                      {AddAlarmListDetails
                        ? AddAlarmListDetails.alarmDetails[0].languages.map(
                          (item, index) => (
                            <td>
                              <input
                                id={`addlanguage${index}`}
                                accessKey="addlanguage"
                                type="text"
                                className="form-control"
                                placeholder={"Enter " + item.name}
                                onChange={(e, i) => {
                                  setAddLanguageDetail(e.target.value);
                                  setAddLangugaeId(item._id);
                                }}
                                onKeyUp={handleAddAlarm}
                                onBlur={(e) => {
                                  setAddLanguageDetails([
                                    ...addLanguageDetails,
                                    e.target.value,
                                  ]);
                                  setAddLanguageIds([
                                    ...addLanguageIds,
                                    item._id,
                                  ]);
                                }}
                              />
                            </td>
                          )
                        )
                        : ""}

                      <td className="add-alarm-del"></td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <AlarmLoaderComponent />
                </tbody>
              )}
            </table>
          </div>

          <ul className="table-pagination">
            <li>
              {/* first page */}
              <button onClick={(e) => handlePagination(id, 0)}>
                <i className="bi bi-chevron-double-left"></i>
              </button>
            </li>
            <li>
              {/* Prev page */}
              <button
                onClick={(e) =>
                  pageNumber !== 0 ? handlePagination(id, pageNumber - 1) : null
                }
              >
                <i className="bi bi-caret-left-fill"></i>
              </button>
            </li>

            {pageNumber > 11 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 12)}>
                  {((pageNumber - 11) - 1) * rowPerpage}-{((pageNumber - 11) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 10 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 11)}>
                  {((pageNumber - 10) - 1) * rowPerpage}-{((pageNumber - 10) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 9 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 10)}>
                  {((pageNumber - 9) - 1) * rowPerpage}-{((pageNumber - 9) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 8 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 9)}>
                  {((pageNumber - 8) - 1) * rowPerpage}-{((pageNumber - 8) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 7 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 8)}>
                  {((pageNumber - 7) - 1) * rowPerpage}-{((pageNumber - 7) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 6 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 7)}>
                  {((pageNumber - 6) - 1) * rowPerpage}-{((pageNumber - 6) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 5 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 6)}>
                  {((pageNumber - 5) - 1) * rowPerpage}-{((pageNumber - 5) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 4 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 5)}>
                  {((pageNumber - 4) - 1) * rowPerpage}-{((pageNumber - 4) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 3 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 4)}>
                  {((pageNumber - 3) - 1) * rowPerpage}-{((pageNumber - 3) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 2 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 3)}>
                  {((pageNumber - 2) - 1) * rowPerpage}-{((pageNumber - 2) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 1 ? (
              <li>
                {/* current page-2 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 2)}>
                  {((pageNumber - 1) - 1) * rowPerpage}-{((pageNumber - 1) * rowPerpage) - 1}
                  {/* {pageNumber - 1} */}
                </button>
              </li>
            ) : null}
            {pageNumber > 0 ? (
              <li>
                {/* current page -1 */}
                <button onClick={(e) => handlePagination(id, pageNumber - 1)}>
                  {(pageNumber - 1) * rowPerpage}-{(pageNumber * rowPerpage) - 1}
                  {/* {pageNumber} */}
                </button>
              </li>
            ) : null}
            {
              <li className={"active"}>
                {/* current page */}
                <button onClick={(e) => handlePagination(id, pageNumber)}>
                  {pageNumber * rowPerpage} -{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms > rowPerpage ? (pageNumber * rowPerpage) + AddAlarmListDetails?.alarmDetails[0]?.alarms?.length - 1 : AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms - 1 : 0}
                  {/* {pageNumber + 1} */}
                </button>
              </li>
            }
            {(pageNumber + 1) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 1)}>
                {(pageNumber + 2-1)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+2)*rowPerpage)-1?((Number(pageNumber)+2)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
               
                  {/* {pageNumber + 2} */}
                </button>
              </li>
            ) : null}
            {(pageNumber + 2) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 2)}>
                {(pageNumber + 3-1)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+3)*rowPerpage)-1?((Number(pageNumber)+3)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
{(pageNumber + 3) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 3)}>
                {(pageNumber + 3)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+4)*rowPerpage)-1?((Number(pageNumber)+4)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
           {(pageNumber + 4) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 4)}>
                {(pageNumber + 4)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+5)*rowPerpage)-1?((Number(pageNumber)+5)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 5) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 5)}>
                {(pageNumber + 5)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+6)*rowPerpage)-1?((Number(pageNumber)+6)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 6) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 6)}>
                {(pageNumber + 6)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+7)*rowPerpage)-1?((Number(pageNumber)+7)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 7) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 7)}>
                {(pageNumber + 7)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+8)*rowPerpage)-1?((Number(pageNumber)+8)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 8) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 8)}>
                {(pageNumber + 8)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+9)*rowPerpage)-1?((Number(pageNumber)+9)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 9) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 9)}>
                {(pageNumber + 9)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+10)*rowPerpage)-1?((Number(pageNumber)+10)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 10) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 10)}>
                {(pageNumber + 10)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+11)*rowPerpage)-1?((Number(pageNumber)+11)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 11) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 11)}>
                {(pageNumber + 11)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+12)*rowPerpage)-1?((Number(pageNumber)+12)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
             {(pageNumber + 12) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button onClick={(e) => handlePagination(id, pageNumber + 12)}>
                {(pageNumber + 12)*rowPerpage}-{AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms>((Number(pageNumber)+13)*rowPerpage)-1?((Number(pageNumber)+13)*rowPerpage)-1:AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms-1}
                  {/* {pageNumber + 3} */}
                </button>
              </li>
            ) : null}
            {(pageNumber + 13) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button>...</button>
              </li>
            ) : null}
            {(pageNumber + 13) * rowPerpage <
              AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms ? (
              <li>
                <button
                  onClick={(e) =>
                    (pageNumber + 1) * rowPerpage <
                      AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms
                      ? handlePagination(
                        id,
                        Math.ceil(
                          AddAlarmListDetails?.alarmDetails[0]
                            ?.numberOfAlarms / rowPerpage
                        ) - 1
                      )
                      : ""
                  }
                >
                  {(Math.ceil(AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms / rowPerpage) - 1) * rowPerpage}- {AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms - 1}
                  {/* {Math.ceil(
                    AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms / rowPerpage
                  )} */}
                </button>
              </li>
            ) : null}
            <li>
              <button
                onClick={
                  (e) =>
                    (pageNumber + 1) * rowPerpage <
                      AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms
                      ? handlePagination(id, pageNumber + 1)
                      : null

                  // ProjectDetails[0]?.total
                }
              >
                <i className="bi bi-caret-right-fill"></i>
              </button>
            </li>
            <li>
              <button
                onClick={(e) =>
                  handlePagination(
                    id,
                    Math.ceil(
                      AddAlarmListDetails?.alarmDetails[0]?.numberOfAlarms / rowPerpage
                    ) - 1
                  )
                }
              >
                <i className="bi bi-chevron-double-right"></i>
              </button>
            </li>

          </ul>
          <ul className="table-pagination">
            <li>
              <label style={{ color: '#2f4c41b3', fontSize: '14px', fontWeight: '600' }}>Limit</label>
              <select onChange={handleRowPerpage} value={rowPerpage}
                style={{ color: '#1D946B', fontSize: '15px', fontWeight: '600', width: 'auto', marginLeft: '3px' }}>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
              </select>

              <label style={{ color: '#2f4c41b3', fontSize: '14px', fontWeight: '600', marginLeft: '15px'}}>Number</label>
             
              

              <select onChange={(e)=>handlePagination(id, e.target.value)} 
              style={{color:'#1D946B',fontSize:'15px', fontWeight:'600',width:'auto',marginLeft:'3px'}} value={pageNumber}>
                 {!allPage?.length? <option selected disabled>0-0</option>:null}
             {allPage?.map((item,index)=><option value={item?.pageNumber}>{item?.pageName}</option>)} 
              
              </select>
            </li>
          </ul>
        </div>

        {/* projects */}
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div className="alarm-tab-section-title">
            <h4>
              {AddAlarmListDetails
                ? AddAlarmListDetails?.alarmDetails[0]?.a_alarm_name
                : "Additional Alarm List"}
            </h4>
          </div>
          <div className="main-inner-card alarm-tab-section">
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Project Name</th>
                </tr>
              </thead>
              <tbody>
                {AddAlarmListDetails?.project?.map((p, index) => (
                  <tr>
                    <td>{index}</td>
                    <td
                      onClick={() => (
                        dispatch(universalLoading(true)),
                        dispatch({
                          type: SET_PROJECTDETAILS_LOADING,
                          payload: true,
                        }),
                        history.push(`/home/projectlist/projectdetail/${p._id}`)
                      )}
                      style={{ color: "#198754", cursor: "pointer" }}
                    >
                      {p.project_name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdditionalAlarms;
