import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getDeleteProject } from "../../Redux/actions/Project";
//delete model for project
function DeleteModel({ open, setOpen, projectid }) {
  const [display, setDisplay] = useState("block"); //hide or show model state
  const dispatch = useDispatch();
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      
      aria-labelledby="exampleModalLabel"
      aria-hidden="false" 
      style={{ display: open, position: "absolute", top: "30%" }}
    >
      <div className="modal-dialog ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Delete Project</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpen("")}
            ></button>
          </div>
          <div className="modal-body">
            <p>Do you want to delete this project?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={() => setOpen("")}
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => dispatch(getDeleteProject(projectid))}
            >
              <b>Delete</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModel;
