import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createGoogleDoc, getDeleteProject } from "../../Redux/actions/Project";
import { ALARM_LOADER } from "../../Utility/ActionConstant";
//delete model for project
function AlarmDocModel({ alarmDocOpen, setAlarmDocOpen, projectid,alarmDocAlarmId,alarmDocLanguageId,alarmDocDocId,alarmServerSearch }) {
  const [display, setDisplay] = useState("block"); //hide or show model state
  const dispatch = useDispatch();
  const handleGoogleDocStatus=()=>{
    if(alarmDocDocId){

      window.open(`https://docs.google.com/document/d/${alarmDocDocId}`)
      setAlarmDocOpen('')
    }else{

      if (projectid && alarmDocAlarmId && alarmDocLanguageId) {
        dispatch({ type: ALARM_LOADER, payload: true });
       dispatch(createGoogleDoc(projectid,alarmDocAlarmId,alarmDocLanguageId,alarmServerSearch))
       setAlarmDocOpen('')
      }
    }
  }
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      
      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{ display: alarmDocOpen, position: "absolute", top: "30%" }}
    >
      <div className="modal-dialog ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Merge process ongoing !</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setAlarmDocOpen("")}
            ></button>
          </div>
          <div className="modal-body">
            <p>Merge process is going on, opening or creating doc can affect merged doc.</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={() => setAlarmDocOpen("")}
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleGoogleDocStatus}
            >
              <b>Continue</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlarmDocModel;