import React ,{useEffect}from "react";
import BreadCums from "../../../Components/Layout/BreadCums";
import Footer from "../../../Components/Layout/Footer";
import Header from "../../../Components/Layout/Header";
import SideBar from "../../../Components/Layout/SideBar";
import { UPDATE_TITLE } from "../../../Utility/ActionConstant";


import {useDispatch,useSelector} from 'react-redux';
import { getAboutUsPage } from "../../../Redux/actions/ContentManger";
const titleData ={title:'About Us'}

function AboutUs() {
  const { AboutUs } = useSelector(
    ({ ContentManager }) => ContentManager
  );
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    dispatch(getAboutUsPage())
   
  }, []);
  
  return (
    <div className="main-inner-card about-section">
      <div className="about-section-row">
        {AboutUs? AboutUs.map((item,index)=>
        <p className="about-section-text" key={index}>
         {item.content.split("\n")
                        .map((i, key) => {
                          return <div key={key}>{i}</div>;
                        })}
         
        </p>):''}
       
      </div>

    </div>


  );
}

export default AboutUs;
