import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import {mergeProcess} from '../Redux/actions/Export'
import {langMerge} from '../Redux/actions/Export'


//delete model for project
function MergeModel({ open, setOpen, projectId,langId }) {
    const { exportDetails, langMarge} = useSelector(
        ({ Export }) => Export
      );

  const [display, setDisplay] = useState("block"); //hide or show model state
  const dispatch = useDispatch();

  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{ display: open, position: "fixed", alignItems: "center", }}
    >
      <div className="modal-dialog " style={{ margin: "14.75rem auto" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Global merge process ongoing !</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpen("")}
            ></button>
          </div>
          <div className="modal-body">
            <p>Global merge process is going on, merging any language can affect global merge process.</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={() => setOpen('')}
            >
              <b>Cancel</b>
            </button>
            <button            
              type="button"
              className="btn btn-danger"
              onClick={() =>{dispatch(langMerge(projectId,langId));setOpen('')}}
            >
              <b>Merge</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MergeModel;
