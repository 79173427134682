import React from "react";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AlarmLoaderComponent from "../../../Components/Layout/AlarmLoaderComponent";
import ReactTooltip from "react-tooltip";
import { getProjectDetails } from "../../../Redux/actions/Project";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import ActivateUsedAlarmModel from "../../../Components/Layout/ActivateUsedAlarmModel";
import moment from "moment";


import {
  usableAlarmFileList,
  
} from "../../../Redux/actions/Project";


const styles = (theme) => ({
  overrideMe: {
    width: "100px",
    "word-break": "break-all",
    "overflow-wrap": "break-word",
    display: "block",
  },
});

export default function UsableAlarmsFileList({ projectId,setPagenumberAlarm,setPagenumberAddAlarm }) {
  const { id } = useParams();
  const { usableFileList, ProjectDetails } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );


  const { ALARMLOADER } = useSelector(({ common }) => common);
  const [open, setOpen] = useState("");
  const  [alarmFile,setAlarmFile] = useState('')
  // pagination for used alarm,
  let content ='';
  let result ='';
  const [page, setPage] = useState(Number(window.localStorage.getItem('u_p')) || 0);
  const [limit, setLimit] = useState(Number(window.localStorage.getItem('u_l')) || 20);
  const [windowWidth, setWindowWidth] = useState(window.screen.width)
let wLimit= Number(window.localStorage.getItem('u_l'))
let wPage=Number(window.localStorage.getItem('u_p'))
  const dispatch = useDispatch();


  const projectDetailsCallBack = () => {
    dispatch(usableAlarmFileList(ProjectDetails[0]._id));
  };

  useEffect(() => {
 dispatch(usableAlarmFileList(ProjectDetails[0]._id, page, limit));
  }, [ page,dispatch]);

useEffect(() => {
  setPage(page!=wPage?wPage:page)
  setLimit(limit!=wLimit?wLimit:limit)
}, [wPage])

useEffect(() => {
  if(!page){
    dispatch(usableAlarmFileList(ProjectDetails[0]._id, page, limit));}
}, [limit])

useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
const handleResize = () => {
  setWindowWidth(window.screen.width)
}
  return (
    <>
   
            
      <div  className="main-inner-card alarm-tab-section" style={{ maxHeight: windowWidth < 2400 ? '60vh' : '80vh' }}>
        
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th scope="col">Filename</th>
              <th scope="col" style={{ marginLeft:'40px'}}>Uploaded By</th>
              <th scope="col">Uploaded at Date</th>
              <th scope="col">Number of Alarms</th>
              <th scope="col">Information</th>
             {ProjectDetails[0]?.edit? <th scope="col"></th>:null}
            </tr>
          </thead>
          {!ALARMLOADER ? (
            <tbody>
              {!usableFileList?.usedAlarms?.length? 
               <tr>
                <td colSpan={5}>
               <p style={{ textAlign: "center", marginTop: "10px",minWidth:'400px' }}>
                 This project does not have any Used alarm file.
               </p>
               </td>
             </tr>:null  
            }
              { usableFileList?.usedAlarms?.map((item, index) => (
              
                <tr key={index}>
                  <td style={{maxWidth:'10px',minWidth:'10px'}}></td>
                  <td  style={{wordBreak:'break-word', maxWidth:'150px', verticalAlign: 'top',paddingTop:'25px'}}>
                    {item.file_name?
                   <> {item.file_name}</>:
                    <span><b>-</b></span>}</td>

                  <td
                    
                    style={{wordBreak:'break-word', maxWidth:'150px', verticalAlign: 'top',paddingTop:'25px'}}
                  >
                    {item.uploaded_by.file_name || item.uploaded_by.last_name?<>
                  <span data-tip={item?.uploaded_by?.email}
                    data-for="registerTip1">  {item.uploaded_by.first_name} {item.uploaded_by.last_name}</span>
                    <ReactTooltip
                      id="registerTip1"
                      place="top"
                       effect='float'
                      backgroundColor="white"
                      borderColor="#fff"
                      border="1px"
                      textColor="black"
                 
                    />
                    </>
                    :<span><b>-</b></span>}

                  </td>

                  <td
                    // style={{
                    //   fontWeight: "600",
                    //   color: "#2f4c41b3",
                    //   fontWidth: "14px",
                    //   fontSize: "13px",
                    // }}
                    style={{verticalAlign: 'top',paddingTop:'25px', width:'200pz'}}
                  >{item.uploaded_at? 
                     moment(item.uploaded_at * 1000).format( "DD MMM YYYY hh:mm A")
                    :<span><b>-</b></span>}
                  </td>

                  <td style={{verticalAlign: 'top'}}>
                    {/* {item?.selectedAlarm?.map((e, index) =>
                                            <span>{index === 13 ? <br /> : `${e},`}</span>
                                        )} */}
                    {item.selectedAlarm.length !==0 ?
                    <div
                      class="accodion accordion-flush"
                      id={"accordionFlushExample2" + index}
                      elevation={0}
                      style={{ width: windowWidth < 2400 ?250:450,wordWrap:'break-word' ,marginLeft:'-10px',marginRight:'15px'}}
                    >
                      <div class="accordion-item">
                        <h2 id={"flush-headingOne1" +index}>
                          <button
                            class="accordion-button collapsed"
                            style={{
                              fontWeight: "600",
                              color: "#2f4c41b3",
                              fontWidth: "10px",
                              fontSize: "14px",
                              width: "250",
                              border: "none",
                              boxShadow: "none",
                             
                             
                            }}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-collapseOne"+index}
                            aria-expanded="false"
                          >
                            {/* {
                              const uniqueNames = names.filter((val, id, array) => {
                                return array.indexOf(val) == id;  
                             });
                            } */}
                            <div
                            style={{
                              textOverflow: 'ellipsis',
                              maxWidth: '350px',
                              display: 'block',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}>
                            {item?.selectedAlarm?.map((e, index) => (
                            
                              <span>{e}{item.selectedAlarm.length > index+1 && e != null?',':null}</span>
                            ))}</div>
                          </button>
                        </h2>
                        <div
                          id={"flush-collapseOne"+index}
                          class="accordion-collapse collapse"
                          aria-labelledby={"flush-headingOne1" + index}
                          data-bs-parent={"#accordionFlushExample2" + index}
                        >
                          <div class="accordion-body"
                          style={{whiteSpace:'normal'}}>
                            {item?.selectedAlarm?.map((e, index) => (
                             
                              <span >{e}{item.selectedAlarm.length > index+1 && e != null?','+' ':null}</span>
                             
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>:<span style={{marginLeft:'50px'}}><b>----</b></span>}
                  </td> 

                  <td style={{verticalAlign: 'top'}}>
                    {item.information?
                    <div
                      class="accodion accordion-flush"
                      id="accordionFlushExample1"
                      elevation={0}
                      style={{ width: windowWidth < 2400 ?300:450 ,wordWrap:'break-word',marginLeft:'-20px'}}
                    >
                      <div class="accordion-item" elevation={0}>
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            style={{
                              fontWeight: "600",
                              color: "#2f4c41b3",
                              fontWidth: "10px",
                              fontSize: "14px",
                              width: "250",
                              border: "none",
                              boxShadow: "none",
                            }}
                            class="accordion-button collapsed"
                            type="button"
                          
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseTwo" +index}
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <div style={{textOverflow: 'ellipsis',
                              maxWidth: '450px',
                              display: 'block',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',}}>
                            {item.information.split('\n')[0]}
                            </div>
                          </button>
                        </h2>
                        <div
                          id={"collapseTwo"+index}
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">{item.information.split('\n')?.map(e=> <p style={{margin:'4px'}}>{e}</p>)}</div>
                        </div>
                      </div>
                    </div>:<span style={{marginLeft:'40px'}}><b>----</b></span>}
                  </td>

                  {ProjectDetails[0]?.edit?<td style={{ verticalAlign: 'top'}}>
                  
                    <button
                    disabled={item.activated}
                      className={item.activated ? "disable_btn" : "export-btn"}
                      // onClick={() => activatebtn(item._id, item.project_id)}
                      onClick={(e) =>{setAlarmFile(item._id)
                        setOpen("block")
                      }}  
                >
                     {item.activated? 'Active': 'Activate'}  
                    </button> 
                    <ActivateUsedAlarmModel
                  setOpen={setOpen}
                  open={open}
                  item_id ={alarmFile}
                  item_project_id = {item.project_id}
                  setPagenumberAlarm={setPagenumberAlarm}
                  setPagenumberAddAlarm={setPagenumberAddAlarm}
                />
                  </td>:null}
                </tr>
              ))}
            </tbody>
          ) : (
            <AlarmLoaderComponent />
          )}
        </table>
      </div>

      <ul className="table-pagination">
        <li>
          {/* first page */}
          <button onClick={() => setPage(0)}>
            <i className="bi bi-chevron-double-left"></i>
          </button>
        </li>
        <li>
          {/* Prev page */}
          <button onClick={() => setPage(page >= 1 ? page - 1 : page)}>
            <i className="bi bi-caret-left-fill"></i>
          </button>
        </li>
        {page > 4 ? (
          <li  >
            <button onClick={() => setPage(page-5)}>{page-4}</button>
          </li>
        ) : null}
        {page > 3 ? (
          <li  >
            <button onClick={() => setPage(page-4)}>{page-3}</button>
          </li>
        ) : null}
        {page > 2 ? (
          <li  >
            <button onClick={() => setPage(page-3)}>{page-2}</button>
          </li>
        ) : null}
        {page > 1 ? (
          <li  >
            <button onClick={() => setPage(page-2)}>{page-1}</button>
          </li>
        ) : null}
        {page > 0 ? (
          <li  >
            <button onClick={() => setPage(page-1)}>{page}</button>
          </li>
        ) : null}
{/* current page */}
        <li className={"active"}>
          <button onClick={() => setPage(page)}>{page + 1}</button>
        </li>
        {Math.ceil(usableFileList?.total / limit) > page + 1 ? (
          <li>
            <button onClick={() => setPage(page + 1)}>{page + 2}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 2 ? (
          <li>
            <button onClick={() => setPage(page + 2)}>{page + 3}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 3 ? (
          <li>
            <button onClick={() => setPage(page + 3)}>{page + 4}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 4 ? (
          <li>
            <button onClick={() => setPage(page + 4)}>{page + 5}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 5 ? (
          <li>
            <button onClick={() => setPage(page + 5)}>{page + 6}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 6 ? (
          <li>
            <button onClick={() => setPage(page + 6)}>{page + 7}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 7 ? (
          <li>
            <button onClick={() => setPage(page + 7)}>{page + 8}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 8 ? (
          <li>
            <button onClick={() => setPage(page + 8)}>{page + 9}</button>
          </li>
        ) : null}

        {Math.ceil(usableFileList?.total / limit) > page + 9 ? (
          <li>
            <button onClick={() => setPage(page + 9)}>{page + 10}</button>
          </li>
        ) : null}

        <li>
          <button
            onClick={() =>
              setPage(
                Math.ceil(usableFileList?.total / limit) > page + 1
                  ? page + 1
                  : page
              )
            }
          >
            <i className="bi bi-caret-right-fill"></i>
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              setPage(Math.ceil(usableFileList?.total / limit) - 1)
            }
          >
            <i className="bi bi-chevron-double-right"></i>
          </button>
        </li>
      </ul>
      <ul className="table-pagination">
        <li>
          <label
            style={{ color: "#2f4c41b3", fontSize: "14px", fontWeight: "600" }}
          >
            Limit
          </label>
          <select
            onChange={(e) => {setPage(0);setLimit(Number(e.target.value));}}
            value={limit}
            style={{
              color: "#1D946B",
              fontSize: "15px",
              fontWeight: "600",
              width: "auto",
              marginLeft: "3px",
            }}
          >
            
       
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </li>
      </ul>
    
    </>
  );
}
