import React, { useState, useEffect } from "react";
import BreadCums from "../../../Components/Layout/BreadCums";
import Footer from "../../../Components/Layout/Footer";
import Header from "../../../Components/Layout/Header";
import SideBar from "../../../Components/Layout/SideBar";
import { AuhMethods } from "../../../Utility/jwt/Authmethod";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../Utility/jwt/config";
import { UPDATE_TITLE } from "../../../Utility/ActionConstant";
import { history } from "../../../Redux/store/index";
import { clearSnackbar, fetchSuccess } from "../../../Redux/actions/Common";
const titleData = { title: "Contact Us" };
function ContactUs() {
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
  }, []);
  const letters = /^[A-Za-z]+$/;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const onSubmit = () => {
    if (description === "") {
      setDescriptionError("Description is required.");
    }
    if (descriptionError === "" && description !== "") {
      const fullname = `${authUser?.first_name} ${authUser?.last_name}`;
      const email = authUser?.email;
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .post("/users/contact-us", {
          name: fullname,
          email: email,
          query: description,
        })
        .then((data) => {
          if (data.status === 201) {
            setDescription("");
            dispatch(fetchSuccess("Query raised successfully."));

            setTimeout(function () {
              dispatch(clearSnackbar());
            }, 3000);
          } else {
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <div className="main-inner-card d-flex justify-content-center align-items-center">
      <div className="profile-box">
        <div className="form-row">
          <div className="row">
            <div className="col">
              <label for="Company-Name" className="col-sm-12 col-form-label ">
                {" "}
                Name{" "}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your name here"
                aria-label="Company-Name"
                value={`${authUser?.first_name} ${authUser?.last_name}`}
                // onChange={(e) => setName(e.target.value)}
                // onBlur={()=>name!==''&& name.match(letters)?setNameError(''):null}
              />
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {/* {nameError} */}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col">
              <label for="Company-Name" className="col-sm-12 col-form-label ">
                {" "}
                Email Address{" "}
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email Address"
                aria-label="Company-Name"
                value={authUser?.email}
                // onChange={(e) => setEmail(e.target.value)}
                // onBlur={()=>email!==''&& email.match(re)?setEmailError(''):null}
              />
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {/* {emailError} */}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col">
              <label for="Company-Name" className="col-sm-12 col-form-label ">
                {" "}
                Description{" "}
              </label>
              {/* <input type="text" className="form-control" placeholder="Your Comments here..." aria-label="Company-Name" /> */}
              <textarea
                className="form-control pt-2"
                rows="3"
                placeholder="Your Comments here..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() =>
                  description !== "" ? setDescriptionError("") : null
                }
                // onKeyUp={(e)=>e.key==='Enter'?onSubmit():null}
              ></textarea>
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {descriptionError}
              </div>
            </div>
          </div>
        </div>
        <div className="profile-btn">
          <button className="cancel-btn" onClick={(e) => history.push("/home")}>
            {" "}
            Cancel{" "}
          </button>
          <button className="primary-btn" onClick={onSubmit}>
            {" "}
            Send{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
