import { clearSnackbar, fetchError, fetchSuccess } from "./Common";
import axios from "../../Utility/jwt/config";
import {
  ALARM_LOADER,
  GET_ADDALARMLISTDETAILS,
  SET_ADDITIONALPAGELIST,
} from "../../Utility/ActionConstant";
import { history } from "../../Redux/store/index";
import { getAllAddionalList } from "./Project";

// get additional alarm list details
export const getAddAlarmListDetails = (alarm_id, pagenumber,rowPerPage, search) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(
        `alarm/additional-alarm-details/${alarm_id}/${pagenumber}/${rowPerPage}/${search}`
      )
      .then((data) => {
        if (data.status === 200) {
          const allAddpageArray= Math.ceil(data.data.data?.alarmDetails[0]?.numberOfAlarms / rowPerPage)
            const addpagenumberFilterArray =[]
            
            for (let index = 0; index <Number(allAddpageArray); index++) {
              
              addpagenumberFilterArray.push({pageName:`${index*rowPerPage}-${data.data.data?.alarmDetails[0]?.numberOfAlarms>rowPerPage  && index!==Number(allAddpageArray)-1?((index+1)*rowPerPage)-1:data.data.data?.alarmDetails[0]?.numberOfAlarms?data.data.data?.alarmDetails[0]?.numberOfAlarms-1:0}`, pageNumber:index  })
            }
            console.log('data',addpagenumberFilterArray) 
            dispatch({type:SET_ADDITIONALPAGELIST,payload:addpagenumberFilterArray})
          dispatch({ type: ALARM_LOADER, payload: false });
          dispatch({ type: GET_ADDALARMLISTDETAILS, payload: data.data.data });

          history.push(`/home/additonalalarms/${alarm_id}`);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {});
  };
};

// edit details of additional alarm in additional alarm list
export const getEditAdditionalAlarm = (
  alarmName,
  description,
  languageIds,
  languageDetails,
  additional_alarm_id,
  alarmId,
  search
) => {
  return (dispatch) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(
        `/alarm/edit-alarm-in-additional-alarm/${additional_alarm_id}/${alarmId}`,
        {
          alarm_name: alarmName,
          description: description,
          language_ids: languageIds,
          language_details: languageDetails,
        }
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Additional alarm updated successfully."));
          const pageLocal = Number(
            window.localStorage.getItem("addpageNumber")
          );
          const rowPerPageLocal = Number(
            window.localStorage.getItem("rowPerPage")
          );
          if (search) {
            dispatch(getAddAlarmListDetails(additional_alarm_id, 0, search));
          } else {
            dispatch(
              getAddAlarmListDetails(additional_alarm_id, pageLocal, rowPerPageLocal)
            );
          }

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch({ type: ALARM_LOADER, payload: false });
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        // window.localStorage.removeItem("token");
        // window.localStorage.removeItem("refreshtoken");
        // window.location.reload();
        console.log("error", err);
      });
  };
};

// add additional alarm in additional alarmlist
export const getAddAdditionalAlarm = (
  alarm_number,
  alarmName,
  description,
  languageIds,
  languageDetails,
  additional_alarm_id
) => {
  const languageIDRev = languageIds.reverse();
  const languageDetailRev = languageDetails.reverse();
  const newLangid = [];
  const newDetailid = [];
  let found = null;
  languageIDRev.forEach((languageId, index) => {
    found = newLangid.some((id) => id === languageId);
    if (!found) {
      newLangid.push(languageId);
      newDetailid.push(languageDetailRev[index]);
    }
  });

  return (dispatch) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(
        `/alarm/add-alarm-in-additional-alarm/${additional_alarm_id}/${alarm_number}`,
        {
          alarm_name: alarmName,
          description: description,
          language_ids: newLangid,
          language_details: newDetailid,
        }
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(
              "New Additional alarm added successfully at the end of this list."
            )
          );
          const rowPerPageLocal = Number(
            window.localStorage.getItem("rowPerPage")
          );
          const pageLocal = Number(
            window.localStorage.getItem("addpageNumber")
          );

          dispatch(getAddAlarmListDetails(additional_alarm_id, pageLocal,rowPerPageLocal));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch({ type: ALARM_LOADER, payload: false });
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        console.log("error", err);
      });
  };
};

// delete additional alarm in adiitional alarmlist
export const getDeleteAddAlarm = (additional_alarm_id, alarmId) => {
  return (dispatch) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .delete(
        `/alarm/delete-alarm-in-additional-alarm/${additional_alarm_id}/${alarmId}`
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Additional alarm deleted successfully."));
          const pageLocal = Number(
            window.localStorage.getItem("addpageNumber")
          );
          const rowPerPageLocal = Number(
            window.localStorage.getItem("rowPerPage")
          );
          dispatch(getAddAlarmListDetails(additional_alarm_id, pageLocal,rowPerPageLocal));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch({ type: ALARM_LOADER, payload: false });
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};
//edit name of additional alarmlist
export const getEditAddAlarmName = (
  additional_alarm_id,
  additionalAlarmName
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(`/alarm/edit-additional-alarm-name/${additional_alarm_id}`, {
        alarm_name: additionalAlarmName,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("Additional alarm list name updated successfully.")
          );
          const rowPerPageLocal = Number(
            window.localStorage.getItem("rowPerPage")
          );
          dispatch(getAddAlarmListDetails(additional_alarm_id));
          dispatch(getAllAddionalList(""));

          dispatch(getAddAlarmListDetails(additional_alarm_id));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};
//delete additional alarmlist
export const getDeleteAdditionalList = (projectId) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .delete(`/alarm/delete-additional-alarm/${projectId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Additional Alarmlist deleted successfully."));

          dispatch(getAllAddionalList(""));
          history.push("/home");

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 2000);
          dispatch(getAllAddionalList(""));
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
        } else {
          //
          dispatch(fetchError(err?.response?.data?.message));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        }
      });
  };
};
//add new additional alarmlist
export const getAddAddAlarmName = (
  additional_alarm_id,
  additionalAlarmName
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(`/alarm/create-additional-alarm-list/${additional_alarm_id}`, {
        alarm_name: additionalAlarmName,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess("Additional alarm list name created successfully.")
          );

          dispatch(getAllAddionalList(""));
          dispatch(getAddAlarmListDetails(data.data.data._id));

          console.log("projectlist", data);

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};
