import { UPDATE_TITLE } from '../../Utility/ActionConstant';

const INIT_STATE = {
  title: null,
 
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_TITLE: {
      return {
        ...state,
        title: action.payload,
        loadUser: true,
      };
    }
   
    default:
      return state;
  }
};
