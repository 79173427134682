import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AlreadyHaveAccount,
  createAccount,
  EmailId,
  FirstName,
  LastName,
  Password,
  PlcAlarm,
  SignUP,
  signupDescription,
  TermsandCondition,
} from "../../../Utility/Constant";
//redux
import { AuhMethods } from "../../../Utility/jwt/Authmethod";
import { useDispatch } from "react-redux";
import { history } from "../../../Redux/store";
import Loading from "../../../Components/Layout/Loading";
import AlarmLoaderComponent from "../../../Components/Layout/AlarmLoaderComponent";
import { useSelector } from "react-redux";

function SignUpPage() {
  const { universalloading, ALARMLOADER } = useSelector(({ common }) => common);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [termCheck, setTermCheck] = useState(0);
  const [termError, setTermError] = useState("none");
  const [disbaleButton, setDisbaleButton] = useState(false);
  //need to change letter
  const [fe, setFe] = useState(false);
  const [le, setLe] = useState(false);
  const [ee, setEe] = useState(false);
  const [pe, setPe] = useState(false);
  const [ce, setCe] = useState(false);
  const [te, setTe] = useState(false);

  const dispatch = useDispatch();
  //validate and excute signup api
  const onSubmit = () => {
    const letters =
      /^(?=(?:[^A-Za-z]*[A-Za-z]){2})(?![^\d~`?!^*¨ˆ;@=$%{}\[\]|\\\/<>#“.,]*[\d~`?!^*¨ˆ;@=$%{}\[\]|\\\/<>#“.,])\S+(?: \S+){0,2}$/;
    const re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    const passw =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$.!%*#?&])[A-Za-z\d@$.!%*#?&]{6,}$/;

    if (firstName === "") {
      setFirstNameError("First name is required.");
    } else if (!firstName.match(letters)) {
      setFirstNameError("First name should contain only letters.");
    } else {
      setFirstNameError("");
      setFe(true);
    }
    if (lastName === "") {
      setLastNameError("Last name is required.");
    } else if (!lastName.match(letters)) {
      setLastNameError("Last name should contain only letters.");
    } else {
      setLastNameError("");
      setLe(true);
    }
    if (email === "") {
      setEmailError("Email is required.");
    } else if (!email.match(re)) {
      setEmailError("Enter valid Email.");
    } else {
      setEmailError("");
      setEe(true);
    }
    if (password === "") {
      setPasswordError("Password is required.");
    } else if (!password.match(passw)) {
      setPasswordError(
        "Password should be combination of one uppercase, one lower case, one special character (@$.!%*#?&), one digit and minimum 6."
      );
    } else {
      setPasswordError("");
      setPe(true);
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required.");
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Password and Confirm password does not match.");
    } else {
      setConfirmPasswordError("");
      setCe(true);
    }
    if (!termCheck) {
      setTermError("block");
    } else {
      setTermError("none");
      setTe(true);
    }
    if (
      firstName !== "" &&
      firstName.match(letters) &&
      lastName !== "" &&
      lastName.match(letters) &&
      email !== "" &&
      email.match(re) &&
      password !== "" &&
      password.match(passw) &&
      confirmPassword === password &&
      termCheck
    ) {
      setDisbaleButton(true);

      dispatch(
        AuhMethods["jwtAuth"].onRegister({
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          termCheck,
        })
      );

      setTimeout(() => {
        setDisbaleButton(false);
      }, 2000);
    }
  };

  if (!ALARMLOADER) {
    return <Loading />;
  } else {
    return (
      <section className="auth-main-section">
        <div className="auth-box-left">
          <img src={process.env.PUBLIC_URL + "/img/Login_graphic.svg"} />
        </div>
        <div className="auth-box-right">
          <div className="auth-box-right-inner">
            <div className="app-logo">
              {/* <img src={process.env.PUBLIC_URL + "/img/PLC-logo.svg"} /> */}
              <img src={process.env.PUBLIC_URL + "/img/PLC_Alarm.png"} />
            </div>
            <div className="auth-title">
              <h4>Create New Account</h4>
            </div>

            <div className="form-row">
              <div className="row ">
                <div className="col">
                  <label for="Your-Name" className="col-sm-12 col-form-label ">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your first name"
                    aria-label="Your-Name"
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {firstNameError}
                  </div>
                </div>

                <div className="col">
                  <label
                    for="Company-Name"
                    className="col-sm-12 col-form-label "
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your last name"
                    aria-label="Company-Name"
                    onChange={(event) => setLastName(event.target.value)}
                  />
                  
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {lastNameError}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="row">
                <div className="col">
                  <label
                    for="Company-Name"
                    className="col-sm-12 col-form-label "
                  >
                    Email Id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your email address here"
                    aria-label="Company-Name"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {emailError}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="row">
                <div className="col">
                  <label
                    for="Company-Name"
                    className="col-sm-12 col-form-label "
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter your password here"
                    aria-label="Company-Name"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {passwordError}
                  </div>
                </div>
              </div>
            </div>
            {/* confirm password added from react side check for design issue if any */}
            <div className="form-row">
              <div className="row">
                <div className="col">
                  <label
                    for="Company-Name"
                    className="col-sm-12 col-form-label "
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter your confirm password here"
                    aria-label="Company-Name"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {confirmPasswordError}
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row">
              <div className="col">
                <label className="cust_checkbox">
                  <p className="auth-link">
                    By Signing Up I agree to the{" "}
                    <a href="#/termsandcondition">Terms & Conditions</a>
                  </p>
                  <input
                    type="checkbox"
                    checked={termCheck}
                    onChange={(e) => setTermCheck(termCheck ? 0 : 1)}
                    // onBlur={onSubmit}
                  />
                  <span className="checkmark"></span>
                </label>
                <div
                  id="name"
                  className="invalid-feedback"
                  style={{ display: termError }}
                >
                  You need to accept Terms & Conditions to sign up.
                </div>
              </div>
            </div>

            <div className="auth-btn-grp">
              <button
                className="primary-btn"
                disabled={disbaleButton}
                onClick={onSubmit}
              >
                Sign Up
              </button>
            </div>

            <div className="form-row row">
              <p className="auth-link">
                Already have an account? <NavLink to="/login">LogIn</NavLink>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SignUpPage;
