import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CrazyTags from "../../../Components/Layout/CrazyTags";
import {
  getAddProject,
  getUpdateProject,
} from "../../../Redux/actions/Project";
import {
  GET_ALLLANGUAGES,
  SET_EDITPROJECTLANGUAGES,
  UPDATE_TITLE,
} from "../../../Utility/ActionConstant";
import { history } from "../../../Redux/store/index";
import Loading from "../../../Components/Layout/Loading";
import ProjectMergeStatusModel from "../../../Components/Layout/ProjectMergeStatusModel";
const titleData = { title: "Update Project Details" };
function EditProject() {
  
  const { ProjectDetailsLoading } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const { ProjectDetails } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const { AddAlarmlanguageList } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const { ALLProjectList, ALLLanguages, ALLAdditionalList } = useSelector( 
    ({ projectlistReducer }) => projectlistReducer
  );
  const {globalMergeStatus } = useSelector(
    ({ Export }) => Export
  );
  const[mergeStatusOpen,setMergeStatusOpen]=useState(globalMergeStatus?'block':'')
  const [projectName, setProjectName] = useState(
    ProjectDetails ? ProjectDetails[0].project_name : "Loading"
  );
  const [parentProjectName, setParentProjectName] = useState("");
  const [description, setDescription] = useState(
    ProjectDetails ? ProjectDetails[0].description : "Loading"
  );
  const [alarmList, setAlarmList] = useState([]);
  const [rsml, setRsml] = useState(
    ProjectDetails ? (ProjectDetails[0].r_s_m_from_ml ? true : false) : true
  );

  const [additionalListStatus, setAdditionalListStatus] = useState(true);
  const [additionalList, setAdditionalList] = useState(null);
  const [projectId, setProjectId] = useState(
    ProjectDetails ? ProjectDetails[0]._id : ""
  );
  const [buttonDisable, setButtonDisable] = useState(false);
  const dispatch = useDispatch();
  var ALLAdditionalListFiltered = ALLAdditionalList.filter(
    (item) =>
      item.a_alarm_name !== ProjectDetails[0]?.additional_alarm?.a_alarm_name
  );
  useEffect(() => {
    if (ProjectDetails) {
      dispatch({
        type: SET_EDITPROJECTLANGUAGES,
        payload: ProjectDetails[0].languages,
      });
    }
    dispatch({ type: UPDATE_TITLE, payload: titleData });
  }, []);

  const handleSave = () => {
    setAlarmList(AddAlarmlanguageList);
    setButtonDisable(true);

    //added null for rsml if no change
    //additional list change option added
    if (rsml === ProjectDetails[0].r_s_m_from_ml) {
      dispatch(
        getUpdateProject(
          projectName,
          description,
          AddAlarmlanguageList,
          null,
          projectId,
          additionalList
        )
      );
    } else {
      dispatch(
        getUpdateProject(
          projectName,
          description,
          AddAlarmlanguageList,
          rsml,
          projectId,
          additionalList
        )
      );
    }
  };
  if (ProjectDetails === null) {
    history.push("/home");
  }
  if (!ProjectDetailsLoading) {
    return (
      <div className="main-inner-card add-projects">
        <div className="form-row">
          <div className="row ">
            <div className="col">
              <label for="Your-Name" className="col-sm-12 col-form-label ">
                Project Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter project name here."
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <div id="name" className="invalid-feedback">
                Project name should contain only letters.
              </div>
            </div>

            <div className="col">
              <label className="col-sm-12 col-form-label ">
                Parent Project
              </label>
              <select
                className="form-select "
                onChange={(e) => setParentProjectName(Number(e.target.value))}
                disabled
                style={{ pointerEvents: "none" }}
              >
                <option disabled selected>
                  {ProjectDetails
                    ? ProjectDetails[0].parent_project.project_name === ""
                      ? "None"
                      : ProjectDetails[0].parent_project.project_name
                    : "Loading"}
                </option>
                {(ALLProjectList ?? []).map((item) => (
                  <option value={item.path_number}>{item.project_name}</option>
                ))}
              </select>

              <div id="name" className="invalid-feedback">
                Last name should contain only letters.
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="row ">
            <div className="col-lg-6">
              <label className="col-sm-12 col-form-label ">
                Alarm Languages
              </label>
              <div className="form-group">
                {/* <input type="text" className="form-control" name="planets" value="Earth,Mars" /> */}
                <CrazyTags />
              </div>
            </div>
            <div className="col-lg-6">
              <label className="col-sm-12 col-form-label ">
                Reload Standard Messages from Master List:
              </label>
              <div className="w-100 float-start">
                <label className="cust_radio">
                  Do it
                  <input
                    type="radio"
                    disabled={
                      ProjectDetails
                        ? ProjectDetails[0].parent_project.project_name === ""
                          ? true
                          : false
                        : ""
                    }
                    name="radio1"
                    checked={rsml}
                    onChange={(e) => setRsml(rsml ? false : true)}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cust_radio">
                  Don't Do it
                  <input
                    type="radio"
                    disabled={
                      ProjectDetails
                        ? ProjectDetails[0].parent_project.project_name === ""
                          ? true
                          : false
                        : ""
                    }
                    checked={rsml ? false : true}
                    name="radio1"
                    onChange={(e) => setRsml(rsml ? false : true)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-row">
          
          <div className="row ">
            <div className="col">
              <label className="col-sm-12 col-form-label ">Description</label>
              <div className="form-group">
                <textarea
                  className="form-control pt-3"
                  rows="3"
                  placeholder="Add Description"l
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col">                                                                                                                                                                                           
              <label className="col-sm-12 col-form-label ">
                Additional List
              </label>    
              <select 
                className="form-select "
                onChange={(e) => setAdditionalList(e.target.value)}
              >
                <option disabled selected>
                  {ProjectDetails
                    ? ProjectDetails[0].additional_alarm.a_alarm_name === ""
                      ? "None"
                      : ProjectDetails[0].additional_alarm.a_alarm_name
                    : "Loading"}
                </option>
                {(ALLAdditionalListFiltered ?? []).map((item) => (
                  <option value={item._id}>{item.a_alarm_name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="profile-btn">
          <button
            className="cancel-btn"
            onClick={(e) => {
              history.goBack();
            }}
          >
            {" "}
            Cancel{" "}
          </button>

          <button
            className="primary-btn"
            onClick={handleSave}
            disabled={buttonDisable}
          >
            {" "}
            Save{" "}
          </button>
        </div>
        <ProjectMergeStatusModel mergeStatusOpen={mergeStatusOpen} setMergeStatusOpen={setMergeStatusOpen}/>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default EditProject;
