import React, { useRef, useState } from "react";

import { NavLink } from "react-router-dom";
//redux
import { AuhMethods } from "../../../Utility/jwt/Authmethod";
import { useDispatch, useSelector } from "react-redux";
import { ONFORGOTCLICK } from "../../../Utility/ActionConstant";
function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const inputRefFor = useRef(null);
  const { forgotpassButton } = useSelector((auth) => auth);
  const dispatch = useDispatch();
  //validation and excute forget password api
  const onSubmit = () => {
    const valemail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
      setEmailError("Email is required.");
    } else if (!email.match(valemail)) {
      setEmailError("Enter valid email address.");
    } else {
      dispatch({
        type: ONFORGOTCLICK,
        payload: false,
      });
      setEmailError("");

      dispatch(AuhMethods["jwtAuth"].onForgotPassword({ email }));
      inputRefFor.current.value = "";
      setEmail("");
    }
  };
  return (
    <section className="auth-main-section">
      <div className="auth-box-left">
        <img src={process.env.PUBLIC_URL + "/img/Login_graphic.svg"} />
      </div>
      <div className="auth-box-right">
        <div className="auth-box-right-inner">
          <div className="app-logo">
            {/* <img src={process.env.PUBLIC_URL + "/img/PLC-logo.svg"} /> */}
            <img src={process.env.PUBLIC_URL + "/img/PLC_Alarm.png"} />
          </div>
          <div className="auth-title">
            <h4>Forgot Password?</h4>
            <p>
              Enter your registered Id below to get password reset instructions.
            </p>
          </div>

          <div className="form-row">
            <div className="row ">
              <div className="col">
                <label for="Your-Name" className="col-sm-12 col-form-label ">
                  Email Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  ref={inputRefFor}
                  placeholder="Enter your email address here"
                  aria-label="Your-Name"
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <div
                  id="name"
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  {emailError}
                </div>
              </div>
            </div>
          </div>

          <div className="auth-btn-grp">
            <button
              className="primary-btn"
              onClick={onSubmit}
              disabled={forgotpassButton ? true : false}
            >
              Submit
            </button>
          </div>
          <div className="form-row row">
            <p className="auth-link">
              Go back to <NavLink to="/login">Log In </NavLink>
            </p>
          </div>
        </div>
      </div>
    </section>
   
  );
}

export default ForgotPasswordPage;
