import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function ProjectMergeStatusModel({ mergeStatusOpen, setMergeStatusOpen }) {
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{
        display: mergeStatusOpen,
        position: "fixed",
        alignItems: "center",
      }}
    >
      <div className="modal-dialog " style={{ margin: "14.75rem auto" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Global merge process ongoing !</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setMergeStatusOpen("")}
            ></button>
          </div>
          <div className="modal-body">
            <p>
              Global merge process is going on, adding or removing any language
              can affect global merge process.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setMergeStatusOpen("")}
            >
              <b>Ok</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectMergeStatusModel;
