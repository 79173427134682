import React, { useState, useEffect, useRef } from "react";
import BreadCums from "../../../Components/Layout/BreadCums";
import Footer from "../../../Components/Layout/Footer";
import Header from "../../../Components/Layout/Header";
import SideBar from "../../../Components/Layout/SideBar";
import { AuhMethods } from "../../../Utility/jwt/Authmethod";
import { useDispatch } from "react-redux";
import { UPDATE_TITLE } from "../../../Utility/ActionConstant";
import { NavLink } from "react-router-dom";
import { history } from "../../../Redux/store/index";
const titleData = { title: "Change Password" };
function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const oldPassRef = useRef(null);
  const newPassRef = useRef(null);
  const confirmPassRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
  }, []);

  const passw =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$.!^%*#?&])[A-Za-z\d@$.!^%*#?&]{6,}$/;
  const onSubmit = () => {
    if (oldPassword === "") {
      setOldPasswordError("Old password is required.");
    }
    if (newPassword === "") {
      setNewPasswordError("New password is required.");
    } else if (!newPassword.match(passw)) {
      setNewPasswordError(
        "Password should be combination of one uppercase, one lower case, one special character (@^$.!%*#?&), one digit and minimum 6."
      );
    }else if(newPassword===oldPassword){
      setNewPasswordError('New password should not be same as old password.')
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required.");
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError(
        "New password and Confirm password does not match."
      );
    }else{
      setConfirmPasswordError('')
    }
    
    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      !newPassword.match(passw) === false &&
      newPassword === confirmPassword
      && oldPassword!==newPassword
    ) {
      setOldPasswordError("");
      setNewPasswordError("");
      setConfirmPasswordError("");
      dispatch(
        AuhMethods["jwtAuth"].onChangePassword({
          oldPassword,
          newPassword,
          confirmPassword,
        })
      );
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      oldPassRef.current.value = "";
      newPassRef.current.value = "";
      confirmPassRef.current.value = "";
    }

    // }
  };
  const handleCancle = () => {
    history.push("/home");
  };
  return (
    <div className="main-inner-card d-flex justify-content-center align-items-center">
      <div className="profile-box">
        <div className="form-row">
          <div className="row">
            <div className="col">
              <label for="Company-Name" className="col-sm-12 col-form-label ">
                {" "}
                Old Password{" "}
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Old Password"
                ref={oldPassRef}
                aria-label="Company-Name"
                onChange={(e) => setOldPassword(e.target.value)}
                onBlur={() =>
                  oldPassword !== "" ? setOldPasswordError("") : null
                }
              />
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {oldPasswordError}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col">
              <label for="Company-Name" className="col-sm-12 col-form-label ">
                {" "}
                New Password{" "}
              </label>
              <input
                type="password"
                className="form-control"
                ref={newPassRef}
                placeholder="Enter New Password"
                aria-label="Company-Name"
                onChange={(e) => setNewPassword(e.target.value)}
                onBlur={() =>
                  newPassword.match(passw) ? setNewPasswordError("") : null
                }
              />
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {newPasswordError}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col">
              <label for="Company-Name" className="col-sm-12 col-form-label ">
                {" "}
                Confirm Password{" "}
              </label>
              <input
                type="password"
                className="form-control"
                ref={confirmPassRef}
                placeholder="Enter Confirm Password"
                aria-label="Company-Name"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {confirmPasswordError}
              </div>
            </div>
          </div>
        </div>
        <div className="profile-btn">
          <button className="cancel-btn" onClick={handleCancle}>
            {" "}
            Cancel{" "}
          </button>

          <button className="primary-btn" onClick={onSubmit}>
            {" "}
            Save{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
