import { ALL_MERGE_STATUS, EXPORTDETAILS, EXPORTLOADING } from "../../Utility/ActionConstant";
import { LANGMERGE } from "../../Utility/ActionConstant";
import { LANGMERGESTATUS , GLOBALMERGESTATUS,MERGESTATUS, PROJECT_SETTING,PROJECT_BITLIST} from "../../Utility/ActionConstant";

const INIT_STATE = {
    exportDetails: null,
    langMarge:false,
    langMergeStatus:[],
    globalMergeStatus:null,
    mergeStatus:null,
    ExportLoading:false,
    AllMerge:false,
    settings:[],
    projectBitlist:[]
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXPORTDETAILS: {
      return {
        ...state,
        exportDetails: action.payload,
      };
    }
    case LANGMERGE: {
      return {
        ...state,
        langMarge: action.payload,
      };
    }
    case LANGMERGESTATUS: {
      return {
        ...state,
        langMergeStatus: action.payload,
      };
    }

    case GLOBALMERGESTATUS: {
      return {
        ...state,
        globalMergeStatus: action.payload,
      };
    }
    case MERGESTATUS: {
      return {
        ...state,
        mergeStatus: action.payload,
      };
    }
    case EXPORTLOADING: {
      return {
        ...state,
        ExportLoading: action.payload,
      };
    }
    case ALL_MERGE_STATUS: {
      return {
        ...state,
        AllMerge: action.payload,
      };
    }
    case PROJECT_SETTING: {
      return {
        ...state,
        settings: action.payload
      }
    }
    case PROJECT_BITLIST:{
      return{
        ...state,
        projectBitlist:action.payload
      }
    }
    default:
      return state;
  }
};
