import {
  ONFORGOTCLICK,
  ONPAGELOAD,
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
} from "../../Utility/ActionConstant";

const INIT_STATE = {
  authUser: null,
  loadUser: false,
  send_forget_password_email: false,
  pageloading: false,
  forgotpassButton:false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    case ONPAGELOAD: {
      return {
        ...state,
        pageloading: action.payload,
      };
    }
    case ONFORGOTCLICK:{
      return {
        ...state,
        forgotpassButton:action.payload,
      };
    }
    default:
      return state;
  }
};
