import React, { useState } from "react";
//string for wording
//router
import { NavLink } from "react-router-dom";

//redux
import { AuhMethods } from "../../../Utility/jwt/Authmethod";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../Components/Layout/Loading";
const remebermeEmail = window.localStorage.getItem("email");
const remebermePassword = window.localStorage.getItem("password");
const remebermeButton = window.localStorage.getItem("rememberme");
function Login() {
  const [email, setEmail] = useState(remebermeEmail ? remebermeEmail : "");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState(
    remebermePassword ? remebermePassword : ""
  );
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(remebermeButton ? true : false);
  const [loading, setLoading] = useState(true);
  const { authUser, pageloading } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  //excute login function on enter
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  //validate and excute login function
  const handleLogin = (event) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
      setEmailError("Email is required.");
      if (password === "") {
        setPasswordError("Password is required.");
      }
    } else if (!email.match(re)) {
      setEmailError("Enter valid email.");
    } else if (password === "") {
      setEmailError("");
      setPasswordError("Password is required.");
    } else {
      if (rememberMe) {
        window.localStorage.setItem("email", email);
        window.localStorage.setItem("password", password);
        window.localStorage.setItem("rememberme", rememberMe);
        
      } else {
        window.localStorage.setItem("email", "");
        window.localStorage.setItem("password", "");
        window.localStorage.setItem("rememberme", rememberMe);
      }
      setEmailError("");
      setPasswordError("");
      dispatch(AuhMethods["jwtAuth"].onLogin({ email, password }));
    }
  };
  
  if (pageloading) {
    return <Loading />;
  } else {
    return (
      <section className="auth-main-section">
        <div className="auth-box-left">
          <img src={process.env.PUBLIC_URL + "/img/Login_graphic.svg"} />
        </div>
        <div className="auth-box-right">
          <div className="auth-box-right-inner">
            <div className="app-logo">
              <img src={process.env.PUBLIC_URL + "/img/PLC_Alarm.png"} />
            </div>
            <div className="auth-title">
              <h4>Welcome Back!!</h4>
            </div>

            <div className="form-row">
              <div className="row ">
                <div className="col">
                  <label for="Your-Name" className="col-sm-12 col-form-label ">
                    Email Id
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email address here"
                    aria-label="Your-Name"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onKeyUp={handleKeyPress}
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {emailError}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="row">
                <div className="col">
                  <label
                    for="Company-Name"
                    className="col-sm-12 col-form-label "
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control is-invalid"
                    placeholder="Enter your password here"
                    aria-label="Company-Name"
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                    onKeyUp={handleKeyPress}
                  />
                  <div
                    id="name"
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {passwordError}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="row form-row">
                <div className="col">
                  <label className="cust_checkbox">
                    Remember Me
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(rememberMe ? false : true)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div className="col">
                  <NavLink to="/forgotpassword" className="forgot-link">
                    Forgot Password?
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="auth-btn-grp">
              <button className="primary-btn" onClick={handleLogin}>
                Login
              </button>
            </div>
            <div className="form-row row">
              <p className="auth-link">
                Don't have an account? <NavLink to="/signup">Sign Up</NavLink>
              </p>
            </div>
          </div>
        </div>
      </section>

     
    );
  }
}

export default Login;
