import React from "react";

function Footer() {
  return (
    <section className="footer-section">
      <div>
        <p>©{new Date().getFullYear()} - Made with heart by<a className='footerLink' href='https://PLC-Alarm.com' > PLC-Alarm.com</a></p>
      </div>
    </section>
  );
}

export default Footer;
