import React, { useEffect, useState } from "react";
import BreadCums from "../../../Components/Layout/BreadCums";
import Footer from "../../../Components/Layout/Footer";
import Header from "../../../Components/Layout/Header";
import SideBar from "../../../Components/Layout/SideBar";
import { UPDATE_TITLE } from "../../../Utility/ActionConstant";
import { useDispatch, useSelector } from "react-redux";
import { getFAQ } from "../../../Redux/actions/ContentManger";
const titleData = { title: "FAQ" };
function FAQPage() {
  const { FAQ } = useSelector(({ ContentManager }) => ContentManager);
  const [show, setShow] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    dispatch(getFAQ());
  }, []);

  return (
    <div className="main-inner-card faq-section">
      <div className="accordion accordion-flush" id="accordionExample">
        {(FAQ ?? []).map((item, index) => (
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapseOne${index}`}
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={() => setShow(index)}
              >
                {item.header}
              </button>
            </h2>
            <div
              id={`collapseOne${index}`}
              className="accordion-collapse collapse "
              aria-labelledby={`headingOne`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">{item.content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQPage;
