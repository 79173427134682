import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchStart,
  fetchSuccess,
  fetchError,
  clearSnackbar,
} from "../../Redux/actions/Common";
//snackbar notification

export default function SuccessSnackbar() {
  const { message, successSnackbarOpen, color } = useSelector(
    ({ common }) => common
  ); //get notification state from store
  const dispatch = useDispatch();
  const [show, setShow] = useState(
    successSnackbarOpen ? successSnackbarOpen : "hide"
  );

  useEffect(() => {
    setShow(successSnackbarOpen ? successSnackbarOpen : "hide");
  }, [show]);
  function handleClose() {
    dispatch(clearSnackbar());
  }


  if (message !== "") {
    return (
      <div
        className={`toast fade ${successSnackbarOpen}  w position-fixed top-30 end-0 p-3 mx-3 mt-2 toast align-items-center text-white bg-${color} border-0`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ zIndex: 9999 }}
      >
        <div className="d-flex">
          <div className="toast-body">
            <b>{message}</b>
          </div>
          <button
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
