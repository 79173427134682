
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddAddAlarmName,
  getEditAddAlarmName,
} from "../../../Redux/actions/AddAlarmList";
import { history } from "../../../Redux/store";

function AdditionalAlarmNameEdit({ addNew }) {
  const { ProjectDetails } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  ); // get state to check if new additional alarm list added or change name of exiting alarm name
  const { AddAlarmListDetails } = useSelector(
    ({ AddAlarmList }) => AddAlarmList
  ); //get details of  additional alarm list
  const [additionalAlarmName, setAdditionalAlarmName] = useState(!addNew && AddAlarmListDetails ? AddAlarmListDetails.alarmDetails[0].a_alarm_name:'');
  const [alarmNameError, setAlarmNameError] = useState("");
  const [addNewName, setAddNewName] = useState(addNew ? true : false);
  const dispatch = useDispatch();
//redirect to home if additional alarm list details are null
  if (AddAlarmListDetails === null && !addNew) {
    history.push("/home");
  }
  //run edit name function on enter key
  const handlekeyup=(event)=>{
    if (event.key === "Enter") {
      handleClick();
    }
  }
  //add or update additional alarm list
  const handleClick = () => {
    window.localStorage.removeItem('addpageIndex') 
    window.localStorage.removeItem('addIndexId')
    window.localStorage.removeItem('addstartIndex')
    window.localStorage.removeItem('addendIndex')
    if (additionalAlarmName === "") {
      setAlarmNameError("Additional Alarm List Name is required.");
    } 
    else if (AddAlarmListDetails && additionalAlarmName !== "" && !addNew) {
      dispatch(
        getEditAddAlarmName(
          AddAlarmListDetails?.alarmDetails[0]?._id,
          additionalAlarmName
        )
      );
    } else if (addNew && additionalAlarmName !== "") {
      dispatch(getAddAddAlarmName(ProjectDetails[0]?._id, additionalAlarmName));
    }
  };
  return (
    <div className="main-inner-card add-alarm">
      <div className="profile-box">
        <div className="form-row">
          <div className="row">
            <div className="col">
              <label for="Company-Name" className="col-sm-12 col-form-label ">
                {" "}
                Additional Alarm List Name
              </label>
              <input
                type="email"
                className="form-control"
                placeholder=" Enter Additional Alarm List name here"
                aria-label="Company-Name"
                value={additionalAlarmName}
                onChange={(e) => setAdditionalAlarmName(e.target.value)}
                onBlur={() =>
                  additionalAlarmName !== "" ? setAlarmNameError("") : null
                }
                onKeyUp={handlekeyup}
              />
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {alarmNameError}
              </div>
            </div>
          </div>
        </div>

        <div className="profile-btn justify-content-center ">
          <button className="primary-btn" onClick={handleClick}>
            {" "}
            Save{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdditionalAlarmNameEdit;
