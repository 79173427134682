import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../Utility/jwt/config";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSnackbar,
  fetchError,
  fetchSuccess,
} from "../../Redux/actions/Common";
import { ExportAPI, globalMerge } from "../../Redux/actions/Export";
import Globalmergemodel from "../Globalmergemodel";
import ReactTooltip from "react-tooltip";
import moment from "moment"

function GlobalMerge() {
  const { id } = useParams(); // get project id from url
  const { MergeStatus} = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const [openGlobal, setopenGlobal] = useState(false);
  const [ExportUser, setExportUser] = useState("");
  const dispatch = useDispatch();
  const { exportDetails  , globalMergeStatus,AllMerge} = useSelector(({ Export }) => Export);
 
const shortedGlobalMerge=exportDetails?.global_docs.sort((a, b) => b.created_at - a.created_at);

  const HandelGlobalmerge = () => {
 
 if (exportDetails.remaining_merge_language == 0 && exportDetails.total_language!==1 && !MergeStatus) {
      dispatch(globalMerge(id));

    } else {
      setopenGlobal("block");
    }
  };

  const Handelexport = (e) => {
    window.open(e.target.id, "_self");
    dispatch(fetchSuccess("File downloading started.."));
    setTimeout(function () {
      dispatch(clearSnackbar());
    }, 3000);
  };

  return (
    <>
      <hr className="dropdown-divider w-100 float-start my-3" />
      <div className="main-inner-card alarm-tab-section mergeScroll">
        <table className="table ">
          <thead>
            <tr>
              <th scope="col" style={{ width: "320px" }}>
                Global - Google Docs Merge & Export
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Global merge </td>
              <td className="text-end">
                {exportDetails?.total_language?<button className={!globalMergeStatus && !AllMerge ? 'export-btn' : 'disable_btn'} onClick={HandelGlobalmerge}  disabled ={globalMergeStatus || AllMerge}>
                  Merge  {!globalMergeStatus && !AllMerge ?<i className="bi bi-intersect"></i>:<button className='mergeLoader'></button>} 
                </button>:<button className={'disable_btn'}  disabled ='true'>
                  Merge <i className="bi bi-intersect"></i>
                </button>}
              </td>
            </tr>
            {shortedGlobalMerge?.map((value) => {
             
              return (
                <>
                  <tr>
                    <td>Global merge - { moment(value.created_at * 1000).format("DD MMM YYYY hh:mm A")} </td>
                    <td className="text-end">
                   
                      <button
                        className="export-btn"
                        onClick={Handelexport}
                        id={value.download_key}
                        data-tip = {'Last merged'+ ' by ' +value?.created_by?.first_name +" "+value?.created_by?.last_name} 
                        data-for="registerTip1"
                      >
                        Export <i className="bi bi-file-earmark-excel-fill"></i>
                      </button>

                      <ReactTooltip
                        id="registerTip1"
                        place="top"
                        effect="solid"
                        backgroundColor='#198754'
                        borderColor='#fff'
                        border='1px'
                      >
                        
                      </ReactTooltip>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <Globalmergemodel
        openGlobal={openGlobal}
        setopenGlobal={setopenGlobal}
        exportDetail={exportDetails}
      />
    </>
  );
}

export default GlobalMerge;
