import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectSetting } from "../../Redux/actions/Project";
import { exportProjectSetting } from "../../Redux/actions/Export";
import { useParams } from 'react-router-dom';
import axios from "../../Utility/jwt/config";
import { ALARM_LOADER,EXPORTLOADING } from "../../Utility/ActionConstant";
import {
  clearSnackbar,
  fetchError,
  fetchSuccess,
} from "../../Redux/actions/Common";
import { ExportAPI, langMerge } from "../../Redux/actions/Export";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import MergeModel from "../MergeModel";
function LanguageMerge() {
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [langId, setLangId] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);
  const [isMergeWithPageBreak, setIsMergeWithPageBreak] = useState(false);
  const [isMergeUsedAlarm, setiIsMergeUsedAlarm] = useState(false)
  
  
  const dispatch = useDispatch();
  const { exportDetails, langMergeStatus, globalMergeStatus, AllMerge ,projectSetting,settings} =
    useSelector(({ Export }) => Export);

    // console.log('settings',settings)
  useEffect(() => {
    setBtnDisable(false);
  }, [langMergeStatus]);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [exportDetails]);
  const {id} = useParams() ;
  const collator = new Intl.Collator("en");

  function SortArray(x, y) {
    return collator.compare(x.name, y.name);
  }
  let sortedLang = exportDetails?.languages?.sort(SortArray);

  const exportLang = (e) => {
    // console.log(e.target.value);
    window.open(e.target.value, "_self");
    dispatch(fetchSuccess("File downloading started."));
    setTimeout(function () {
      dispatch(clearSnackbar());
    }, 3000);
  };
  const handleLangerge = (e) => {
    setBtnDisable(e.target.value);
    setTimeout(() => {
      setBtnDisable(false);
    }, 5000);
    if (globalMergeStatus && e.target.value) {
      setProjectId(exportDetails._id);
      setLangId(e.target.value);
      setOpen("block");
    } else {
      if (e.target.value) {
        dispatch(langMerge(exportDetails._id, e.target.value));
      }
    }
  };

  const mergeUsedAlarm = () => {
    setiIsMergeUsedAlarm(!isMergeUsedAlarm);
    const data=
    {
      show_used_alarm_column: null,
      hide_language_ids:null,
      export_used_alarm: null,
      merge_with_page_break: null,
      fill_with_number: null,
      merge_used_alarm: !settings.mergeUsedAlarm
    }
    dispatch({ type: EXPORTLOADING, payload: true });
    dispatch({ type: ALARM_LOADER, payload: true });
    dispatch(getProjectSetting(id,data,callbackFun,true));
  }
  const mergeWithPageBreak = () => {
    setIsMergeWithPageBreak(!isMergeWithPageBreak);
    const data=
    {
      show_used_alarm_column: null,
      hide_language_ids:null,
      export_used_alarm: null,
      merge_with_page_break: !settings.mergeWithPageBreak,
      fill_with_number: null,
      merge_used_alarm: null
    }
    dispatch({ type: EXPORTLOADING, payload: true });
    dispatch({ type: ALARM_LOADER, payload: true });
    dispatch(getProjectSetting(id,data,callbackFun,true));

  }
  const callbackFun = () => {
    dispatch(exportProjectSetting(id))
  }
  
  return (
    <>
      <hr className="dropdown-divider w-100 float-start my-3" />
      <div className="main-inner-card alarm-tab-section mergeScroll">
        <table className="table ">
          <thead>
            <tr>
              <th scope="col" style={{ width: "320px", textAlign: "left" }}>
                Language - Google Docs Merge & Export
              </th>
          
              <th>
              <td>
                         <label className="cust_checkbox" style={{ marginLeft: '175px' }} >
                              <input
                                type="checkbox"
                                defaultChecked = {settings.mergeUsedAlarm}
                                onChange={mergeUsedAlarm}
                              />
                              <label></label>
                              <span className="checkmark"></span>
                            </label>      
                          <label style={{
                            marginLeft:'-30px',
                            marginRight:'30px',
                            fontSize: '14px',
                            fontWeight: '600',
                            color:' #2f4c41b3'}}> Only merge used alarm  </label>

                             <label className="cust_checkbox"  >
                              <input
                                type="checkbox"
                                defaultChecked = {settings.mergeWithPageBreak}
                                onChange={mergeWithPageBreak}
                              />
                              <label></label>
                              <span className="checkmark"></span>
                            </label> <label style={{
                              marginLeft:'-30px',
                              fontSize: '14px',
                              fontWeight: '600',
                              color:' #2f4c41b3'}}>Merge with page break</label>
                  </td>
              </th>
            </tr>
          </thead>

          <tbody>
            <MergeModel
              open={open}
              setOpen={setOpen}
              projectId={projectId}
              langId={langId}
            />
            {sortedLang?.map((lang, index) => {
              return (
                <tr>
                  <td>{lang?.name} </td>
                  <td >
                    {lang.last_merged
                      ? "Last merged" +
                      " on " +
                      moment(lang.last_merged * 1000).format(
                        "DD MMM YYYY hh:mm A"
                      )
                      : null}{" "}
                  </td>
                  {/* <td>
                         <label className="cust_checkbox">
                              <input
                                type="checkbox"
                                defaultChecked = {settings.mergeUsedAlarm}
                                onChange={mergeUsedAlarm}
                              />
                              <label></label>
                              <span className="checkmark"></span>
                            </label> <label >Only merge used alarm</label>
                                <br/><br/>
                             <label className="cust_checkbox"  >
                              <input
                                type="checkbox"
                                defaultChecked = {settings.mergeWithPageBreak}
                                onChange={mergeWithPageBreak}
                              />
                              <label></label>
                              <span className="checkmark"></span>
                            </label> <label>Merge with page break</label>
                  </td> */}
                  {!langMergeStatus.length ? (

                    <td className="text-end">
                      <button
                        disabled={btnDisable}
                        className={btnDisable ? "disable_btn" : "export-btn"}
                        value={lang._id}
                        onClick={handleLangerge}
                      >
                        Merge{" "}
                        {btnDisable ? (
                          <button className="mergeLoader"></button>
                        ) : (
                          <i className="bi bi-intersect"></i>
                        )}
                      </button>

                      <button
                        className={
                          lang.download_key ? "export-btn" : "disable_btn"
                        }
                        disabled={lang.download_key ? false : true}
                        value={lang.download_key}
                        onClick={exportLang}
                      >
                        Export <i className="bi bi-file-earmark-excel-fill"></i>
                      </button>
                    </td>
                  ) : (
                    <td className='text-end'>
                      {langMergeStatus?.map((isProgress) =>
                        isProgress.language_id === lang._id ? (
                          <>
                            {/* <label className="cust_checkbox" style={{ marginRight: '-7px' }} >
                              <input
                                type="checkbox"
                                onChange={() => {
                                }}
                              />
                              <label></label>
                              <span className="checkmark"></span>
                            </label> <label style={{ marginRight: '15px' }}>Only merge used alarm</label> */}
                            <button
                              className={
                                isProgress.is_in_progress ||
                                  btnDisable ||
                                  AllMerge
                                  ? "disable_btn "
                                  : "export-btn"
                              }
                              disabled={
                                isProgress.is_in_progress ||
                                  btnDisable ||
                                  AllMerge
                                  ? true
                                  : false
                              }
                              value={lang._id}
                              onClick={handleLangerge}
                             
                            >
                              Merge{" "}
                              {isProgress.is_in_progress ||
                                btnDisable ||
                                AllMerge ? (
                                <button className="mergeLoader"></button>
                              ) : (
                                <i className="bi bi-intersect"></i>
                              )}
                            </button>
                            <button
                              className={
                                !isProgress.is_in_progress &&
                                  lang.download_key &&
                                  btnDisable !== lang._id
                                  ? "export-btn"
                                  : "disable_btn"
                              }
                              disabled={
                                !lang.download_key ||
                                  isProgress.is_in_progress ||
                                  btnDisable
                                  ? true
                                  : false
                              }
                              value={lang.download_key}
                              onClick={exportLang}
                            >
                              Export{" "}
                              <i className="bi bi-file-earmark-excel-fill"></i>
                            </button>
                            <br/>
                            {/* <label className="cust_checkbox" style={{ marginRight: '-7px' }} >
                              <input
                                type="checkbox"
                                onChange={() => {
                                }}
                              />
                              <label></label>
                              <span className="checkmark"></span>
                            </label> <label style={{ marginRight: '15px' }}>Only merge used alarm</label> */}

                          </>
                        ) : null
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default LanguageMerge;