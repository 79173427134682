import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSnackbar,
  fetchError,
  fetchSuccess,
} from "../Redux/actions/Common";
import axios from "../Utility/jwt/config";
import { ExportAPI, globalMerge } from "../Redux/actions/Export";

export default function Globalmergemodel({
  openGlobal,
  setopenGlobal,
  exportDetail,
}) {
  const { id } = useParams(); // get project id from url
  const { MergeStatus } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  var globalmergelan = exportDetail?.remaining_merge_language;
  var totalmerge = exportDetail?.total_language;
  var Penddingmerge = totalmerge - globalmergelan;
  const dispatch = useDispatch();

  const HandelmergeGlobal = () => {
    setopenGlobal("none");

    dispatch(globalMerge(id));
  };
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{
        display: openGlobal,
        position: "absolute",
        alignItems: "center",
      }}
    >
      {globalmergelan === totalmerge || Penddingmerge <= 1 ? (
        <div className="modal-dialog" style={{ margin: "14.75rem auto" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Global Merge</h5>
            </div>
            <div className="modal-body">
              <p>
                There should be atleast two or more languages merged in your
                project to perform global merge.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => setopenGlobal("none")}
              >
                <b>Ok</b>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-dialog" style={{ margin: "14.75rem auto" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Global merge</h5>
            </div>
            <div className="modal-body">
              {MergeStatus ? (
                <>
                  <p>The language merge process is going on,</p>
                  <p>
                    if you perform global merge now then global merge will not
                    contain languages that are currently in the merging process.{" "}
                  </p>
                </>
              ) : (
                <p>
                  {exportDetail?.total_language -
                    exportDetail?.remaining_merge_language}{" "}
                  out of {exportDetail?.total_language} language are merged , Do
                  you want to perform global merge?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => setopenGlobal("none")}
              >
                <b>Cancel</b>
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={HandelmergeGlobal}
              >
                <b>Merge</b>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
