import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SNACKBAR_CLEAR,
  UNIVERSAL_LOADING,
  ALARM_LOADER
} from "../../Utility/ActionConstant";

const INIT_STATE = {
  initialURL: "/",
  error: "",
  message: "",
  loading: false,
  successSnackbarOpen: "hide",
  color: "success",
  universalloading:true,
  ALARMLOADER:true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: "", message: "", loading: true };
    }
    case FETCH_SUCCESS: {
      
      return {
        ...state,
        error: "",
        loading: false,
        successSnackbarOpen: "show",
        color: "success",
        message: action.payload,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        successSnackbarOpen: "show",
        color: "danger",
        message: action.payload,
      };
    }
    case SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: "hide",
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
        message:'',
      };
      case UNIVERSAL_LOADING:
      return {
        ...state,
        universalloading:action.payload,
      };
      case ALARM_LOADER:
      return {
        ...state,
        ALARMLOADER:action.payload,
      };
    default:
      return state;
  }
};
