import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import Loading from "../../../Components/Layout/Loading";
import { ExportAPI, langStatus } from "../../../Redux/actions/Export";
// import { useLocation } from 'react-router-dom';
// react redux function import
import {
  clearSnackbar,
  fetchError,
  fetchSuccess,
  universalLoading,
} from "../../../Redux/actions/Common";
import {
  createGoogleDoc,
  getAccessAlarm,
  getAddAlarm,
  getDeleteAlarm,
  getDeleteProject,
  getEditAlarm,
  getProjectDetails,
  getUserDelete,
  getUserEdit,
  usableAlarmFileList,
  getalarmSelectDeselect,
  getProjectSetting,

} from "../../../Redux/actions/Project";

import { exportProjectSetting } from "../../../Redux/actions/Export";
import {
  ALARM_LOADER,
  EXPORTLOADING,
  SET_EDITPROJECTLANGUAGES,
  SET_PROJECTDETAILS_LOADING,
  UPDATE_TITLE,
  GET_USABLEFILELIST

} from "../../../Utility/ActionConstant";
import { ExportToExcel } from "../../../Utility/ExcleExport";
import axios from "../../../Utility/jwt/config";
import AdditionalAlarmNameEdit from "../Additional Alarms/AdditionalAlarmNameEdit";
import { useLocation } from "react-router-dom";
import DeleteModel from "../../../Components/Layout/DeleteModel";
import AlarmLoaderComponent from "../../../Components/Layout/AlarmLoaderComponent";
import ReactTooltip from "react-tooltip";
import ProjectExport from "./ProjectExport";
import AlarmDocModel from "../../../Components/Layout/AlarmDocModel";
import AlarmSetting from "../../../Components/Layout/AlarmSetting";
import UploadFile from '../../../Components/Layout/UploadFile'
import UsableAlarmsFileList from "./UsableAlarmsFileList";
const filename = "example"; //defined name for export file
const addNew = true; // used this variable to know if new additional alarm list need to be added or show exiting list

function ProjectDetails() {
  const { id } = useParams(); // get project id from url
  const rowPerPageLocal = Number(
    window.localStorage.getItem("rowPerPage")
  );
  const { ProjectDetails, ProjectDetailsLoading, MergeStatus, pageList, addPageList, usableFileList, displayedLaguages, projectSetting
  } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  
  const { universalloading, ALARMLOADER } = useSelector(({ common }) => common);
  const titleData = {
    title: ProjectDetails ? ProjectDetails[0].project_name : "Loading",
  };
  const { authUser } = useSelector(({ auth }) => auth);
  let found;
  let foundMatch = [];
  const [apidata, setApidata] = useState([]);
  const [projectDetail, setProjectDetail] = useState("");
  const [projectStatus, setProjectStatus] = useState(false);
  const [ownerstatus, setOwnerStatus] = useState(false);
  const [projectId, setProjectId] = useState(
    ProjectDetails ? ProjectDetails[0]._id : ""
  );
  //alarm module
  const [alarmSearcharray, setAlarmSearcharry] = useState(
    ProjectDetails ? ProjectDetails[0].alarms : null
  );
  const [alarmServerSearch, setAlarmServerSearch] = useState("");
  const [alarmSearch, setAlarmSearch] = useState("");
  const [alarmId, setAlarmId] = useState("");
  const [alarmName, setAlarmName] = useState("");
  const [description, setDescription] = useState("");
  const [languageIds, setLanguageIds] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [languageDetails, setLanguageDetails] = useState([]);
  const [languageDetail, setLanguageDetail] = useState("");
  const [addalarmIndex, setAddalarmIndex] = useState(null);
  const [giveAccesskey, setGiveAccessKey] = useState(false);
  const [childAccess, setChildAccess] = useState(false);
  const [checkAlarm, setCheckAlarm] = useState(false)
  //alarm doc status
  const [alarmDocOpen, setAlarmDocOpen] = useState('')
  const [alarmDocAlarmId, setAlarmDocAlarmId] = useState(null)
  const [alarmDocLanguageId, setAlarmDocLanguageId] = useState(null)
  const [alarmDocDocId, setAlarmDocDocId] = useState(null)
  //server side pagination for alarm
  const [pagenumberAlarm, setPagenumberAlarm] = useState(0);
  const [rowPerpage, setRowPerpage] = useState(rowPerPageLocal ? rowPerPageLocal : 20);
  const [allPage, setAllPage] = useState([])
  const [addAllPage, setAddAllPage] = useState([])
  //additional alarmlist server side pagination
  const [pagenumberAddAlarm, setPagenumberAddAlarm] = useState(0);
  const [additionalAlarmsearch, setAdditionalAlarmsearch] = useState("");
  //additional alarm search
  const [addAlarmSearch, setAddAlarmsearch] = useState("");
  const [addalarmSearchArry, setAddalarmSearchArry] = useState([]);
  //add project
  const [addAlarmName, setAddAlarmName] = useState("");
  const [addDescription, setAddDescription] = useState("");
  const [addLanguageId, setAddLangugaeId] = useState("");
  const [addLanguageIds, setAddLanguageIds] = useState([]);
  const [addLanguageDetail, setAddLanguageDetail] = useState("");
  const [addLanguageDetails, setAddLanguageDetails] = useState([]);
  const [alarmFileList, setAlarmFileList] = useState([]);

  const [openUploadModule, setOpenUploadModule] = useState();
  const [openSetting, setOpenSetting] = useState('');
  const [open, setOpen] = useState("");
  //user module
  const [userArry, setUserArry] = useState(
    ProjectDetails ? ProjectDetails[0].access_user : null
  );
  const [userSearch, setUserSearch] = useState("");
  const [userView, setUserView] = useState(false);
  const [userEdit, setUserEdit] = useState(true);
  const dispatch = useDispatch();
  //edit right
  const [editRight, setEditRight] = useState(
    ProjectDetails ? ProjectDetails[0].edit : false
  );
  const [foundArry, setFoundArry] = useState([]);
  //error validation
  const [alarmNameError, setAlarmNAmeError] = useState("");
  const [alarmDescritptionError, setAlarmDescritptionError] = useState("");
  const [alarmIndexError, setAlarmIndexError] = useState("");
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [paginationIndexID, setPaginationIndexID] = useState(0);
  const [paginationUserIndex, setPaginationUserIndex] = useState(0);
  const [paginationUserIndexID, setPaginationUserIndexID] = useState(0);
  const [paginationIndexadd, setPaginationIndexadd] = useState(0);
  const [paginationIndexIDadd, setPaginationIndexIDadd] = useState(0);
  // excle link
  const [excleApiLink, setExcleApiLink] = useState(null);
  const alarmSearchRef = useRef(null);
  const location = useLocation();
  const [GetAlramsId, setGetAlramsId] = useState([]);
  const [GetLanguageId, setGetLanguageId] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.screen.width)
  if (universalloading) {
    dispatch(universalLoading(false));
    const rowPerPageLocal = Number(
      window.localStorage.getItem("rowPerPage")
    );
    dispatch(getProjectDetails(id, 0, rowPerPageLocal));

    window.localStorage.removeItem("pageIndex");
    window.localStorage.removeItem("IndexId");
    window.localStorage.removeItem("startIndex");
    window.localStorage.removeItem("pageNumber");
    setPagenumberAddAlarm(0)
  }
 
  useEffect(() => {
    setAddAlarmName("");
    setAddDescription("");
    setAddalarmIndex(null);
    setPaginationUserIndex(0);
    setPaginationIndex(0);
    if (ProjectDetailsLoading) {
      setPagenumberAlarm(0);
      setPagenumberAddAlarm(0)
    }


    dispatch({ type: UPDATE_TITLE, payload: titleData });


    setEditRight(ProjectDetails ? ProjectDetails[0].edit : false);
    setOwnerStatus(
      ProjectDetails ? (ProjectDetails[0].project_owner ? true : false) : false
    );
    setProjectStatus(
      ProjectDetails
        ? ProjectDetails[0].owner_project_status
          ? true
          : false
        : false
    );
    setProjectDetail(ProjectDetails);



    /* alarm language empty index */
    // get alarm data from responce
    const options = ProjectDetails
      ? ProjectDetails[0]?.languages?.map(function (row) {
        return { name: row.name, id: row._id };
      })
      : "";
    /* alarm language empty index */
    const alarmSearchData = ProjectDetails
      ? ProjectDetails[0]?.alarms.filter((val) => {
        if (alarmSearch === "") {
          return val;
        } else if (
          val.alarm_name
            .toLowerCase()
            .includes(alarmSearch.toLocaleLowerCase())
        ) {
          return val;
        }
      })
      : "";

    const addAlarmSearchData = ProjectDetails
      ? ProjectDetails[0]?.additional_alarm?.alarms?.filter((val) => {
        if (addAlarmSearch === "") {
          return val;
        } else if (
          val.alarm_name
            .toLowerCase()
            .includes(addAlarmSearch.toLocaleLowerCase())
        ) {
          return val;
        }
      })
      : "";

    const userSearchData = ProjectDetails
      ? ProjectDetails[0].access_user.filter((val) => {
        if (userSearch === "") {
          return val;
        } else if (
          val.first_name
            .toLowerCase()
            .includes(userSearch.toLocaleLowerCase())
        ) {
          return val;
        }
      })
      : "";
    const pageIndex = Number(window.localStorage.getItem("pageIndex"));
    const pageIndexId = Number(window.localStorage.getItem("IndexId"));
    const startIndex = Number(window.localStorage.getItem("startIndex"));
    const endIndex = Number(window.localStorage.getItem("endIndex"));
    const pagenumberLocal = Number(window.localStorage.getItem("pageNumber"));
    // setPagenumberAlarm(pagenumberLocal);
    setPaginationIndex(pageIndex ? pageIndex : 0);
    setPaginationIndexID(pageIndexId ? pageIndexId : 0);

    setAddalarmSearchArry(addAlarmSearchData);
    if (alarmSearch !== "") {
      setAlarmSearcharry(alarmSearchData.slice(0));
    } else if (ProjectDetails) {
      setAlarmSearcharry(
        ProjectDetails[0]?.alarms?.slice(startIndex)
      );
    }
    setUserArry(userSearchData.slice(0, 10));

    setAllPage(pageList)
    setAddAllPage(addPageList)
  }, [
    dispatch,
    ProjectDetails,
    alarmSearch,
    userSearch,
    addAlarmSearch,
    alarmServerSearch,
    pageList,

  ]);
  
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleResize = () => {
    setWindowWidth(window.screen.width)
  }
  
  const handleOnChangeAlarm = (alarm_id, is_selected) => {
    //  setCheckAlarm(true)
    dispatch({ type: ALARM_LOADER, payload: true });
    dispatch(getalarmSelectDeselect(id, alarm_id, is_selected, projectDetailsCallBack))
  };

  const projectDetailsCallBack = () => {
    if (alarmServerSearch) {
      dispatch(
        getProjectDetails(ProjectDetails[0]._id, 0, rowPerpage, alarmServerSearch)
      );
    } else {
      dispatch(getProjectDetails(ProjectDetails[0]._id, pagenumberAlarm, rowPerpage));
    }

  }

  const handleOwnerStatus = () => {
    const project_id = ProjectDetails ? ProjectDetails[0]._id : "";

    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(`projects/active-inactive-owner-project/${project_id}`, {
        status: projectStatus ? 0 : 1,
      })
      .then((data) => {
        if (data.status === 200) {
          setProjectStatus(projectStatus ? 0 : 1);
          dispatch(fetchSuccess("Status updated successfully."));
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 500 || err?.response?.status === 401) {
          window.location.reload();
        } else {
          console.log(err?.response?.data?.message);
        }
      });
  };

  const enterFocusOut = (e) => {
    if (e.charCode === 13) {
      document.getElementById('alarmCheckBox').focus({ preventScroll: true });
    }
  }

  const handleKeyPress = (event) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    if (event.target.id === "alarmName") {
      const alarmText = event.target.innerText;
      setAlarmName(event.target.innerText);
      dispatch(
        getEditAlarm(
          event.target.innerText,
          "",
          [],
          [],
          ProjectDetails[0]._id,
          alarmId,
          alarmServerSearch
        )
      );
      setLanguageIds([]);
      setLanguageDetails([]);
      setAddalarmIndex(null);
    } else if (event.target.id === `description`) {
      setLanguageIds([]);
      setLanguageDetails([]);
      setLanguageId("");
      setDescription(event.target.innerText);

      dispatch(
        getEditAlarm(
          "",
          event.target.innerText, +
        [],
          [],
          ProjectDetails[0]._id,
          alarmId,
          alarmServerSearch
        )
      );
      setAddalarmIndex(null);
    }

    if (event.target.id === "language") {
      setLanguageDetail(event.target.innerText);
      setLanguageDetails([...languageDetails, event.target.innerText]);
      setLanguageIds([...languageIds, event.target.accessKey]);

      dispatch(
        getEditAlarm(
          "",
          "",
          [event.target.accessKey],
          [event.target.innerText],
          ProjectDetails[0]._id,
          event.target.slot,
          alarmServerSearch
        )
      );
      setAddalarmIndex(null);
    }
  };
  const handleUpdate = (event) => {
    if (event.key === "Enter") {
      setLanguageDetail(event.target.innerText);
      setLanguageDetails([...languageDetails, event.target.innerText]);

      setLanguageIds([...languageIds, event.target.accessKey]);

      dispatch(
        getEditAlarm(
          "",
          "",
          [event.target.accessKey],
          [event.target.innerText],
          ProjectDetails[0]._id,
          event.target.slot,
          alarmServerSearch
        )
      );
    }
  };
  const handleAddAlarm = (event) => {
    if (
      event.key === "Enter" &&
      event.target.id === "addalarmName" &&
      addAlarmName !== ""
    ) {
      document.querySelector(`#adddescription`).focus();
    } else if (
      event.key === "Enter" &&
      event.target.id === "adddescription" &&
      addDescription !== "" &&
      addAlarmName !== "" &&
      addalarmIndex !== null &&
      addalarmIndex !== "" &&
      addalarmIndex >= 0 &&
      addalarmIndex - ProjectDetails[0].last_alarm_number <= 10000 &&
      addalarmIndex <= 65536
    ) {
      dispatch(
        getAddAlarm(
          addAlarmName,
          addDescription,
          [...addLanguageIds, addLanguageId],
          [...addLanguageDetails, addLanguageDetail],
          ProjectDetails[0]._id,
          addalarmIndex,
          giveAccesskey
        )
      );
      if (ProjectDetails) {
        ProjectDetails[0].languages.forEach(
          (item, index) =>
            (document.getElementById(`addlanguage${index}`).value = "")
        );
      }
      setAddAlarmName("");
      setAddDescription("");
      setAddalarmIndex("");
      setAlarmServerSearch("");
      setAddLanguageIds([]);
      setAddLanguageDetails([]);
      setAddLangugaeId([]);
      setAddLanguageDetail([]);
    }
    if (
      event.key === "Enter" &&
      event.target.accessKey === "addlanguage" &&
      addDescription !== "" &&
      addAlarmName !== "" &&
      addalarmIndex !== null &&
      addalarmIndex !== "" &&
      addalarmIndex >= 0 &&
      addalarmIndex - ProjectDetails[0].last_alarm_number <= 10000 &&
      addalarmIndex <= 65536
    ) {
      dispatch(
        getAddAlarm(
          addAlarmName,
          addDescription,
          [...addLanguageIds, addLanguageId],
          [...addLanguageDetails, addLanguageDetail],
          ProjectDetails[0]._id,
          addalarmIndex,
          giveAccesskey
        )
      );
      if (ProjectDetails) {
        ProjectDetails[0].languages.forEach(
          (item, index) =>
            (document.getElementById(`addlanguage${index}`).value = "")
        );
      }
      setAddAlarmName("");
      setAddDescription("");
      setPaginationIndex(0);
      setAddalarmIndex("");
      setAlarmServerSearch("");
      setAddLanguageIds([]);
      setAddLanguageDetails([]);
      setAddLangugaeId([]);
      setAddLanguageDetail([]);
    }
  };
  const handleChildAccess = (item) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    const giveAccessChild = item.giveAccess ? 0 : 1;
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .put(
        `/alarm/tick-untick-alarm-in-project/${ProjectDetails[0]._id}/${item._id}/${giveAccessChild}`
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess("Alarm access updated."));

          if (alarmServerSearch) {
            dispatch(
              getProjectDetails(ProjectDetails[0]._id, 0, rowPerpage, alarmServerSearch)
            );
          } else {
            dispatch(getProjectDetails(ProjectDetails[0]._id, pagenumberAlarm, rowPerpage));
          }

          setChildAccess(childAccess ? 0 : 1);


          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch({ type: ALARM_LOADER, payload: false });
        dispatch(fetchError(err?.response?.data?.message));
        dispatch({ type: ALARM_LOADER, payload: false });
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
  //alarm search
  const handleSearch = () => {
    if (alarmServerSearch.length > 2) {
      dispatch({ type: ALARM_LOADER, payload: true });
      dispatch(getProjectDetails(id, 0, rowPerpage, alarmServerSearch));
      setPagenumberAlarm(0);
    } else if (!alarmServerSearch.length) {
      dispatch({ type: ALARM_LOADER, payload: true });
      dispatch(getProjectDetails(id, 0, rowPerpage, undefined));
    }
  };
  //additional alarm search
  const handleAdditionalSearch = () => {
    if (additionalAlarmsearch.length > 2) {
      dispatch({ type: ALARM_LOADER, payload: true });
      dispatch(getProjectDetails(id, 0, undefined, 0, additionalAlarmsearch));
      setPagenumberAlarm(0);
    } else if (!additionalAlarmsearch.length) {
      dispatch({ type: ALARM_LOADER, payload: true });
      dispatch(getProjectDetails(id, 0, undefined, 0, undefined));
    }
  };
  //search alarm on enter
  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  //search additional alarm on enter
  const handleAdditionalSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleAdditionalSearch();
    }
  };
  //pagination for  alarm tab
  const handlePagination = (id, pageNumber) => {
    console.log('pagenumber',pageNumber)
    dispatch({ type: ALARM_LOADER, payload: true });
    if (alarmServerSearch.length > 2) {
      dispatch(
        getProjectDetails(id, Math.floor(pageNumber), rowPerpage, alarmServerSearch, Math.floor(pagenumberAddAlarm))
      );
    } else {
      dispatch(getProjectDetails(id, Math.floor(pageNumber), rowPerpage,undefined, Math.floor(pagenumberAddAlarm)));
    }

    window.localStorage.setItem("pageNumber", Math.floor(pageNumber));
    setPagenumberAlarm(Number(pageNumber));
  };
  //Pahse_4 alarm row perpage
  const handleRowPerpage = (e) => {
    dispatch({ type: ALARM_LOADER, payload: true });
    setRowPerpage(Number(e.target.value));
    window.localStorage.setItem("pageNumber", 0);
    window.localStorage.setItem('rowPerPage', e.target.value)
    setPagenumberAlarm(0);
    setPagenumberAddAlarm(0)
    if (alarmServerSearch.length > 2) {
      dispatch(
        getProjectDetails(id, 0, e.target.value, alarmServerSearch,  Math.floor(pagenumberAddAlarm))
      );
    }
    else {
      dispatch(
        getProjectDetails(id, 0, e.target.value, undefined,  Math.floor(pagenumberAddAlarm))
      );
    }
  }

  //pagination for additional alarm tab
  const handlePaginationAdd = (id, pagenumberAddAlarm) => {
  
    if (pagenumberAddAlarm >= 0) {
      dispatch({ type: ALARM_LOADER, payload: true });
      if (additionalAlarmsearch.length > 2) {
        dispatch(
          getProjectDetails(
            id,
            pagenumberAlarm,
            rowPerpage,
            undefined,
            Math.floor(pagenumberAddAlarm), additionalAlarmsearch
          )
        );
      } else {
        dispatch(
          getProjectDetails(
            id,
            pagenumberAlarm,
            rowPerpage,
            undefined,
            Math.floor(pagenumberAddAlarm)
          )
        );
      }

      setPagenumberAddAlarm(Number(pagenumberAddAlarm));
    }
  };
  //pagination for user tab
  const handlePaginationUser = (a, b, i, e) => {
    setUserArry(ProjectDetails[0].access_user.slice(a, b));
    setPaginationUserIndex(i);
    setPaginationUserIndexID(e);
  };   
  // write doc function here
  const handelGoogleDocument = (e) => {
    setAlarmDocDocId(null)
    const DocumentId = e.target.id;
    setAlarmDocDocId(DocumentId)
    if (MergeStatus) {
      setAlarmDocOpen('block')
    } else {
      if (DocumentId) { window.open(`https://docs.google.com/document/d/${DocumentId}`); }
    }
  };

  const handleOpenTemplate = () => {
    window.open(
      `https://docs.google.com/document/d/${ProjectDetails[0].template_id}`
    );
  };

  const handelcreateDocument = (e) => {
    setAlarmDocDocId(null)
    const alarmId = e.target.accessKey;
    const languageId = e.target.id;
    setAlarmDocAlarmId(alarmId)
    setAlarmDocLanguageId(languageId)
    if (MergeStatus) {
      setAlarmDocOpen('block')
    } else {

      if (ProjectDetails[0]._id && alarmId && languageId) {
        dispatch({ type: ALARM_LOADER, payload: true });
        dispatch(createGoogleDoc(ProjectDetails[0]._id, alarmId, languageId, alarmServerSearch))
      }
    }

  };

  //render project detail only when all data recived from backend
  if (!ProjectDetailsLoading) {
    return (
      <div className="tab-menu-section" >
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Project Details
            </a>
          </li>
          <li
            className="nav-item"
            role="presentation"
            onClick={() => setAlarmServerSearch("")}
          >
            <a
              className="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >

              Alarms
            </a>
          </li>


          <li
            className="nav-item"
            role="presentation"
            onClick={() => {dispatch(usableAlarmFileList(ProjectDetails[0]._id,0,20));
              window.localStorage.setItem('u_l',20)
              window.localStorage.setItem('u_p',0)
            }}
          >
            <a
              className="nav-link"
              id="pills-usedalarms-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-usedalarms"
              type="button"
              role="tab"
              aria-controls="pills-usedalarms"
              aria-selected="false"
            >

              Used Alarms
            </a>
          </li>

          <li
            className="nav-item"
            role="presentation"
            onClick={
              (() => setAdditionalAlarmsearch(""),
                window.localStorage.setItem("addpageNumber", Math.floor(0)))
            }
          >
            <a
              className="nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
            >
              Additional Alarms
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile4"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Users
            </a>
          </li>
          <li className="nav-item" role="presentation"
            onClick={() => { dispatch(ExportAPI(ProjectDetails[0]._id)); dispatch(exportProjectSetting(projectDetail[0]._id)); dispatch({ type: EXPORTLOADING, payload: true }); }}>
            <a
              className="nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact5"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
            >
              Export
            </a>
          </li>

        </ul>
        <div className="tab-content" id="pills-tabContent">
          {/* Project Details Tab*/}
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="main-inner-card tab-project-details" style={{ maxHeight: '80vh' }}>
              <div className="row">
                <div className="col tab-project-details-row1">
                  <div className="tab-project-details-row1-box">
                    <div className="tab-project-details-row1-box-img">
                      <img
                        src={process.env.PUBLIC_URL + "/img/folder-fill.svg"}
                      />
                    </div>
                    <div className="tab-project-details-row1-box-switch">
                      <h4>
                        {ProjectDetails
                          ? ProjectDetails[0].project_name
                          : "Loading"}
                      </h4>
                      {ProjectDetails ? (
                        ProjectDetails[0].project_owner ? (
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              checked={projectStatus}
                              onChange={handleOwnerStatus}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefault"
                            ></label>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col tab-project-details-row1">
                  <NavLink to="/home/projectlist/addproject">
                    {" "}
                    <button
                      className="add-project-btn"
                      onClick={(e) =>
                        dispatch({
                          type: SET_EDITPROJECTLANGUAGES,
                          payload: [],
                        })
                      }
                    >
                      Add New Project
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className="tab-project-details-row2">
                <label className="col-form-label">Parent Project</label>
                <h6>
                  {ProjectDetails[0]?.parent_project?.project_name
                    ? ProjectDetails[0]?.parent_project?.project_name
                    : "None"}
                </h6>
              </div>
              <div className="tab-project-details-row2">
                <label className="col-form-label">Description</label>
                <h6>
                  {ProjectDetails
                    ? ProjectDetails[0].description
                      .split("\n")
                      .map((i, key) => {
                        return <div key={key}>{i}</div>;
                      })
                    : "Loading"}
                </h6>
              </div>
              <div className="tab-project-details-row2">
                <label className="col-form-label">
                  Reload Standard Messages from Master List:
                </label>
                <h6>
                  {ProjectDetails
                    ? ProjectDetails[0].r_s_m_from_ml
                      ? "Do it"
                      : `Don't Do it`
                    : "Loading"}
                </h6>
              </div>
              <div className="tab-project-details-row2">
                <label className="col-form-label">Alarm Languages</label>
                <ul>
                  {ProjectDetails
                    ? ProjectDetails[0].languages.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))
                    : "Loading"}
                </ul>
              </div>
              <div className="tab-project-details-row2">
                <label className="col-form-label">Additional Alarm:</label>
                <h6>
                  {ProjectDetails[0]?.additional_alarm?.a_alarm_name
                    ? ProjectDetails[0]?.additional_alarm?.a_alarm_name
                    : "None"}
                </h6>
              </div>
              <div className="tab-project-details-row2">
                {ProjectDetails ? (
                  ProjectDetails[0].edit ? (
                    <NavLink to="/home/projectlist/editproject">
                      <button
                        className="edit-btn"
                        onClick={(e) =>
                          dispatch({
                            type: SET_EDITPROJECTLANGUAGES,
                            payload: ProjectDetails[0].languages,
                          })
                        }
                      >
                        Edit Project Details
                      </button>
                    </NavLink>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {ProjectDetails ? (
                  ProjectDetails[0].edit ? (
                    <NavLink to="#">
                      {ProjectDetails[0]?.project_owner ? (
                        <button
                          className="btn-edit-template" //access css className from App.scss
                          onClick={() => handleOpenTemplate()}
                        >
                          Edit Project Template
                        </button>
                      ) : (
                        " "
                      )}
                    </NavLink>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {ProjectDetails[0]?.project_owner && ProjectDetails[0]?.owner_edit_permission ? (
                  <button
                    type="button"
                    className="edit-btn btn-danger"
                    style={{ backgroundColor: "#dc3545" }}
                    onClick={(e) => setOpen("block")}
                  >
                    Delete Project{" "}
                  </button>
                ) : (
                  ""
                )}
                <DeleteModel
                  setOpen={setOpen}
                  open={open}
                  projectid={ProjectDetails[0]._id}
                />
              </div>
            </div>
          </div>

          {/* Alarm Tab */}
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="alarm-tab-section-title" >
              <h4 style={{flexGrow:2}}>Alarms</h4>
              <div className="alarm-tab-section-box" style={{ marginLeft: '380px' }}>
                <div className="alarm-tab-section-search float-end">
                  <input
                    type="text"
                    placeholder="Search..."
                    ref={alarmSearchRef}
                    value={alarmServerSearch}
                    onChange={(e) => setAlarmServerSearch(e.target.value)}
                    onKeyUp={handleSearchEnter}
                    style={{ color: "black" }}
                  />
                  <button onClick={handleSearch}>
                    <img
                      src={process.env.PUBLIC_URL + "/img/search-white.svg"}
                    />
                  </button>
                </div>
              </div>
                
              {/* Alarms Setting module */}
              {ProjectDetails[0]?.project_owner && ProjectDetails[0]?.owner_edit_permission ? (
                <a
                  type="button"
                  // className="breadcrumb-item"
                  style={{zIndex:'999',marginLeft:'15px'}}
                  onClick={(e) => setOpenSetting("block")}
                >
                  <i class="bi bi-gear-fill" style={{ color: '#1D946B' }}></i>
                </a>
              ) : (
                ""
              )}
              <AlarmSetting
                
                setOpenSetting={setOpenSetting}
                openSetting={openSetting}
                projectid={ProjectDetails[0]._id}
              />
            </div>
            <div className="main-inner-card alarm-tab-section sticky-last-child" style={{ maxHeight: windowWidth < 2400 ? '60vh' : '80vh' }}>
              <AlarmDocModel setAlarmDocOpen={setAlarmDocOpen}
                alarmDocOpen={alarmDocOpen}
                projectid={ProjectDetails[0]._id}
                alarmDocAlarmId={alarmDocAlarmId}
                alarmDocLanguageId={alarmDocLanguageId}
                alarmDocDocId={alarmDocDocId}
                alarmServerSearch={alarmServerSearch}
              />
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">ID</th>
                    <th scope="col">Alarm Name</th>
                    <th scope="col">Description</th>
                    {ProjectDetails
                      ? displayedLaguages.map((item, index) => (
                        <th key={index} scope="col">
                          {item.name}
                        </th>
                      ))
                      : ""}
                  
                    {ProjectDetails[0]?.show_used_alarm_column ? <th scope="col" style={{minWidth:'100px'}}>Used Alarm</th> : null}
                  </tr>
                </thead>
                {!ALARMLOADER ? (
                  <tbody>
                    {alarmSearcharray
                      ? alarmSearcharray.map((item, index) => (
                        <tr
                          key={index}
                          className={item.gotAccess ? "alarmEdit" : ""}
                          style={{
                            cursor: item.gotAccess ? "not-allowed" : "",
                          }}

                        >
                          <td className="text-center">
                            <label className="cust_checkbox">
                              <input
                                id='alarmCheckBox'
                                type="checkbox"
                                checked={item.giveAccess}
                                onChange={() => {
                                  handleChildAccess(item);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <p>{item.alarm_number}</p>
                          </td>
                          <td>
                            <p
                              contentEditable={
                                editRight && !item.gotAccess
                                  ? "true"
                                  : "false"
                              }
                              accessKey={index}
                              id="alarmName"
                              onClick={(e) => {
                                setAlarmName(item.alarm_name);
                                setAlarmId(item._id);
                              }}
                              onFocus={(e) => {
                                setAlarmName(item.alarm_name);
                                setAlarmId(item._id);
                              }}
                              onBlur={handleKeyPress}
                              onKeyPress={enterFocusOut}
                              suppressContentEditableWarning={true}
                            >
                              {item.alarm_name}

                            </p>
                          </td>
                          <td style={{ maxWidth: "350px" }}>

                            <p
                              contentEditable={
                                editRight && !item.gotAccess
                                  ? "true"
                                  : "false"
                              }
                              id="description"
                              accessKey={index}
                              onFocus={(e) => {
                                setDescription(item.description);
                                setAlarmId(item._id);
                              }}
                              onBlur={handleKeyPress}
                              onKeyPress={enterFocusOut}
                              suppressContentEditableWarning={true}
                            >
                              {item.description}
                            </p>
                          </td>

                          {displayedLaguages?.map(
                            (items, index) => (
                              (found = item.languages.find((element, index) =>
                                element.id === items._id ? element : false
                              )),
                              found ? (
                                <td key={found?.id}>
                                  <div
                                    className="position-relative"
                                    style={{ maxWidth: "360px" }}
                                  >
                                    <p
                                      contentEditable={
                                        editRight && !item.gotAccess
                                          ? "true"
                                          : "false"
                                      }
                                      id="language"
                                      slot={found.alarm_id}
                                      accessKey={found?.id}
                                      onClick={(e) => {
                                        setLanguageDetail(e.target.value);
                                        setLanguageId(found?.id);
                                        
                                      }}
                                      onBlur={handleKeyPress}
                                      onKeyPress={enterFocusOut}
                                      onKeyUp={handleUpdate}
                                      suppressContentEditableWarning={true}
                                    >
                                      {found?.details}
                                    </p>

                                    {
                                      // Empty alarms fields functionality
                                      !item.gotAccess ||
                                        typeof found.doc_id !== "undefined" ? (
                                        (item.showIcon &&
                                          ProjectDetails[0].edit &&
                                          !item.gotAccess) ||
                                          (item.alarm_name !== "" &&
                                            ProjectDetails[0].edit &&
                                            !item.gotAccess) ||
                                          (item.gotAccess && typeof found.doc_id !== "undefined") ||
                                          (item.alarm_name !== "" &&
                                            ProjectDetails[0].edit &&
                                            !item.gotAccess) || (item.gotAccess && found.doc_id) ||
                                          (ProjectDetails[0].edit &&
                                            item.description !== "") ? (
                                          //child project not edit data button hide
                                          typeof found.doc_id !==
                                            "undefined" ? (
                                            <>
                                              <button
                                                className="export-doc-btn"

                                                data-tip
                                                data-for="registerTip"

                                                disabled={
                                                  item.gotAccess
                                                    ? "disabled "
                                                    : ""
                                                }
                                              >
                                                <i
                                                  className="bi bi-file-earmark-text-fill"
                                                  onClick={(e) => !item.gotAccess ? handelGoogleDocument(e) : null}
                                                  id={found.doc_id}
                                                  disabled={
                                                    item.gotAccess
                                                      ? "disabled "
                                                      : ""
                                                  }
                                                ></i>
                                              </button>
                                              <ReactTooltip
                                                id="registerTip"
                                                place="top"
                                                effect="solid"
                                                backgroundColor='#198754'
                                                borderColor='#fff'
                                                border='1px'
                                              >
                                                Open Google Document
                                              </ReactTooltip>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className="export-doc-btn"

                                                data-tip
                                                data-for="registerTip1"
                                              >
                                                <i
                                                  accessKey={found.alarm_id}
                                                  id={found.id}
                                                  className="bi bi-file-earmark-plus-fill"
                                                  onClick={handelcreateDocument}
                                                ></i>
                                              </button>
                                              <ReactTooltip
                                                id="registerTip1"
                                                place="top"
                                                effect="solid"
                                                backgroundColor='#198754'
                                                borderColor='#fff'
                                                border='1px'
                                              >
                                                Create Google Document
                                              </ReactTooltip>
                                            </>
                                          )
                                        ) : (
                                          ""
                                        )
                                      ) : null
                                    }

                                    {/* give condition to this buttons, show upper one if there is doc id else show below button. */}

                                  </div>
                                </td>
                              ) : (
                                ""
                              )
                            )
                          )}
                            
                              <>
                                {ProjectDetails[0]?.show_used_alarm_column ?
                                  <td id={item._id} style={{ minWidth: '100px' }}>
                                    <td>
                                      <label className="cust_checkbox" style={{ marginLeft: '25px' }}>
                                        <input
                                          type="checkbox"
                                          disabled={!projectDetail[0]?.edit}
                                          checked={item.is_selected}
                                          onChange={() => handleOnChangeAlarm(item._id, item.is_selected)}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </td>
                                  </td>
                                  : null}
                              </>
                          {(editRight &&
                            !item.gotAccess &&
                            item.alarm_name !== "") ||
                            (editRight &&
                              !item.gotAccess &&
                              item.description !== "") ||
                            (editRight && item.showIcon && !item.gotAccess) ? (
                            <>

                              <td id={item._id} >

                                {" "}
                                <img
                                  // style={{marginRight:'30px'}}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/icn_trash.svg"
                                  }
                                  onClick={(e) =>
                                    dispatch(
                                      getDeleteAlarm(
                                        ProjectDetails[0]._id,
                                        item._id,
                                        alarmServerSearch
                                      )
                                    )
                                  }
                                />
                              </td>

                            </>



                          ) : (
                            <td></td>
                          )}

                        </tr>
                      ))
                      : ""}

                    {editRight ? (
                      <tr>
                        <td className="text-center">
                          <label className="cust_checkbox">
                            <input
                              type="checkbox"
                              checked={giveAccesskey}
                              onChange={() =>
                                setGiveAccessKey(giveAccesskey ? 0 : 1)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="number"
                            id="addalarmIndex"
                            className={`form-control ${alarmIndexError}`}
                            placeholder="Index"
                            value={addalarmIndex}
                            onChange={(e) => {
                              setAddalarmIndex(e.target.value);
                            }}
                            onBlur={() =>
                              addalarmIndex !== null &&
                                addalarmIndex !== "" &&
                                addalarmIndex >= 0 &&
                                addalarmIndex -
                                ProjectDetails[0].last_alarm_number <=
                                10000 &&
                                addalarmIndex <= 65536
                                ? setAlarmIndexError("")
                                : setAlarmIndexError("error")
                            }
                            onKeyUp={handleAddAlarm}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            id="addalarmName"
                            className={`form-control ${alarmNameError}`}
                            placeholder="Enter Alarm Name"
                            value={addAlarmName}
                            onChange={(e) => {
                              setAddAlarmName(e.target.value);
                              setAlarmNAmeError(
                                addAlarmName === "" ? "error" : ""
                              );
                            }}
                            onKeyUp={handleAddAlarm}
                            onBlur={(e) =>
                              setAlarmNAmeError(
                                addAlarmName === "" ? "error" : ""
                              )
                            }
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            id="adddescription"
                            className={`form-control ${alarmDescritptionError}`}
                            placeholder="Enter Description"
                            value={addDescription}
                            onChange={(e) => {
                              setAddDescription(e.target.value);
                              setAlarmDescritptionError(
                                addDescription === "" ? "error" : ""
                              );
                            }}
                            onKeyUp={handleAddAlarm}
                            onBlur={(e) =>
                              setAlarmDescritptionError(
                                addDescription === "" ? "error" : ""
                              )
                            }
                          />
                        </td>
                        {displayedLaguages.map((item, index) => (
                          <td key={index}>
                            <input
                              id={`addlanguage${index}`}
                              accessKey="addlanguage"
                              type="text"
                              className="form-control"
                              placeholder={"Enter " + item.name}
                              onChange={(e, i) => {
                                setAddLanguageDetail(e.target.value);
                              }}
                              onFocus={(e) => {
                                setAddLangugaeId(item._id);
                                setAddLanguageDetail(e.target.value);
                              }}
                              onKeyUp={handleAddAlarm}
                              onBlur={(e) => {
                                setAddLanguageDetails([
                                  ...addLanguageDetails,
                                  e.target.value,
                                ]);
                                setAddLanguageIds([
                                  ...addLanguageIds,
                                  item._id,
                                ]);
                              }}
                            />
                          </td>
                        ))
                        }

                        <td style={{maxWidth:'30px',    position: 'unset'}}></td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                ) : (
                  <tbody
                    style={{
                      cursor: "not-allowed",
                    }}
                  >
                    {alarmSearcharray
                      ? alarmSearcharray.map((item, index) => (
                        <tr
                          key={index}
                          className={item.gotAccess ? "alarmEdit" : ""}
                          style={{
                            cursor: item.gotAccess ? "not-allowed" : "",
                          }}
                        >
                          <td className="text-center">
                            <label className="cust_checkbox">
                              <input
                                id='alarmCheckBox'
                                type="checkbox"
                                checked={item.giveAccess}
                                readOnly
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>

                            <p>{item.alarm_number}</p>
                          </td>
                          <td>
                            <p
                              contentEditable={
                                editRight && !item.gotAccess
                                  ? "true"
                                  : "false"
                              }
                              accessKey={index} id="alarmName"
                              onFocus={(e) => {
                                setAlarmName(item.alarm_name);
                                setAlarmId(item._id);
                              }}
                            >
                              {item.alarm_name}
                            </p>
                          </td>
                          <td style={{ maxWidth: "350px" }}>
                            {/* set max widht in below p tag.
                          /> */}
                            <p
                              contentEditable={
                                editRight && !item.gotAccess
                                  ? "true"
                                  : "false"
                              }

                              id="description" accessKey={index}
                              onFocus={(e) => {
                                setDescription(item.description);
                                setAlarmId(item._id);
                              }}
                            >
                              {item.description}
                            </p>
                          </td>


                          {ProjectDetails[0].languages.map(
                            (items, index) => (
                              (found = item.languages.find((element, index) =>
                                element.id === items._id ? element : false
                              )),
                              found ? (
                                <td key={found?.id}>
                                  <p

                                    contentEditable={
                                      editRight && !item.gotAccess
                                        ? "true"
                                        : "false"
                                    }
                                    id="language"
                                    slot={found.alarm_id}
                                    accessKey={found?.id}
                                  >
                                    {found?.details}
                                  </p>
                                </td>
                              ) : (
                                ""
                              )
                            )
                          )}
 <>
                                {ProjectDetails[0]?.show_used_alarm_column ?
                                  <td id={item._id} style={{ minWidth: '100px' }}>
                                    <td>
                                      <label className="cust_checkbox" style={{ marginLeft: '25px' }}>
                                        <input
                                          type="checkbox"
                                          disabled={!projectDetail[0]?.edit}
                                          checked={item.is_selected}
                                          onChange={() => handleOnChangeAlarm(item._id, item.is_selected)}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </td>
                                  </td>
                                  : null}
                              </>
                          {(editRight &&
                            !item.gotAccess &&
                            item.alarm_name !== "") ||
                            (editRight &&
                              !item.gotAccess &&
                              item.description !== "") ||
                            (editRight && item.showIcon && !item.gotAccess) ? (
                            <td id={item._id}>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/icn_trash.svg"
                                }
                              />
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ))
                      : ""}

                    {editRight ? (
                      <tr>
                        <td className="text-center">
                          <label className="cust_checkbox">
                            <input type="checkbox" checked={giveAccesskey} readOnly />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <input
                            type="number"
                            id="addalarmIndex"
                            className={`form-control ${alarmIndexError}`}
                            placeholder="Index"
                            value={addalarmIndex}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            id="addalarmName"
                            className={`form-control ${alarmNameError}`}
                            placeholder="Enter Alarm Name"
                            value={addAlarmName}
                            readOnly
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            id="adddescription"
                            className={`form-control ${alarmDescritptionError}`}
                            placeholder="Enter Description"
                            value={addDescription}
                            readOnly
                          />
                        </td>
                        {ProjectDetails
                          ? ProjectDetails[0].languages.map((item, index) => (
                            <td key={index}>
                              <input
                                id={`addlanguage${index}`}
                                accessKey="addlanguage"
                                type="text"
                                className="form-control"
                                placeholder={"Enter " + item.name}
                              />
                            </td>
                          ))
                          : ""}

                        <td className='abc'></td>


                      </tr>
                    ) : (
                      ""
                    )}
                    <AlarmLoaderComponent />
                  </tbody>
                )}
              </table>
            </div>
            <ul className="table-pagination">
              <li>
                {/* first page */}
                <button onClick={(e) => handlePagination(id, 0)}>
                  <i className="bi bi-chevron-double-left" width='50px'></i>
                </button>
              </li>
              <li>

                {/* Prev page */}
                <button
                  onClick={(e) =>
                    Number(pagenumberAlarm) !== 0
                      ? handlePagination(id, Number(pagenumberAlarm) - 1)
                      : null
                  }
                >
                  <i className="bi bi-caret-left-fill"></i>
                </button>
              </li>
              {Number(pagenumberAlarm) > 10 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 11)}
                  >
                    {((Number(pagenumberAlarm) - 10) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 10) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 9 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 10)}
                  >
                    {((Number(pagenumberAlarm) - 9) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 9) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 8 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 9)}
                  >
                    {((Number(pagenumberAlarm) - 8) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 8) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 7 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 8)}
                  >
                    {((Number(pagenumberAlarm) - 7) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 7) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 6 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 7)}
                  >
                    {((Number(pagenumberAlarm) - 6) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 6) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 5 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 6)}
                  >
                    {((Number(pagenumberAlarm) - 5) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 5) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 4 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 5)}
                  >
                    {((Number(pagenumberAlarm) - 4) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 4) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 3 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 4)}
                  >
                    {((Number(pagenumberAlarm) - 3) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 3) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 2 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 3)}
                  >
                    {((Number(pagenumberAlarm) - 2) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 2) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 1 ? (
                <li>
                  {/* current page-2 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 2)}
                  >
                    {((Number(pagenumberAlarm) - 1) - 1) * rowPerpage}-{((Number(pagenumberAlarm) - 1) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {Number(pagenumberAlarm) > 0 ? (
                <li>
                  {/* current page -1 */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) - 1)}
                  >
                    {(Number(pagenumberAlarm) - 1) * rowPerpage}-{(Number(pagenumberAlarm) * rowPerpage) - 1}
                  </button>
                </li>
              ) : null}
              {
                <li className={"active"}>
                  {/* current page */}
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm))}
                  >
                    {Number(pagenumberAlarm) * rowPerpage} -{ProjectDetails[0]?.total > rowPerpage ? (Number(pagenumberAlarm) * rowPerpage) + alarmSearcharray?.length - 1 : ProjectDetails[0]?.total ? ProjectDetails[0]?.total - 1 : 0}
                  </button>
                </li>
              }
              {(Number(pagenumberAlarm) + 1) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, Number(pagenumberAlarm) + 1)}
                  >
                    {(Number(pagenumberAlarm) + 1) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 2) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 2) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>
              ) : null}
              {(pagenumberAlarm + 2) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 2)}
                  >
                    {(Number(pagenumberAlarm) + 2) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 3) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 3) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>
              ) : null}
              {(pagenumberAlarm + 3) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 3)}
                  >
                    {(Number(pagenumberAlarm) + 3) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 4) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 4) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 4) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 4)}
                  >
                    {(Number(pagenumberAlarm) + 4) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 5) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 5) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 5) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 5)}
                  >
                    {(Number(pagenumberAlarm) + 5) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 6) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 6) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 6) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 6)}
                  >
                    {(Number(pagenumberAlarm) + 6) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 7) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 7) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 7) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 7)}
                  >
                    {(Number(pagenumberAlarm) + 7) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 8) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 8) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 8) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 8)}
                  >
                    {(Number(pagenumberAlarm) + 8) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 9) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 9) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 9) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 9)}
                  >
                    {(Number(pagenumberAlarm) + 9) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 10) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 10) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 10) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 10)}
                  >
                    {(Number(pagenumberAlarm) + 10) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 11) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 11) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 11) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 11)}
                  >
                    {(Number(pagenumberAlarm) + 11) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 12) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 12) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>


              ) : null}
              {(pagenumberAlarm + 12) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) => handlePagination(id, pagenumberAlarm + 12)}
                  >
                    {(Number(pagenumberAlarm) + 12) * rowPerpage}-{ProjectDetails[0]?.total > (((Number(pagenumberAlarm) + 13) * rowPerpage) - 1) ? ((Number(pagenumberAlarm) + 13) * rowPerpage) - 1 : ProjectDetails[0]?.total - 1}
                  </button>
                </li>
              ) : null}

              {(Number(pagenumberAlarm) + 13) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button>...</button>
                </li>
              ) : null}
              {(Number(pagenumberAlarm) + 13) * rowPerpage < ProjectDetails[0]?.total ? (
                <li>
                  <button
                    onClick={(e) =>
                      (Number(pagenumberAlarm) + 1) * rowPerpage < ProjectDetails[0]?.total
                        ? handlePagination(
                          id,
                          Math.floor(ProjectDetails[0]?.total / rowPerpage)
                        )
                        : ""
                    }
                  >
                    {(Math.ceil(ProjectDetails[0]?.total / rowPerpage) - 1) * rowPerpage}- {ProjectDetails[0]?.total - 1}
                  </button>
                </li>
              ) : null}
              <li>
                <button
                  onClick={
                    (e) =>
                      (pagenumberAlarm + 1) * rowPerpage < ProjectDetails[0]?.total
                        ? handlePagination(id, pagenumberAlarm + 1)
                        : null

                    // ProjectDetails[0]?.total
                  }
                >
                  <i className="bi bi-caret-right-fill"></i>
                </button>
              </li>
              <li>
                <button
                  onClick={(e) =>
                    handlePagination(
                      id,
                      Math.floor(ProjectDetails[0]?.total / rowPerpage)
                    )
                  }
                >
                  <i className="bi bi-chevron-double-right"></i>
                </button>
              </li>
            </ul>
            <ul className="table-pagination">
              <label style={{ color: '#2f4c41b3', fontSize: '14px', fontWeight: '600' }}>Limit</label>
              <select onChange={handleRowPerpage} value={rowPerpage}
                style={{ color: '#1D946B', fontSize: '15px', fontWeight: '600', marginLeft: '3px', width: '55px', backgroundColor: 'white', width: '55px' }}>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
              </select>

              <label style={{ color: '#2f4c41b3', fontSize: '14px', fontWeight: '600', marginLeft: '10px', }}>Number</label>
              <select onChange={(e) => handlePagination(id, e.target.value)}
                style={{ color: '#1D946B', fontSize: '15px', fontWeight: '600', marginLeft: '3px', backgroundColor: 'white', width: 'auto' }} value={pagenumberAlarm}>
                {!allPage?.length ? <option selected disabled>0-0</option> : null}
                {allPage?.map((item, index) => <option value={item?.pageNumber}>{item?.pageName}</option>)}

              </select>

            </ul>
          </div>

          {/* UsedAlarms Tab */}
          <div
            className="tab-pane fade"
            id="pills-usedalarms"
            role="tabpanel"
            aria-labelledby="pills-usedalarms-tab"
          >
            <div className="alarm-tab-section-title">
              <h4>Used Alarms</h4>

              {/* Alarms Setting module */}
              {ProjectDetails[0]?.edit ? (
                <button
                  className="export-btn"
                  style={{
                    maxWidth: '320px',
                    fontSize: '10px',
                    height: '30px',
                    fontWeight: 'bold',
                    borderRadius: '4px',
                    marginLeft: '10px',
                    float: 'right',
                    fontWeight: '600',
                    background: '#1D946B',
                    borderRadius: '10px',
                    border: '2px solid #1D946B',
                    color: '#fff',
                    fontSize: '15px',
                    lineHeight: '22px',
                    transition: '0.3s'
                  }}
                  onClick={(e) => setOpenUploadModule("block")}
                >
                  Upload Alarm File
                </button>
              ) : (
                ""
              )}
              <UploadFile
                setOpenUploadModule={setOpenUploadModule}
                openUploadModule={openUploadModule}
                projectid={ProjectDetails[0]._id}
              />

            </div>
            <UsableAlarmsFileList projectId={ProjectDetails[0]._id} setPagenumberAlarm={setPagenumberAlarm}
      setPagenumberAddAlarm={setPagenumberAddAlarm}/>
          </div>








          {/* Additional Alarm Tab */}
          <div
            className="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            {ProjectDetails[0]?.additional_create !== 1 ? (
              ProjectDetails[0]?.additional_create === 0 ? (
                <>
                  <div className="alarm-tab-section-title">
                    <h4>Additional Alarms</h4>
                  </div>
                  <div className="main-inner-card alarm-tab-section">
                    <p style={{ textAlign: "center", marginTop: "10px" }}>
                      This project does not have any additional alarm list.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="alarm-tab-section-title">
                    <h4>Additional Alarms</h4>
                  </div>
                  <AdditionalAlarmNameEdit addNew={addNew} />
                </>
              )
            ) : (
              <>
                {" "}
                <div className="alarm-tab-section-title">
                  <h4>
                    {ProjectDetails[0]?.additional_alarm.a_alarm_name
                      ? ProjectDetails[0]?.additional_alarm.a_alarm_name
                      : ""}
                  </h4>
                  <div className="alarm-tab-section-box">
                    <div className="alarm-tab-section-search">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={additionalAlarmsearch}
                        onChange={(e) => {
                          setAdditionalAlarmsearch(e.target.value);
                        }}
                        onKeyUp={handleAdditionalSearchEnter}
                      />
                      <button onClick={handleAdditionalSearch}>
                        <img
                          src={process.env.PUBLIC_URL + "/img/search-white.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="main-inner-card alarm-tab-section" style={{ maxHeight: windowWidth < 2600 ? '60vh' : '80vh' }}>
                  <table className="table ">
                    <thead >
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Alarm Name</th>
                        <th scope="col">Description</th>
                        {ProjectDetails[0]?.additional_alarm?.languages?.map(
                          (al) => (
                            <th scope="col">{al.name}</th>
                          )
                        )}
                      </tr>
                    </thead>
                    {!ALARMLOADER ? (
                      <tbody>
                        {ProjectDetails
                          ? addalarmSearchArry?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <p>{item.alarm_number}</p>

                              </td>
                              <td>
                                <p id="alarmName">{item.alarm_name}</p>
                              </td>
                              <td style={{ maxWidth: "350px" }}>
                                <p id="description" accessKey={index}>
                                  {item.description}
                                </p>
                              </td>

                              {ProjectDetails[0]?.additional_alarm?.languages?.map(
                                (items, index) => (
                                  (found = item.languages.find(
                                    (element, index) =>
                                      element.id === items._id
                                        ? element
                                        : false
                                  )),
                                  found ? (
                                    <td key={found?.id}>
                                      <p
                                        id="language"
                                        slot={found.alarm_id}
                                        accessKey={found?.id}
                                      >
                                        {found?.details}
                                      </p>
                                    </td>
                                  ) : (
                                    ""
                                  )
                                )
                              )}
                            </tr>
                          ))
                          : ""}
                      </tbody>
                    ) : (
                      <tbody>
                        {ProjectDetails
                          ? addalarmSearchArry?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <p>{item.alarm_number}</p>

                              </td>
                              <td>
                                <p id="alarmName">{item.alarm_name}</p>
                              </td>
                              <td style={{ maxWidth: "350px" }}>
                                <p id="description" accessKey={index}>
                                  {item.description}
                                </p>
                              </td>

                              {ProjectDetails[0]?.additional_alarm?.languages?.map(
                                (items, index) => (
                                  (found = item.languages.find(
                                    (element, index) =>
                                      element.id === items._id
                                        ? element
                                        : false
                                  )),
                                  found ? (
                                    <td key={found?.id}>
                                      <p
                                        id="language"
                                        slot={found.alarm_id}
                                        accessKey={found?.id}
                                      >
                                        {found?.details}
                                      </p>
                                    </td>
                                  ) : (
                                    ""
                                  )
                                )
                              )}
                            </tr>
                          ))
                          : ""}
                        <AlarmLoaderComponent />
                      </tbody>
                    )}
                  </table>
                </div>
                <ul className="table-pagination">
                  <li>
                    {/* first page */}
                    {/* <label>Prev</label> */}
                    <button onClick={(e) => handlePaginationAdd(id, 0)}>
                      <i className="bi bi-chevron-double-left"></i>
                    </button>
                  </li>
                  <li>
                    {/* Prev page */}
                    <button
                      onClick={(e) =>
                        pagenumberAddAlarm !== 0
                          ? handlePaginationAdd(id, pagenumberAddAlarm - 1)
                          : null
                      }
                    >
                      <i className="bi bi-caret-left-fill"></i>
                    </button>
                  </li>



                  {pagenumberAddAlarm > 10 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 11)
                        }
                      >
                        {((pagenumberAddAlarm - 10) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 10) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 9 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 10)
                        }
                      >
                        {((pagenumberAddAlarm - 9) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 9) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 8 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 9)
                        }
                      >
                        {((pagenumberAddAlarm - 8) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 8) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 7 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 8)
                        }
                      >
                        {((pagenumberAddAlarm - 7) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 7) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 6 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 7)
                        }
                      >
                        {((pagenumberAddAlarm - 6) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 6) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 5 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 6)
                        }
                      >
                        {((pagenumberAddAlarm - 5) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 5) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 4 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 5)
                        }
                      >
                        {((pagenumberAddAlarm - 4) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 4) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 3 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 4)
                        }
                      >
                        {((pagenumberAddAlarm - 3) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 3) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 2 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 3)
                        }
                      >
                        {((pagenumberAddAlarm - 2) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 2) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 1 ? (
                    <li>
                      {/* current page-2 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 2)
                        }
                      >
                        {((pagenumberAddAlarm - 1) - 1) * rowPerpage}-{((Number(pagenumberAddAlarm) - 1) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm - 1} */}
                      </button>
                    </li>
                  ) : null}
                  {pagenumberAddAlarm > 0 ? (
                    <li>
                      {/* current page -1 */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm - 1)
                        }
                      >
                        {(pagenumberAddAlarm - 1) * rowPerpage}-{(Number(pagenumberAddAlarm) * rowPerpage) - 1}
                        {/* {pagenumberAddAlarm} */}
                      </button>
                    </li>
                  ) : null}
                  {
                    <li className={"active"}>
                      {/* current page */}
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, pagenumberAddAlarm)
                        }
                      >
                        {pagenumberAddAlarm * rowPerpage} -{ProjectDetails[0]?.addi_total > rowPerpage ? (Number(pagenumberAddAlarm) * rowPerpage) + ProjectDetails[0]?.additional_alarm?.alarms?.length - 1 : ProjectDetails[0]?.addi_total ? ProjectDetails[0]?.addi_total - 1 : 0}
                        {/* {pagenumberAddAlarm + 1} */}
                      </button>
                    </li>
                  }
                  {(pagenumberAddAlarm + 1) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 1)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 2 - 1) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 2) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 2) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 2} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 2) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 2)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 3 - 1) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 3) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 3) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}

                  {(pagenumberAddAlarm + 3) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 3)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 3) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 4) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 4) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 4) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 4)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 4) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 5) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 5) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 5) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 5)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 5) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 6) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 6) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 6) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 6)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 6) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 7) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 7) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 7) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 7)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 7) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 8) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 8) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 8) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 8)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 8) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 9) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 9) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 9) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 9)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 9) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 10) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 10) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 10) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 10)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 10) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 11) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 11) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}{(pagenumberAddAlarm + 11) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 11)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 11) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 12) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 12) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}
                  {(pagenumberAddAlarm + 12) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          handlePaginationAdd(id, Number(pagenumberAddAlarm) + 12)
                        }
                      >
                        {(Number(pagenumberAddAlarm) + 12) * rowPerpage}-{ProjectDetails[0]?.addi_total > ((Number(pagenumberAddAlarm) + 13) * rowPerpage) - 1 ? ((Number(pagenumberAddAlarm) + 13) * rowPerpage) - 1 : ProjectDetails[0]?.addi_total - 1}
                        {/* {pagenumberAddAlarm + 3} */}
                      </button>
                    </li>
                  ) : null}


                  {(pagenumberAddAlarm + 13) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button

                      >
                        ...
                      </button>
                    </li>
                  ) : null}


                  {(pagenumberAddAlarm + 13) * rowPerpage <
                    ProjectDetails[0]?.addi_total ? (
                    <li>
                      <button
                        onClick={(e) =>
                          (pagenumberAddAlarm + 1) * rowPerpage <
                            ProjectDetails[0]?.addi_total
                            ? handlePaginationAdd(
                              id,
                              Math.ceil(ProjectDetails[0]?.addi_total / rowPerpage) -
                              1
                            )
                            : ""
                        }
                      >
                        {(Math.ceil(ProjectDetails[0]?.addi_total / rowPerpage) - 1) * rowPerpage}- {ProjectDetails[0]?.addi_total - 1}
                        {/* {Math.ceil(ProjectDetails[0]?.addi_total / 20)} */}
                      </button>
                    </li>
                  ) : null}
                  <li>
                    <button
                      onClick={
                        (e) =>
                          (pagenumberAddAlarm + 1) * rowPerpage <
                            ProjectDetails[0]?.addi_total
                            ? handlePaginationAdd(id, pagenumberAddAlarm + 1)
                            : null

                        // ProjectDetails[0]?.total
                      }
                    >
                      <i className="bi bi-caret-right-fill"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={(e) =>
                        handlePaginationAdd(
                          id,
                          Math.ceil(ProjectDetails[0]?.addi_total / rowPerpage) - 1
                        )
                      }
                    >
                      <i className="bi bi-chevron-double-right"></i>
                    </button>

                  </li>
                </ul>
                <ul className="table-pagination">

                  <label style={{ color: '#2f4c41b3', fontSize: '14px', fontWeight: '600' }}>Limit</label>
                  <select onChange={handleRowPerpage} value={rowPerpage}

                    style={{ background: 'white', fontSize: '15px', fontWeight: '600', color: '#1D946B', width: 'auto', marginLeft: '3px' }}
                  >
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                  </select>



                  <label style={{ color: '#2f4c41b3', fontSize: '14px', fontWeight: '600', marginLeft: '10px' }}>Number</label>
                  <select onChange={(e) => handlePaginationAdd(id, e.target.value)}
                    style={{ background: 'white', fontSize: '15px', width: 'auto', fontWeight: '600', color: '#1D946B', marginLeft: '3px' }} value={pagenumberAddAlarm}>
                    {!addPageList?.length ? <option selected disabled>0-0</option> : null}
                    {addPageList?.map((item, index) => <option value={item?.pageNumber}>{item?.pageName}</option>)}

                  </select>
                </ul>
              </>
            )}
          </div>

          {/* User Tab*/}
          <div
            className="tab-pane fade"
            id="pills-profile4"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="alarm-tab-section-title">
              <h4>Users</h4>
              <div className="alarm-tab-section-box">
                {ProjectDetails ? (
                  ProjectDetails[0].project_owner && ProjectDetails[0]?.owner_edit_permission ? (
                    <NavLink to="/home/projectlist/adduser">
                      {" "}
                      <button className="primary-btn">Add New User</button>
                    </NavLink>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <div className="alarm-tab-section-search">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                  />
                  <button>
                    <img
                      src={process.env.PUBLIC_URL + "/img/search-white.svg"}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="main-inner-card alarm-tab-section sticky-last-child">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email Address</th>
                    <th className="text-center">View</th>
                    <th className="text-center">Edit</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td>0</td>
                    <td>{ProjectDetails[0]?.created_by?.first_name}</td>
                    <td>{ProjectDetails[0]?.created_by?.last_name}</td>
                    <td>{ProjectDetails[0]?.created_by?.email} (Owner) {ProjectDetails[0]?.created_by?.email === authUser?.email ? '(You)' : null}</td>
                    <td className="text-center">
                      <label className="cust_checkbox">
                        <input type="checkbox" checked={true} readOnly />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                    <td className="text-center">
                      <label className="cust_checkbox">
                        <input
                          type="checkbox"
                          checked={ProjectDetails[0]?.owner_edit_permission}
                          // onClick={(e) => dispatch(getUserEdit(ProjectDetails[0]._id, item.email, item.edit ? false : true))}
                          disabled={!ProjectDetails[0]?.edit}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                    {ProjectDetails[0].project_owner && ProjectDetails[0]?.edit ? (
                      <td
                        className="text-center"

                      >

                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                  {ProjectDetails[0].access_user?.length === 0 ? null : userArry ? (
                    userArry.map((item, index) => (
                      <tr key={index}>
                        <td>{`${(paginationUserIndexID * 10 + index) + 1}`}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.email} {item.email === authUser?.email ? '(You)' : null} </td>
                        <td className="text-center">
                          <label className="cust_checkbox">
                            <input type="checkbox" checked={item.view} readOnly />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        <td className="text-center">
                          <label className="cust_checkbox">
                            <input
                              type="checkbox"
                              checked={item.edit}
                              onClick={(e) => dispatch(getUserEdit(ProjectDetails[0]._id, item.email, item.edit ? false : true))}
                              disabled={!ProjectDetails[0]?.edit}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        {ProjectDetails[0].project_owner && ProjectDetails[0]?.edit ? (
                          <td
                            className="text-center"
                            onClick={(e) =>
                              dispatch(
                                getUserDelete(ProjectDetails[0]._id, item.email)
                              )
                            }
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/icn_trash.svg"
                              }
                            />
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
            <ul className="table-pagination">
              <li>
                <button onClick={(e) => handlePaginationUser(0, 20, 0, 0)}>
                  <i className="bi bi-chevron-double-left"></i>
                </button>
              </li>
              <li>
                <button
                  onClick={(e) =>
                    paginationUserIndex !== 0
                      ? handlePaginationUser(
                        paginationUserIndex * 20 - 20,
                        paginationUserIndex * 20,
                        paginationUserIndex - 1,
                        paginationUserIndexID - 2
                      )
                      : ""
                  }
                >
                  <i className="bi bi-caret-left-fill"></i>
                </button>
              </li>

              {paginationUserIndex > 1 ? (
                <li>
                  <button
                    onClick={(e) =>
                      handlePaginationUser(
                        (paginationUserIndex - 2) * 20,
                        (paginationUserIndex - 2) * 20 + 20,
                        paginationUserIndex - 2,
                        paginationUserIndexID - 4
                      )
                    }
                  >
                    {paginationUserIndex - 1}
                  </button>
                </li>
              ) : null}
              {paginationUserIndex > 0 ? (
                <li>
                  <button
                    onClick={(e) =>
                      handlePaginationUser(
                        (paginationUserIndex - 1) * 20,
                        (paginationUserIndex - 1) * 20 + 20,
                        paginationUserIndex - 1,
                        paginationUserIndex - 2
                      )
                    }
                  >
                    {paginationUserIndex}
                  </button>
                </li>
              ) : null}
              {
                <li className={"active"}>
                  <button
                    onClick={(e) =>
                      (paginationUserIndex + 1) * 20 <
                        ProjectDetails[0].access_user.length
                        ? handlePaginationUser(
                          paginationUserIndex * 20,
                          paginationUserIndex * 20 + 20,
                          paginationUserIndex,
                          paginationUserIndexID
                        )
                        : ""
                    }
                  >
                    {paginationUserIndex + 1}
                  </button>
                </li>
              }
              {(paginationUserIndex + 1) * 20 <
                ProjectDetails[0].access_user.length ? (
                <li>
                  <button
                    onClick={(e) =>
                      (paginationUserIndex + 1) * 20 <
                        ProjectDetails[0].access_user.length
                        ? handlePaginationUser(
                          (paginationUserIndex + 1) * 20,
                          (paginationUserIndex + 1) * 20 + 20,
                          paginationUserIndex + 1,
                          paginationUserIndexID + 2
                        )
                        : ""
                    }
                  >
                    {paginationUserIndex + 2}
                  </button>
                </li>
              ) : null}
              {(paginationUserIndex + 2) * 20 <
                ProjectDetails[0].access_user.length ? (
                <li>
                  <button
                    onClick={(e) =>
                      (paginationUserIndex + 1) * 20 <
                        ProjectDetails[0].access_user.length
                        ? handlePaginationUser(
                          (paginationUserIndex + 2) * 20,
                          (paginationUserIndex + 2) * 20 + 20,
                          paginationUserIndex + 2,
                          paginationUserIndexID + 4
                        )
                        : ""
                    }
                  >
                    {paginationUserIndex + 3}
                  </button>
                </li>
              ) : null}
              {(paginationUserIndex + 3) * 20 <
                ProjectDetails[0].access_user.length ? (
                <li>
                  <button>...</button>
                </li>
              ) : null}
              {(paginationUserIndex + 3) * 20 <
                ProjectDetails[0].access_user.length ? (
                <li>
                  <button
                    onClick={(e) =>
                      (paginationUserIndex + 1) * 20 <
                        ProjectDetails[0]?.additional_alarm?.alarms?.length
                        ? handlePaginationUser(
                          Math.ceil(
                            ProjectDetails[0].access_user.length / 20
                          ) *
                          20 -
                          20,
                          Math.ceil(
                            ProjectDetails[0].access_user.length / 20
                          ) * 20,
                          Math.ceil(
                            ProjectDetails[0].access_user.length / 20
                          ) - 1,
                          Math.ceil(ProjectDetails[0].access_user.length / 20)
                        )
                        : ""
                    }
                  >
                    {Math.ceil(ProjectDetails[0].access_user.length / 20)}
                  </button>
                </li>
              ) : null}
              <li>
                <button
                  onClick={(e) =>
                    (paginationUserIndex + 1) * 20 <
                      ProjectDetails[0].access_user.length
                      ? handlePaginationAdd(
                        (paginationUserIndex + 1) * 20,
                        (paginationUserIndex + 1) * 20 + 20,
                        paginationUserIndex + 1,
                        paginationUserIndexID + 2
                      )
                      : ""
                  }
                >
                  <i className="bi bi-caret-right-fill"></i>
                </button>
              </li>
              <li>
                <button
                  onClick={(e) =>
                    handlePaginationUser(
                      Math.ceil(ProjectDetails[0].access_user.length / 20) *
                      20 -
                      20,
                      Math.ceil(ProjectDetails[0].access_user.length / 20) * 20,
                      Math.ceil(ProjectDetails[0].access_user.length / 20) - 1,
                      Math.ceil(ProjectDetails[0].access_user.length / 20)
                    )
                  }
                >
                  <i className="bi bi-chevron-double-right"></i>
                </button>
              </li>

            </ul>
          </div>
          {/*export Tab*/}
          <div
            className="tab-pane fade"
            id="pills-contact5"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <div className="alarm-tab-section-title">
              <h4>Export</h4>
              <div className="alarm-tab-section-box"></div>
            </div>
            <ProjectExport projectId={ProjectDetails[0]._id} />
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default ProjectDetails;
