import React from "react";
//Alarm Loader component
function AlarmLoaderComponent() {
  return (
    <div
      className="d-flex justify-content-center align-items-center loader"
      style={{
        color: "#1D946B",
        position: "fixed",
        background: "#f4fdf994",
        right: "0px",
        top: "0px",
        width: "100%",
      }}
    >
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default AlarmLoaderComponent;

// style={{ color: "#1D946B", position: "fixed", right: "43%" }}
