import React from 'react'

function EditProfile() {
    return (
        <div>
            <image></image>
            <form>
            <label>First Name</label>
            <input type='text'/>
            <label>Last Name</label>
            <input type='text'/>
            <label>Email Address</label>
            <input type='email'/>
            <button>Cancel</button>
            <button>Save</button>
            </form>
        </div>
    )
}

export default EditProfile
