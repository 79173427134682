import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import SignUp from "./Pages/Auth/SignUpPage"; 
import HomePage from "./Pages/HomePage/HomePage";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import { AuhMethods } from "../Utility/jwt/Authmethod";
import { useDispatch } from "react-redux";
import Profile from "./Pages/Profile/Profile";
import AboutUs from "./Pages/ContentPage/AboutUs";
import ContactUs from "./Pages/ContentPage/ContactUs";
import TermsandConditions from "./Pages/ContentPage/TermsandConditions";
import FAQPage from "./Pages/ContentPage/FAQPage";
import PrivacyPolicy from "./Pages/ContentPage/PrivacyPolicy";
import ChangePassword from "./Pages/ContentPage/ChangePassword";
import CrazyTags from "../Components/Layout/CrazyTags";
import Loading from "../Components/Layout/Loading";
import TermsandCondition2 from "./Pages/ContentPage/TermsandCondition2";
import CoockieConset from "./Pages/HomePage/CoockieConset";
import CookiePolicy2 from "./Pages/ContentPage/CookiePolicy2";
import PrivacyPolicy2 from "./Pages/ContentPage/PrivacyPolicy2";
import AlarmSetting from "../Components/Layout/AlarmSetting";
import UploadFile from '../Components/Layout/UploadFile'

const RestrictedRoute = ({ component:Component,...rest}) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props}/>
        ) : ( 
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location},
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AuhMethods["jwtAuth"].getAuthUser());
  }, [dispatch]);

  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/home"} />;
  } else if (authUser && location.pathname === "/login") {
    // return <Redirect to={'/home'} />;
    
    if (location.state !== undefined && location.state !== null) {
      return (
        <Redirect
          to={location.state.from === "/login" ? "/home" : location.state.from}
        />
      );
    } else {
      return <Redirect to={"/home"} />;
    }
  } else if (
    authUser &&
    location.pathname === "/login" &&
    location.state == undefined
  ) {
    
    return <Redirect to={"/home"} />;
  }

  return (
    <React.Fragment>
      
      <Switch>
        <RestrictedRoute path="/home" component={HomePage} />
        <RestrictedRoute path="/crazy" component={CrazyTags} />

        <Route path="/login" component={Login} />
        <Route path="/termsandcondition" component={TermsandCondition2} />
        <Route path="/CookiePolicy" component={CookiePolicy2} />
        <Route path="/PrivacyPolicy" component={PrivacyPolicy2} />
        <Route path="/signup" component={SignUp} />
   
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/loading" component={Loading} />
        <Route path ="/AlarmSetting" component={AlarmSetting}/>
        <Route path ='/UploadFile' component={UploadFile}/>
       
      </Switch> 
      {/* <div><CoockieConset/></div> */}
    </React.Fragment>
  );
};

export default Routes;
