import {
  clearSnackbar,
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../Redux/actions/Common";
import {
  setAuthUser,
  setForgetPassMailSent,
  setPageLoading,
  updateLoadUser,
} from "../../Redux/actions/Auth";
import React from "react";
import axios from "./config";
import { history } from "../../Redux/store/index";
import { ALARM_LOADER, ONFORGOTCLICK } from "../ActionConstant";
const JWTAuth = { 
  onRegister: ({  
    firstName,
    lastName, 
    email,
    password,
    confirmPassword,
    termCheck,
  }) => {
    return (dispatch) => {
      dispatch({ type: ALARM_LOADER, payload: false });
      dispatch(fetchStart());
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .post("users/signUp", {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          confirm_password: confirmPassword,
          terms_condition: termCheck,
        })
        .then(({ data }) => {
          
          if (data) {
            dispatch(fetchSuccess("User registered successfully."));
            dispatch({ type: ALARM_LOADER, payload: true });
            setTimeout(function () {
          
              dispatch(clearSnackbar());
              history.push("/login");
            }, 2000);
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function (err) {
          dispatch(fetchError(err?.response?.data?.message));
          dispatch({ type: ALARM_LOADER, payload: true });
          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 5000);

          
        });
    };
  },

  onLogin:({ email, password }) => {
    console.log('data')
    return (dispatch) => {
      try {
        axios
          .post("users/login", {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            console.log('data',data)
            if (data.data.status) {
              
              localStorage.setItem("refreshtoken", data.data.refresh_tokens);
              localStorage.setItem("token", data.data.tokens);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.data.tokens;
              dispatch(fetchSuccess("Welcome to PLC Alarm."));
              dispatch(JWTAuth.getAuthUser(true, data.data.tokens));

              setTimeout(function () {
                dispatch(clearSnackbar());
              }, 5000);
            } else {
              dispatch(fetchError("Unauthorized user."));
              
            }
          })
          .catch(function (err) {
            dispatch(
              fetchError(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "User dont have write access."
              )
            );

            setTimeout(function () {
              dispatch(clearSnackbar());
            }, 5000);
          });
      } catch (error) {
        dispatch(fetchError(error.message));
        
      }
    };
  },
  onLogout: () => {
    return (dispatch) => {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      dispatch(fetchStart());
      axios
        .get("users/logout/")
        .then(({ data }) => {
          if (data) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshtoken");
            window.location.reload();
            dispatch(setAuthUser(null));
          } else {
            // dispatch(fetchError(data.error));
          }
        })
        .catch(function (error) {
          // dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return (dispatch) => {
      dispatch(setPageLoading(true));
      const refreshToken1 = localStorage.getItem("refreshtoken");
      console.log('authtoken', refreshToken1)
      axios
        .get(`users/auth_tokens/${refreshToken1}`, {
          refresh_tokens: refreshToken1,
        })
        .then(({ data }) => {
          if (data) {
            
            localStorage.setItem("token", data.data);
            const token = localStorage.getItem("token");
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            dispatch(fetchStart());
            dispatch(updateLoadUser(loaded));
            axios
              .get("users/")
              .then(({ data }) => {
                if (data) {
                 
                //  dispatch(fetchSuccess("Welcome " + data.data.first_name));
                window.localStorage.setItem('rowPerPage',data?.data?.page_size)
                  dispatch(setAuthUser(data.data));
                  setTimeout(function () {
                    dispatch(clearSnackbar());
                  }, 5000);
                  dispatch(setPageLoading(false));
                } else {
                  dispatch(updateLoadUser(true));
                  dispatch(setPageLoading(false));
                }
              })
              .catch(function (error) {
                dispatch(updateLoadUser(true));
                dispatch(setPageLoading(false));
              });
          } else {
            dispatch(updateLoadUser(true));
            dispatch(setPageLoading(false));
          }
        })
        .catch(function (error) {
          dispatch(updateLoadUser(true));
          dispatch(setPageLoading(false));
        });
      if (!token) {
        // const refreshToken1 = localStorage.getItem('refreshtoken');
        // dispatch(fetchStart());
        // axios
        //   .post('admin/refresh-token', {
        //     refreshToken: refreshToken1,
        //   })
        //   .then(({ data }) => {
        //     if (data) {
        //       
        //       localStorage.setItem('token', data.data.authToken);
        //       const token = localStorage.getItem('token');
        //       axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        //     } else {
        //       dispatch(fetchError(data.error));
        //     }
        //   })
        //   .catch(function(error) {
        //     dispatch(fetchError(error.message));
        //   });
      }
    };
  },

  onForgotPassword: ({ email }) => {
    return (dispatch) => {
     

      try {
        dispatch(fetchStart());
        axios
          .post("users/forgot-password", {
            email: email,
          })
          .then(({ data }) => {
            if (data) {
              

              dispatch(fetchSuccess(data.message));
            
              dispatch(setForgetPassMailSent(true));
              setTimeout(function () {
                dispatch(clearSnackbar());
                history.push("/login");
              }, 1000);
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function (err) {
            dispatch(fetchError(err?.response?.data?.message));
            dispatch({
              type: ONFORGOTCLICK,
              payload: false,
            })
            setTimeout(function () {
              dispatch(clearSnackbar());
            }, 2000);
          });
      } catch (err) {
        dispatch(fetchError(err?.response?.data?.message));
        dispatch({
          type: ONFORGOTCLICK,
          payload: false,
        })
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 5000);
      }
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
  onUpdateProfile: ({ firstName, lastName }) => {
    return (dispatch) => {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .put(`users/edit`, {
          first_name: firstName,
          last_name: lastName,
        })
        .then(({ data }) => {
          if (data) {
            

            const token = localStorage.getItem("token");
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            dispatch(fetchStart());
            // dispatch(updateLoadUser(loaded));
            axios
              .get("users/")
              .then(({ data }) => {
                if (data) {
                  
                  dispatch(fetchSuccess("Profile updated successfully."));

                  setTimeout(function () {
                    dispatch(clearSnackbar());
                  }, 2000);
                  dispatch(setAuthUser(data.data));
                  
                } else {
                  dispatch(updateLoadUser(true));
                }
              })
              .catch(function (error) {
                dispatch(updateLoadUser(true));
              });
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function (err) {
          dispatch(fetchError(err?.response?.data?.message));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 5000);
        });
    };
  },
  onChangePassword: ({ oldPassword, newPassword, confirmPassword }) => {
    return (dispatch) => {
      dispatch(fetchStart());

      try {
        dispatch(fetchStart());
        axios
          .post("users/reset-password", {
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
          })
          .then(({ data }) => {
            if (data) {
              

              dispatch(fetchSuccess('Your password successfully changed.'));
              setTimeout(function () {
                dispatch(clearSnackbar());
              }, 5000);
              dispatch(setForgetPassMailSent(true));
              history.push('/home')
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function (err) {
            dispatch(fetchError(err?.response?.data?.message));
            setTimeout(function () {
              dispatch(clearSnackbar());
            }, 5000);
          });
      } catch (err) {
        dispatch(fetchError(err?.response?.data?.message));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 5000);
      }
    };
  },
};

export default JWTAuth;
