//auth string
export const login ='Login'
export const EmailId ='Email Id'
export const RememberMe = 'Remember Me'
export const Password = 'Password'
export const ForgotPassword = 'Forgot Password?'
export const DontHaveAccount=' Dont have an account?'
export const AlreadyHaveAccount = 'Already have an account'
export const SignUP ='Sign Up'
export const PlcAlarm ='Plc Alarm'
export const WelcomeBack = 'Welcome Back!!'
export const ForgotPasswordDescription='Enter your registred Id below to get password reset instructions.'
export const EmailLabel = 'Enter your email address here'
export const PasswordLabel = ' Enter your password here'
export const FirstName ='First Name'
export const LastName = 'Last Name'
export const createAccount = 'Create New Account'
export const signupDescription= 'By Signing Up I agree to'
export const TermsandCondition = 'Terms & Conditions'


//notification
// project
//error
export const somethingWentWrongError ='There is something issue in responding server.'