import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndCondition } from "../../../Redux/actions/ContentManger";

function CookiePolicy2() {
  const { TermsAndCondition } = useSelector(
    ({ ContentManager }) => ContentManager
  );
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getTermsAndCondition());
    
  }, []);
  useEffect(() => {
    const script = document.createElement('script');
  
    // script=<script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
    script.src="https://cdn.iubenda.com/iubenda.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
   
    // <a href="https://www.iubenda.com/privacy-policy/24494648" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Privacy Policy">Privacy Policy</a>
    // <a href="https://www.iubenda.com/privacy-policy/24494648/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a>
    <a href="https://www.iubenda.com/privacy-policy/24494648/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a>

  );
}

export default CookiePolicy2;
