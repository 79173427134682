import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getProjectList } from "../../../Redux/actions/Project";
import {
  SET_EDITPROJECTLANGUAGES,
  SET_PROJECTDETAILS_LOADING,
  UPDATE_TITLE,
} from "../../../Utility/ActionConstant";
const titleData = { title: "" };
function MainHomepage() {
  const dispatch = useDispatch();
  const { title } = useSelector(({ BreadcumsReducer }) => BreadcumsReducer);
  const { authUser } = useSelector(({ auth }) => auth);
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: false });
    dispatch(getProjectList(""));
  }, []);
  return (
    <div className="no-data">
      <h2 className="welcome-text">
        WELCOME {authUser ? authUser.first_name.toUpperCase() : ""}
      </h2>
      <img src={process.env.PUBLIC_URL + "/img/Welcome_graphic.svg"} />
      <NavLink className="add-project-btn" to="/home/projectlist/addproject">
        <span
          onClick={(e) =>
            dispatch({ type: SET_EDITPROJECTLANGUAGES, payload: [] })
          }
        >
          Add New Project
        </span>
      </NavLink>
    </div>
  );
}

export default MainHomepage;
