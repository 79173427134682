import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CrazyTags from "../../../Components/Layout/CrazyTags";
// import DropdownTreeSelect from "react-dropdown-tree-select";
// react redux function import
import {
  getAddProject,
  getAllAddionalList,
  getAllLanguageList,
  getAllProjectList,
} from "../../../Redux/actions/Project";
import {
  SET_EDITPROJECTLANGUAGES,
  UPDATE_TITLE,
} from "../../../Utility/ActionConstant";
import { history } from "../../../Redux/store/index";
import Loading from "../../../Components/Layout/Loading";
const titleData = {
  title: "Add New Project",
};
function AddProject() {
  const { ProjectDetailsLoading } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const { AddAlarmlanguageList } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const { ALLProjectList, ALLLanguages, ALLAdditionalList } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const [projectName, setProjectName] = useState("");
  const [parentProjectName, setParentProjectName] = useState(0);
  const [parentProjectDisplay,setParentProjectDisplay]=useState('None')
  const [description, setDescription] = useState("");
  const [alarmList, setAlarmList] = useState([]);
  const [rsml, setRsml] = useState(false);
  const [additionalListStatus, setAdditionalListStatus] = useState(0);
  const [additionalList, setAdditionalList] = useState("");
  const [projectNameError, setProjectNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [additionalListError, setAdditionalListError] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [addchildproject, setAddchildproject] = useState([]);
  const [selectDropDown,setSelectDropDown]=useState('none')
  console.log("child", addchildproject)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: SET_EDITPROJECTLANGUAGES, payload: [] });
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    dispatch(getAllProjectList());
    dispatch(getAllLanguageList());
    dispatch(getAllAddionalList());

    // const assignObjectPaths = (obj, stack) => {
    //   const isArray = Array.isArray(obj);
    //   Object.keys(obj).forEach((k) => {
    //     const node = obj[k];
    //     const key = isArray ? `[${k}]` : k;
    
    //     if (typeof node === "object") {
    //       node.path = stack ? `${stack}.${key}` : key;
    //       assignObjectPaths(node, node.path);
    //     }
    //   });
    // };   
    // const HOC = (Wrapped) => ({ data, ...rest }) => {
    //   assignObjectPaths(data);
    
    //   return <Wrapped data={data} {...rest} />;
    // };
    
  //   function compareAge(a, b) {

  //     return a.path_number - b.path_number;
  // }
  // ALLProjectList.sort(compareAge)  
    const ALLProjectListfilter = ALLProjectList?.map((item, index) => {
      if (!ALLProjectList[index - 1]?.parent_id && item.parent_id === ALLProjectList[index - 1]?.path_number) {
        // alert("fgdfd");
        return { ...item, child: 2 }
      }
      else if (item.parent_id === ALLProjectList[index - 1]?.path_number) {
        // alert("subchild")
        return { ...item, child: 1 }
      } else {
        return item
      }
    }
    )
  //   const filtredProjectList = []
  //   const ALLProjectListfilter = ALLProjectList?.map((parent, index) => {
  //     filtredProjectList?.forEach(check=> check._id)
  // ALLProjectList?.forEach(item=> item.parent_id===parent.path_number? filtredProjectList.push(item) )
  //   }
    // )
    setAddchildproject(ALLProjectListfilter)
   }, []);


  const handleSave = () => {
    setAlarmList(AddAlarmlanguageList);

    if (projectName.trim() === "") {
      setProjectNameError("Project name is required.");
      if (description.trim() === "") {
        setDescriptionError("Description is required.");
      } else {
        setDescriptionError("");
      }
    } else if (description.trim() === "") {
      setProjectNameError("");
      setDescriptionError("Description required.");
    } else if (additionalListStatus == 1) {
      if (additionalList) {
        setButtonDisable(true);
        dispatch(
          getAddProject(
            projectName,
            parentProjectName,
            description,
            AddAlarmlanguageList,
            rsml,
            additionalList,
            additionalListStatus
          )
        );
      } else {
        setAdditionalListError("Select additional alarm list from list.");
      }
    } else {
      setButtonDisable(true);
      dispatch(
        getAddProject(
          projectName,
          parentProjectName,
          description,
          AddAlarmlanguageList,
          rsml,
          additionalList,
          additionalListStatus
        )
      );
    }
  };
  if (!ProjectDetailsLoading) {
    return (
      <div className="main-inner-card add-projects" onClick={e=>selectDropDown==='block' &&e.target.nodeName!=='UL'?setSelectDropDown('none'):null}>
        <div className="form-row">
          <div className="row ">
            <div className="col">
              <label for="Your-Name" className="col-sm-12 col-form-label ">
                Project Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter project name here."
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {projectNameError}
              </div>
            </div>

            <div className="col">
              <label className="col-sm-12 col-form-label ">
                Parent Project
              </label>
              {/* <select
                className="form-select "
                onChange={(e) => setParentProjectName(Number(e.target.value))}
              >
               
                <option value={0} selected>None</option>
                {(addchildproject ?? []).map((item) => (
                  <option value={item.path_number}
                    className={item.child === 1 ? 'child' : item.child === 2 ? 'subChild' : ''}
                  >{item.project_name}</option>
                ))}
              </select> */}
             <div
                className="form-select "
                onChange={(e) => setParentProjectName(Number(e.target.value))}
                onClick={e=> setSelectDropDown(selectDropDown==='none'?'block':'none')}
              ><p style={{margin:'revert'}}>{parentProjectDisplay}</p>
              </div><ul className='customSelect' style={{display:selectDropDown}}>
              <li value={0} selected  onClick={e=> {setSelectDropDown('none')
            setParentProjectName(Number(e.target.value))
            setParentProjectDisplay('None')
            
            }}>None</li>
              {(ALLProjectList ?? []).map((item) => (
                <>  <li value={item.path_number}
                    className={item.path_number==parentProjectName? 'selectProject':''}
                    disable={item?.disable || !item.status? true:false}
                    style={{pointerEvents: item?.disable || !item.status?'none':'', color:item?.disable || !item.status?'grey':''}}
                    onClick={e=> {if(!item?.disable){setSelectDropDown('none')
                    setParentProjectName(Number(e.target.value))
                  setParentProjectDisplay(item.project_name)}else{
                    setSelectDropDown('block')
                  }
                
  }}
                    >{item.project_name}</li>
                    
                    {item?.childProjects?.map(child=>
                     <> <li value={child.path_number}
                     disabled={child?.disable || !child.status? true:false}
                     style={{pointerEvents: child?.disable || !child.status?'none':'', color:child?.disable || !child.status?'grey':''}}
                    className={child.path_number==parentProjectName? 'selectProject child':'child'}
                    onClick={e=> {setSelectDropDown('none')
                    setParentProjectName(Number(e.target.value))
                  setParentProjectDisplay(child.project_name)
                  }}
                    ><i class="bi bi-arrow-return-right"></i>{` ${child.project_name}`}</li>
                      
                    {child?.childProjects?.map(subChild=><li value={subChild.path_number}
                    disabled={subChild?.disable || !subChild.status?true:false}
                    style={{pointerEvents: subChild?.disable|| !subChild.status?'none':'', color:subChild?.disable|| !subChild.status?'grey':''}}
                    className={subChild.path_number==parentProjectName? 'selectProject subChild':'subChild'}
                    onClick={e=> {setSelectDropDown('none')
                    setParentProjectName(Number(e.target.value))
                  setParentProjectDisplay(subChild.project_name)
                  }}
                    ><i class="bi bi-arrow-return-right"></i>{` ${subChild.project_name}`}</li>)}
                    
                    </>
                    ) }
                    </>
                ))}
                </ul>
              <div id="name" className="invalid-feedback">
                Project name should contain only letters.
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="row ">
            <div className="col-lg-6">
              <label className="col-sm-12 col-form-label ">
                Alarm Languages
              </label>
              <div className="form-group">
                {/* <input type="text" className="form-control" name="planets" value="Earth,Mars" /> */}
                <CrazyTags />
              </div>
            </div>
            <div className="col-lg-6">
              <label className="col-sm-12 col-form-label ">
                Reload Standard Messages from Master List:
              </label>
              <div className="w-100 float-start">
                <label className="cust_radio">
                  Do it
                  <input
                    disabled={parentProjectName === 0 ? true : false}
                    type="radio"
                    name="radio1"
                    checked={rsml ? true : false}
                    onChange={(e) => setRsml(rsml ? false : true)}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cust_radio">
                  Don't Do it
                  <input
                    type="radio"
                    checked={rsml ? false : true}
                    name="radio1"
                    onChange={(e) => setRsml(rsml ? false : true)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="row ">
            <div className="col">
              <label className="col-sm-12 col-form-label ">Description</label>
              <div className="form-group">
                <textarea
                  className="form-control pt-3"
                  rows="3"
                  placeholder="Add Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {descriptionError}
              </div>
            </div>
            <div className="col">
              <label className="col-sm-12 col-form-label ">
                Additional List
              </label>
              <div className="w-100 float-start">
                <label className="cust_radio">
                  Select
                  <input
                    type="radio"
                    name="radio11"
                    onChange={(e) => setAdditionalListStatus(1)}
                    disabled={ALLAdditionalList?.length === 0 ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cust_radio">
                  Add
                  <input
                    type="radio"
                    name="radio11"
                    onClick={(e) => setAdditionalListStatus(2)}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cust_radio">
                  No Additional alarm list
                  <input
                    type="radio"
                    name="radio11"
                    checked={additionalListStatus === 0 ? true : false}
                    onChange={(e) => setAdditionalListStatus(0)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              {additionalListStatus === 1 ? (
                <select
                  className="form-select "
                  onChange={(e) => setAdditionalList(e.target.value)}
                  disabled={additionalListStatus ? false : true}
                >
                  <option disabled selected>
                    Select
                  </option>
                  {(ALLAdditionalList ?? []).map((item) => (
                    <option value={item._id}>{item.a_alarm_name}</option>
                  ))}
                </select>
              ) : additionalListStatus === 0 ? (
                <p>"Project will be created without additional alarm list."</p>
              ) : (
                <p>
                  "You'll be redirected to addition alarm page after project
                  creation in this case to add Additional Alarm List Name so
                  that it can be initialised."
                </p>
              )}
              <div
                id="name"
                className="invalid-feedback"
                style={{ display: "block" }}
              >
                {additionalList ? "" : additionalListError}
              </div>
            </div>
          </div>
        </div>

        <div className="profile-btn">
          <button
            className="cancel-btn"
            onClick={(e) => {
              history.goBack();
            }}
          >
            {" "}
            Cancel{" "}
          </button>

          <button
            className="primary-btn"
            onClick={handleSave}
            disabled={buttonDisable}
          >
            {" "}
            Save{" "}
          </button>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default AddProject;
