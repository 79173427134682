import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  SET_EDITPROJECTLANGUAGES,
  SET_PROJECTDETAILS_LOADING,
  UPDATE_TITLE,
} from "../../../Utility/ActionConstant";
const titleData = {
  title: "",
};
function ProjectDetailsNotAvailabel() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: false });
  }, []);
  return (
    <div className="no-data">
      <img src={process.env.PUBLIC_URL + "/img/any_project.svg"} />
      <NavLink className="add-project-btn" to="/home/projectlist/addproject">
        <span
          onClick={(e) =>
            dispatch({ type: SET_EDITPROJECTLANGUAGES, payload: [] })
          }
        >
          Add New Project
        </span>
        {/* {" "}
      <button
        className="add-project-btn"
        >
        Add New Project
      </button> */}
      </NavLink>
    </div>
  );
}

export default ProjectDetailsNotAvailabel;
