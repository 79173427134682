import {
  GET_ABOUT,
  GET_PROJECTDETAILS,
  GET_ALLPROJECTLIST,
  GET_ALLLANGUAGES,
  GET_ALLADDITONALLIST,
  SET_ALARMLANGUAGE,
  SET_EDITPROJECTLANGUAGES,
  SET_PROJECTDETAILS_LOADING,
  SET_MERGE_STATUS,
  SET_PAGELIST,
  SET_ADDPAGELIST,
  SET_FILEUPLOAD,
  GET_USABLEFILELIST,
  GET_ACTIVATE_USED_ALARM_FILE,
  GET_ALARM_SELECT_DESELECT,
  GET_PROJECT_SETTING,
  DISPLAYED_LANGUAGES,
  SHOW_USED_ALARM,
  
} from '../../Utility/ActionConstant';
const INIT_STATE = {
  ProjectList: [],
  ProjectDetails: null,
  ALLProjectList: [],
  ALLLanguages: [],
  ALLAdditionalList: [],
  AddAlarmlanguageList: [],
  EDITALARMLANGUAGE: null,
  ProjectDetailsLoading: true,
  MergeStatus: null,
  pageList: [],
  addPageList: [],
  fileUpload: null,
  usableFileList: null,
  activateUsedAlarmFile: null,
  alarmSelectDeselect: null,
  projectSetting:[],
  displayedLaguages: [],
  showUsedAlarms: null,
 
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ABOUT: {
      return {
        ...state,
        ProjectList: action.payload,
      };
    }
    case GET_PROJECTDETAILS: {
      return {
        ...state,
        ProjectDetails: action.payload,
      };

    }
    case GET_ALLPROJECTLIST: {
      return {
        ...state,
        ALLProjectList: action.payload,
      }

    }
    case GET_ALLLANGUAGES: {
      return {
        ...state,
        ALLLanguages: action.payload,
      }
    }
    case GET_ALLADDITONALLIST: {
      return {
        ...state,
        ALLAdditionalList: action.payload,
      }
    }
    case SET_ALARMLANGUAGE: {
      return {
        ...state,
        AddAlarmlanguageList: action.payload,
      }
    }
    case SET_EDITPROJECTLANGUAGES: {

      return {
        ...state,
        EDITALARMLANGUAGE: action.payload,
      }
    }
    case SET_PROJECTDETAILS_LOADING: {
      return {
        ...state,
        ProjectDetailsLoading: action.payload,
      }
    }
    case SET_MERGE_STATUS: {
      return {
        ...state,
        MergeStatus: action.payload,
      }
    }
    case SET_PAGELIST: {
      return {
        ...state,
        pageList: action.payload
      }
    }
    case SET_ADDPAGELIST: {
      return {
        ...state,
        addPageList: action.payload
      }
    }
    case SET_FILEUPLOAD: {
      return {
        ...state,
        fileUpload: action.payload
      }
    }
    case GET_USABLEFILELIST: {
      return {
        ...state,
        usableFileList: action.payload
      }
    }
    case GET_ACTIVATE_USED_ALARM_FILE: {
      return {
        ...state,
        activateUsedAlarmFile: action.payload
      }
    }
    case GET_ALARM_SELECT_DESELECT: {
      return {
        ...state,
        alarmSelectDeselect: action.payload
      }
    }
    case GET_PROJECT_SETTING: {
      return {
        ...state,
        projectSetting: action.payload
      }
    }
    case DISPLAYED_LANGUAGES: {
      return {
        ...state,
        displayedLaguages: action.payload
      }
    }
    case SHOW_USED_ALARM: {
      return {
        ...state,
        showUsedAlarms: action.payload
      }
    }
  
    
    default:
      return state;
  }
};
