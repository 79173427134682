import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ALARM_LOADER } from "../../Utility/ActionConstant";
import { createGoogleDoc, getActivateUsedAlarmFile, usableAlarmFileList,getProjectDetails } from '../../Redux/actions/Project';

function ActivateUsedAlarmModel({ open, setOpen, item_id, item_project_id,setPagenumberAlarm,setPagenumberAddAlarm }) {
  console.log(item_id, item_project_id)
  const { ProjectDetails } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const [display, setDisplay] = useState("block");
  const dispatch = useDispatch();
  const { id } = useParams();
  const activatebtn = () => {
    dispatch({ type: ALARM_LOADER, payload: true });
    dispatch(getActivateUsedAlarmFile(item_project_id, item_id, projectDetailsCallBack));
    setOpen('');
  };

  const projectDetailsCallBack = () => {
    dispatch(usableAlarmFileList(ProjectDetails[0]._id,window.localStorage.getItem('u_p'),window.localStorage.getItem('u_l')));
    
    dispatch({ type: ALARM_LOADER, payload: true });
    dispatch(getProjectDetails(id, 0,window.localStorage.getItem("rowPerPage")));
    setPagenumberAlarm(0);
    setPagenumberAddAlarm(0);
  };
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"

      aria-labelledby="exampleModalLabel"
      aria-hidden="false"
      style={{ display: open, top: "35%" }}
    >
      <div className="modal-dialog ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Activate Used Alarms List</h5>32
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpen("")}
            ></button>
          </div>
          <div className="modal-body">
            <p>Are you sure that you want to overwrite the used alarm list?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={() => setOpen("")}
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={activatebtn}
            // className={item.activated ? "disable_btn" : "export-btn"}
            >
              <b>Ok</b>

            </button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivateUsedAlarmModel;
 