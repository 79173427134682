import { clearSnackbar, fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "../../Utility/jwt/config";
import {
  GET_ABOUTUS,
  GET_FAQPAGE,
  GET_PRIVACYPOLICY,
  GET_TERMSANDCONDITON,
} from "../../Utility/ActionConstant";
import { history } from "../../Redux/store/index";

//get about page details
export const getAboutUsPage = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`/content/1`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_ABOUTUS, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(
          fetchError(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Project details availible."
          )
        );
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        history.push("/home/projectlist/projecterror");
      });
  };
};
//get termsandcondition page details
export const getTermsAndCondition = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`/content/2`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_TERMSANDCONDITON, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(
          fetchError(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "There is something issue in responding server."
          )
        );
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        history.push("/home/projectlist/projecterror");
      });
  };
};
//get privacy policy page details
export const getPrivacyPolicy = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`/content/3`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_PRIVACYPOLICY, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(
          fetchError(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Project details availible."
          )
        );
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        history.push("/home/projectlist/projecterror");
      });
  };
};
//get faq page details
export const getFAQ = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`/content/4`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_FAQPAGE, payload: data.data.data });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(
          fetchError(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Project details availible."
          )
        );
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
        history.push("/home/projectlist/projecterror");
      });
  };
};
