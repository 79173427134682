import BreadCums from "../../../Components/Layout/BreadCums";
import Footer from "../../../Components/Layout/Footer";
import Header from "../../../Components/Layout/Header";
import SideBar from "../../../Components/Layout/SideBar";
import React,{useEffect} from 'react'
import { UPDATE_TITLE } from "../../../Utility/ActionConstant";
import {useDispatch,useSelector} from 'react-redux';
import { getPrivacyPolicy } from "../../../Redux/actions/ContentManger";
const titleData ={title:'Privacy Policy'}
function PrivacyPolicy() {
  const { PrivacyPolicy } = useSelector(
    ({ ContentManager }) => ContentManager
  );
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    dispatch(getPrivacyPolicy())
  }, []);
  useEffect(() => {
    const script = document.createElement('script');
  
    // script=<script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
    script.src="https://cdn.iubenda.com/iubenda.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
    return (
      <div className="main-inner-card about-section">
      <div className="about-section-row">
        {/* {(PrivacyPolicy ?? []).map(item=><><h3 className="about-section-title">{item.header}</h3>
        <p className="about-section-text">
          {item.content.split("\n")
                        .map((i, key) => {
                          return <div key={key}>{i}</div>;
                        })}
        </p></>)} */}
        
        <a href="https://www.iubenda.com/privacy-policy/24494648" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Privacy Policy">Privacy Policy</a>
      </div>

    </div>
    )
}

export default PrivacyPolicy
