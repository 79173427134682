import * as FileSaver from "file-saver";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as XLSX from "xlsx";
import { clearSnackbar, fetchError } from "../Redux/actions/Common";
import { history } from "../Redux/store";
import axios from "./jwt/config";
import { EXPORTLOADING } from "./ActionConstant";
import { PROJECT_BITLIST } from "./ActionConstant";
// import { getProjectSetting } from "../Redux/actions/Project";

export const BitlistExcleExport = ({ pid, isExportWithUsedAlarm }) => {
  const [data, setdata] = useState();
  console.log("fileData", data);

  const data1 = {
    export_only_used_alarms:isExportWithUsedAlarm
  }
  useEffect(() => {
    let obj1 = Object.entries(data1);
    let obj2 = obj1.map(([key, val]) => {
      // if (val) {
        let newData = `${key}=${val}`;
        setdata(newData);
      // }
    });
  }, [data1]);

  // let { isUsedAlarmsExport, isAllAlarmsExport } = fileData;

  // console.log("exportDataApi", pid, isUsedAlarmsExport, isAllAlarmsExport);

  const dispatch = useDispatch();
  const [exportButtons, setExportButtons] = useState("Export");

  const handleExport = () => {
    setExportButtons("Downloading...");
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`projects/export-bitlist/${pid}?${data}`)
      .then((data) => {
        dispatch({ type: PROJECT_BITLIST, payload: data.data.data });
        setExportButtons("Export");

        const excleApi = data.data.data.fileLink;

        window.open(data.data.data.fileLink, "_self");
        // dispatch({ type: EXPORTLOADING, payload: false });
      })
      .catch((err) => {
        setExportButtons("Export");
        dispatch(fetchError("There is something issue in responding server."));
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
  return (
    <>
      <button className="export-btn" onClick={handleExport}>
        {exportButtons} <i className="bi bi-file- -excel-fill"></i>
      </button>
    </>
  );
};

// JSON.stringify(obj1)?.replaceAll('}','')?.replaceAll('{','')?.replaceAll(':','=').replaceAll('"','')

// let flag = "";
// fileData?.for((progress) => {
//   if (progress.isUsedAlarmsExport === true) {
//     return flag = progress.isUsedAlarmsExport;
//   }
//   return flag = progress.isAllAlarmsExport;
// });
// console.log('flag',flag)
// const object1 = {
//   a: 'somestring',
//   b: 42
// };

// for (const [key, value] of Object.entries(object1)) {
//   console.log("demo1",`${key}: ${value}`);
// }

// console.log(Object.entries(object1))

// let obj1 ={};
//   if (fileData?.isUsedAlarmsExport) {
//     obj1.isUsedAlarmsExport =true;
//   }else{
//     obj1.isAllAlarmsExport = true;
//   }
// console.log("obj1",obj1)
