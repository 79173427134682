import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import configureStore, { history } from './Redux/store';

import Routes from './Routes/Index';
import './App.scss';
import SuccessSnackbar from './Components/Layout/Notification';
import CoockieConset from './Routes/Pages/HomePage/CoockieConset';
export const store = configureStore();

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>     
      <SuccessSnackbar/>
     
          <Switch>
            <Routes/>
          </Switch>
          
      
    </ConnectedRouter>
  </Provider>
);

export default App;

