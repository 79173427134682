import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { getDeleteAdditionalList } from '../../Redux/actions/AddAlarmList';
import { getDeleteProject } from '../../Redux/actions/Project';
// this is delete model for additional alarm list in sidebar
function AdditionalAlarmDeleteModel({open,setOpen, projectid, addDelete }) {
    const[display,setDisplay]=useState('block') // hide or show model state
    const dispatch = useDispatch();
    return (
        
        <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false" style={{display:open, position:'fixed', top:'30%'}}>
  <div className="modal-dialog ">
  {  addDelete?<div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Delete additional alarm list</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setOpen('')}></button>
      </div>
      <div className="modal-body">
        <p>Do you want to delete this additional alarm list?</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={()=>setOpen('')}><b>Cancel</b></button>
        <button type="button" className="btn btn-danger" onClick={()=>{dispatch(getDeleteAdditionalList(projectid));setOpen('')}}><b>Delete</b></button>
      </div>
    </div>:  <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Can not delete!</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setOpen('')}></button>
      </div>
      <div className="modal-body">
        <p>This additional alarm list is connected to one or more projects so it can not be deleted.</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={()=>setOpen('')}><b>Ok</b></button>
      </div>
    </div>}
  </div>
</div>
    )
}

export default AdditionalAlarmDeleteModel
