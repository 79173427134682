import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { getWhitelistFromServer, getValue } from "./mockServer";
import DragSort from "@yaireo/dragsort";
import "@yaireo/dragsort/dist/dragsort.css";

//import Tags from './tagify/react.tagify'
import Tags from "@yaireo/tagify/dist/react.tagify";
import { useDispatch, useSelector } from "react-redux";
import { setAlarmLanguage } from "../../Redux/actions/Project";

/////////////////////////////////////////////////

// Tagify settings object
const baseTagifySettings = {
  blacklist: ["xxx", "yyy", "zzz"],
  maxTags: 1000,
  
  //backspace: "edit",
  tagTextProp: "value",
  dropdown: {
    searchKeys: ["name", "_id"],
    mapValueTo: "name",
    maxItems : 200,
  },

  placeholder: "Type...",

  dropdown: {
    enabled: 0,
    maxItems : 200, // a;ways show suggestions dropdown
  },
};

// this is an example React component which implemenets Tagify within
// itself. This example is a bit elaborate, to demonstrate what's possible.
const CrazyTags = () => {
  const tagifyRef1 = useRef();
  const tagifyRefDragSort = useRef();
  const { ALLLanguages } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const { EDITALARMLANGUAGE } = useSelector(
    ({ projectlistReducer }) => projectlistReducer
  );
  const dispatch = useDispatch();
  const languageDefaultvalue = (EDITALARMLANGUAGE ?? []).map(function (row) {
    return { value: row.name, _id: row._id };
  });
  const { title } = useSelector(({ BreadcumsReducer }) => BreadcumsReducer);
  // just a name I made up for allowing dynamic changes for tagify settings on this component
  const [tagifySettings, setTagifySettings] = useState([]);
  const [tagifyProps, setTagifyProps] = useState({});
  const [alarmLanguage, setAlarmLanguagelist] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState(
    EDITALARMLANGUAGE?.length < 1
      ? [
        
        ]
      : languageDefaultvalue
  );
  const options = ALLLanguages.map(function (row) {
    return { value: row.name, _id: row._id };
  });

  // on component mount
  useEffect(() => {
    dispatch(setAlarmLanguage(defaultLanguage)); // tying //done
    // console.log("editlanguage", EDITALARMLANGUAGE);
    setTagifyProps({ loading: true });

    getWhitelistFromServer(2000).then((response) => {
      setTagifyProps((lastProps) => ({
        ...lastProps,
        whitelist: options,
        showFilteredDropdown: "",
        loading: false,
      }));
    });

    // simulate setting tags value via server request
    getValue(3000).then((ALLLanguages) =>
      setTagifyProps((lastProps) => ({
        ...lastProps,
        defaultValue: defaultLanguage,
      }))
    );

    // simulate state change where some tags were deleted
    setTimeout(
      () =>
        setTagifyProps((lastProps) => ({
          ...lastProps,
          defaultValue: ["abc"],
          showFilteredDropdown: false,
        })),
      5000
    );
  }, []);
  // console.log("tagify", tagifyProps);
  // merged tagify settings (static & dynamic)
  const settings = {
    ...baseTagifySettings,
    ...tagifySettings,
  };

  const onChange = useCallback((e) => {
    setAlarmLanguagelist("hello");
    let a = e.detail.tagify.value;

    // console.log("CHANGED:", e.detail.value);

    // console.log("option2:", e.detail.tagify.value);

    dispatch(setAlarmLanguage(a));
  }, []);

  // access Tagify internal methods example:
  const clearAll = () => {
    tagifyRef1.current && tagifyRef1.current.removeAllTags();
  };

  // bind "DragSort" to Tagify's main element and tell
  // it that all the items with the below "selector" are "draggable".
  // This is done inside a `useMemo` hook to make sure it gets initialized
  // only when the ref updates with a value ("current")
  useMemo(() => {
    if (tagifyRefDragSort.current)
      new DragSort(tagifyRefDragSort.current.DOM.scope, {
        selector: ".tagify__tag",
        callbacks: {
          dragEnd: onDragEnd,
        },
      });
  }, [tagifyRefDragSort.current]);

  // must update Tagify's value according to the re-ordered nodes in the DOM
  function onDragEnd(elm) {
    tagifyRefDragSort.current.updateValueByDOMTags();
  }

  return (
    <>
     
      <Tags
        tagifyRef={tagifyRef1}
        settings={settings}
        defaultValue={defaultLanguage}
        autoFocus={true}
        {...tagifyProps}
        onChange={onChange}
        onEditInput={() => console.log("onEditInput")}
        onEditBeforeUpdate={() => console.log`onEditBeforeUpdate`}
        onEditUpdated={() => console.log("onEditUpdated")}
        onEditStart={() => console.log("onEditStart")}
        onEditKeydown={() => console.log("onEditKeydown")}
        onDropdownShow={() => console.log("onDropdownShow")}
        onDropdownHide={() => console.log("onDropdownHide")}
        onDropdownSelect={() => console.log("onDropdownSelect")}
        onDropdownScroll={() => console.log("onDropdownScroll")}
        onDropdownNoMatch={() => console.log("onDropdownNoMatch")}
        onDropdownUpdated={() => console.log("onDropdownUpdated")}
      />
    </>
  );
};

export default CrazyTags;
