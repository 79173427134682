import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuhMethods } from "../../Utility/jwt/Authmethod";
import { useDispatch } from "react-redux";

function Header() {
  const { authUser } = useSelector(({ auth }) => auth); // get logged user state from store
  const dispatch = useDispatch();

  const onLogoutClick = () => {
    dispatch(AuhMethods["jwtAuth"].onLogout());
  };
  return (
    // <header className="header-section sticky-top ">
      <header className="header-section sticky-top-custom">
      <div className="dropdown float-end">
        <a
          href="#"
          className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle"
          id="dropdownUser1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Faenza-avatar-default-symbolic.svg/240px-Faenza-avatar-default-symbolic.svg.png"
            alt=""
            width="30"
            height="30"
            className="rounded-circle me-2"
          />
          <span>{authUser ? authUser.first_name : ""} </span>
          <img
            className="ms-2"
            width="18"
            src={process.env.PUBLIC_URL + "/img/down-arrow-color.svg"}
          />
        </a>
        <ul className="dropdown-menu  shadow" aria-labelledby="dropdownUser1">
          <li>
            <NavLink to="/home/profile" className="dropdown-item">
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/aboutus" className="dropdown-item">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/termsandconditions" className="dropdown-item">
              Terms & Conditions
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/CookiePolicy" className="dropdown-item">
             Cookie policy
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/privacypolicy" className="dropdown-item">
              Privacy Policy
            </NavLink>
          </li>
          <li>
            <a 
            className="dropdown-item"
            onClick={() =>window.open('https://plc-alarm.com/docs/')}  
            >
            Documentation
            </a>
          </li>
          <li>
            <NavLink to="/home/contactus" className="dropdown-item">
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/changepassword" className="dropdown-item">
              Change Password
            </NavLink>
          </li>
          {/* <li><a className="dropdown-item" href="#">Terms & Conditions</a></li>
          <li><a className="dropdown-item" href="#">Privacy Policy</a></li>
          <li><a className="dropdown-item" href="#">FAQ</a></li>
          <li><a className="dropdown-item" href="#">Contact Us</a></li>
          <li><a className="dropdown-item" href="#">Change Password</a></li> */}
          <li>
            <a className="dropdown-item" href="#" onClick={onLogoutClick}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </header>
    //       <section className="header-section">
    //         <div>
    //             <span>Franz</span>
    //             <div className="dropdown">
    //   <a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
    //     Dropdown link
    //   </a>

    //   <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
    //     <li><a className="dropdown-item" href="#">Profile</a></li>
    //     <li><a className="dropdown-item" href="#">About Us</a></li>
    //     <li><a className="dropdown-item" href="#">Terms & Conditions</a></li>
    //     <li><a className="dropdown-item" href="#">Privacy Policy</a></li>
    //     <li><a className="dropdown-item" href="#">FAQ</a></li>
    //     <li><a className="dropdown-item" href="#">Contact Us</a></li>
    //     <li><a className="dropdown-item" href="#">Change Password</a></li>
    //     <li><a className="dropdown-item" href="#">Logout</a></li>
    //   </ul>
    // </div>
    //         </div>
    //         </section>
  );
}

export default Header;
