import {
  EXPORTDETAILS,
  GLOBALMERGESTATUS,
  LANGMERGE,
  LANGMERGESTATUS,
  MERGESTATUS,
  SET_MERGE_STATUS,
  EXPORTLOADING,
  ALL_MERGE_STATUS,
  PROJECT_SETTING,
  PROJECT_BITLIST,
} from "../../Utility/ActionConstant";
import axios from "../../Utility/jwt/config";
import { fetchSuccess } from "./Common";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar, fetchError } from "./Common";

export const ExportAPI = (id) => {
  return (dispatch) => {
    dispatch(langStatus(id));

    const token = localStorage.getItem("token"); 
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`/projects/project-merge-details/${id}`)
      .then((data) => {
        
        if (data.status === 200) {
          
          dispatch({
            type: EXPORTDETAILS,
            payload: data.data.data,
          });
          dispatch({ type: EXPORTLOADING, payload: false });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        dispatch({ type: EXPORTLOADING, payload: false });
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};

export const langMerge = (ProjectId, langId) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios(`/doc/language-merge-docs/${ProjectId}/${langId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(langStatus(ProjectId));
          dispatch(
            fetchSuccess(
              "Merge process started, you will get mail with download link once its finished."
            )
          );

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          const mergeInterval = setInterval(() => {
            dispatch(langStatus(ProjectId, mergeInterval));
          }, 60000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        dispatch(langStatus(ProjectId));
        const mergeInterval = setInterval(() => {
          dispatch(langStatus(ProjectId, mergeInterval));
        }, 60000);
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};

export const langStatus = (ProjectId, mergeInterval) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios(`/doc/check-export-process-status/${ProjectId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: LANGMERGESTATUS,
            payload: data.data.data.alarm_folder_ids,
          });
          dispatch({
            type: GLOBALMERGESTATUS,
            payload: data.data.data.is_in_progress,
          });
          const isProgress = () => {
            let flag = false;
            data.data.data?.alarm_folder_ids?.forEach((progress) => {
              if (progress.is_in_progress) {
                flag = true;
              }
              return flag;
            });
            return flag;
          };
          if (isProgress()) {
            dispatch({ type: SET_MERGE_STATUS, payload: true });
          }
          if (!data.data.data?.is_in_progress && !isProgress()) {
            dispatch({ type: SET_MERGE_STATUS, payload: false });
            dispatch({ type: ALL_MERGE_STATUS, payload: false });
            if (mergeInterval) {
              dispatch({ type: MERGESTATUS, payload: true });
              dispatch(fetchSuccess("Your file has been merged successfully."));
            }

            setTimeout(function () {
              dispatch(clearSnackbar());
            }, 3000);
            return clearInterval(mergeInterval);
          } else {
            dispatch({ type: ALL_MERGE_STATUS, payload: true });
          }
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        console.log("");
      });
  };
};

export const globalMerge = (ProjectId) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios(`doc/global-merge-docs/${ProjectId}`)
      .then((data) => {
      
        if (data.status === 200) {
          dispatch(
            fetchSuccess(
              "Global merge process started, you will get mail with download link once its finished."
            )
          );
          dispatch(langStatus(ProjectId));

          setTimeout(function () {
            dispatch(clearSnackbar());
          }, 3000);
          const mergeInterval = setInterval(() => {
            dispatch(langStatus(ProjectId, mergeInterval));
          }, 60000);
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchError(err?.response?.data?.message));
        dispatch(langStatus(ProjectId));
        const mergeInterval = setInterval(() => {
          dispatch(langStatus(ProjectId, mergeInterval));
        }, 1000);
        setTimeout(function () {
          dispatch(clearSnackbar());
        }, 3000);
      });
  };
};

export const mergeProcess = (mergeState) => {
  return (dispatch) => {
    dispatch({ type: LANGMERGE, payload: mergeState });
  };
};

export const exportProjectSetting = (
  project_id,
) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(`projects/get-project-setting/${project_id}`)
      .then((data) => {
        if (data.status === 200) {

          dispatch({ type: PROJECT_SETTING, payload: data.data.data });
          dispatch({ type: EXPORTLOADING, payload: false });
        } else {
          dispatch(
            fetchError("There is something issue in responding server.")
          );
        }
      })
      .catch((err) => { });
  };
};

// export const exportBitlistProject = (
//   project_id, data,callbackfun,isExport
// ) => {
//   return (dispatch) => {
//     const token = localStorage.getItem("token");
//     axios.defaults.headers.common["Authorization"] = "Bearer " + token;
//     axios
//       .get(`projects/get-project-setting/${project_id}`,data)
//       .then((data) => {
//         if (data.status === 200) {

//           dispatch({ type:PROJECT_BITLIST, payload: data.data.data });
//           if (callbackfun) callbackfun()
//           if(!isExport) dispatch(fetchSuccess("Alarm setting updated successfully."));
//           else{
//             dispatch(fetchSuccess("Export setting updated successfully."));
//           }
//           setTimeout(function () {
//             dispatch(clearSnackbar());
//           }, 3000);
//           // dispatch({ type: ALARM_LOADER, payload: false });
//           dispatch({ type: EXPORTLOADING, payload: false });
//         } else {
//           dispatch(
//             fetchError("There is something issue in responding server.")
//           );
//         }
//       })
//       .catch((err) => { });
//   };
// };