import axios from "axios";

export default axios.create({
  baseURL:` https://api.plc-alarm.app/v1/`, 
  headers: {
    "Content-Type": "application/json",
  },
// `https://e034-2401-4900-b13-6d65-82d-ae0a-b5ed-15d5.ngrok.io/v1`
});
// use below base api according to env
//https://api.plc-alarm.app/v1/    for client live production
// https://dev.inheritxdev.net/plcalarm-prod/v1/ for inx production
// https://dev.inheritxdev.net/plcalarm-qa/v1/  for inx qa
// https://dev.inheritxdev.net/plcalarm-dev/v1/ for inx dev
// https://node.inheritxdev.in/plcalarm-qa/v1/    aws


