import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { getProjectList } from "../../../Redux/actions/Project";
import {
  SET_EDITPROJECTLANGUAGES,
  SET_PROJECTDETAILS_LOADING,
  UPDATE_TITLE,
} from "../../../Utility/ActionConstant";
const titleData = {
  title: "",
};
function ProjectNotAvailibel() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: UPDATE_TITLE, payload: titleData });
    dispatch({ type: SET_PROJECTDETAILS_LOADING, payload: false });
    dispatch(getProjectList(""));
  }, []);
  return (
    <div className="no-data">
      <img src={process.env.PUBLIC_URL + "/img/this_project.svg"} />
      <NavLink className="add-project-btn" to="/home/projectlist/addproject">
        <span
          onClick={(e) =>
            dispatch({ type: SET_EDITPROJECTLANGUAGES, payload: [] })
          }
        >
          Add New Project
        </span>
        
      </NavLink>
    </div>
  );
}

export default ProjectNotAvailibel;
