import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SNACKBAR_CLEAR,
  UNIVERSAL_LOADING,
} from "../../Utility/ActionConstant";

export const fetchSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || "",
    });
  };
};
export const fetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_START,
    });
  };
};
export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: SNACKBAR_CLEAR });
  };
};
export const universalLoading = (loading) => {
  return (dispatch) => {
    dispatch({ type: UNIVERSAL_LOADING, payload: loading });
  };
};
